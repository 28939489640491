import firebase from 'firebase/app';
import "firebase/firestore";
/*
var firebaseConfig = {
    apiKey: "AIzaSyCPHEhK-25eHvStZftKl-Ts1-VmHfk04XM",
    authDomain: "career-expo-9c016.firebaseapp.com",
    projectId: "career-expo-9c016",
    storageBucket: "career-expo-9c016.appspot.com",
    messagingSenderId: "1051108120915",
    appId: "1:1051108120915:web:1b57fca5b7c8acbb7e29a6",
    measurementId: "G-37D63T398V"
  };
*/
// eslint-disable-next-line no-unused-vars
const gaTESTINGfirebaseConfig = {
  apiKey: "AIzaSyCzbBNbbGEZCxSKcODTOe5_Yh_dORvbKUI",
  authDomain: "testinganalytics-2a9eb.firebaseapp.com",
  projectId: "testinganalytics-2a9eb",
  storageBucket: "testinganalytics-2a9eb.appspot.com",
  messagingSenderId: "893282383009",
  appId: "1:893282383009:web:8af7577e1c6c504c1dace0",
  measurementId: "G-149XWXM70W"
};
  // Initialize Firebase
  firebase.initializeApp(gaTESTINGfirebaseConfig);
  //firebase.analytics();
   const analytics = firebase.analytics();
   
   export const firestore = firebase.firestore();

   console.log(analytics);