/*
export function someMutation (state) {
}
*/
export const setEditUserDialog = (state, editUserDialog) => {
  state.editUserDialog = editUserDialog
}
export const setContactFormDialog = (state, contactFormDialog) => {
  state.contactFormDialog = contactFormDialog
}
export const setCurrentUserData = (state, data) => {
  state.currentUser = data
}
