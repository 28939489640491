import { render, staticRenderFns } from "./manufacturing_form.vue?vue&type=template&id=1b57a1c8&scoped=true&"
import script from "./manufacturing_form.vue?vue&type=script&lang=js&"
export * from "./manufacturing_form.vue?vue&type=script&lang=js&"
import style0 from "./manufacturing_form.vue?vue&type=style&index=0&id=1b57a1c8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b57a1c8",
  null
  
)

export default component.exports