import Vue from 'vue'
import VueRouter from 'vue-router'
import firebase from 'firebase'
//import Home from '../views/Home.vue'

import HomePage from '../views/bike'
import WorldHome from '../components/worldhome';
import researchLab from '../views/researchLab';
import FormHome from '../views/formpage'
import Dashboard from '../components/Dashboard'
import Awards from '../views/interactionHome';
import ResourceLibrary from '../views/resourceLibrary'
import MatchingGame from '../components/MatchingGame'
//import VueGtag from "vue-gtag";
//Vue.use(VueGtag, {
 /// config: { id: "GTM-PCNWMJK" },
//  includes: [
  //  { id: 'GTM-55S9W6G' },
//    { 
  //    id: 'UA-1234567-3',
    //  params: {
      //  send_page_view: false
     // }
  //  }
  //]
//}, router);
Vue.use(VueRouter)

const routes = [
 
    { path: '/', name:'home',component: HomePage ,// meta: {
     // requiresAuth: true,
  //  }
  },
  
    { path: '/worlds/:worldname', name:"individualWorld" ,component: WorldHome },
    { path: '/worlds', name:"researchLab",component: researchLab },

    { path: '/formssummary',name:"formhome", component: FormHome },
    { path: '/awards',name:"Awards", component: Awards },



    {
      path: "/game",
      name: "game",
      component: MatchingGame
    },

    {
      path: "/dashboard",
      name: "dashboard",
      component: Dashboard,
      meta: {
        requiresAuth: true,
      },
    },  {
      path: "/resourceLibrary",
      name:"resourceLibrary",
      component: ResourceLibrary,

    },
    // otherwise redirect to home
    { path: '*', redirect: '/' }
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes:routes
})

export default router
router.beforeEach((to, from, next) => {
  const user = firebase.auth().currentUser;
 //console.log(store.state)
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
 //console.log(user)
  if (requiresAuth && !user) {
    next("/login");
  } else if (requiresAuth && user) {
    next();
  } else {
    next();
  }
});

