<template>
<div>
<button v-if="ipadUp && assessToggle"  :class="ipadUp ? 'back-button show-button' : 'back-button hid-button'"  @click="ipadDown"><b-icon icon="chevron-down" aria-hidden="true" style="width: 42px; height: 42px; color: #de1a21 !important;"></b-icon></button>
<div id="tabletIn">
<h1>Explore</h1>
  <b-row v-if="assessToggle==true">
    <b-col cols="6">
      <div class="neu-body">
        <h2>{{selectProduct(region,worldName)}} World</h2>
               <div v-if="currentExploreBlurb.mediaInfo.imageOrVideo=='video'">
               
        <videoToggleWrapper :worldName='worldName' :key="1" :videoInfo='currentExploreBlurb.mediaInfo'/> 
              </div>
              <div v-else>
                <img :src="require(`@/assets/${region}-images/${currentExploreBlurb.mediaInfo.srcOrHref}.jpg`)" alt="Career Explore is fun">
                
  
                </div>

        <p>{{currentExploreBlurb.paragraph1}} </p>
        <p>{{currentExploreBlurb.paragraph2}}</p>
      </div>
        <p style="font-size:11px; font-weight:bold;">Take a brief, five question assessment that will help you decide if this world is a good fit for you by highlighting key skills that are needed to have a successful career in this world.</p>
        <b-button class="neu-button" @click="displayAssessment">Take The Assessment</b-button>
    </b-col>
    <b-col cols="6">
      <ul>
            <li class="achievements">
              <h6>Achievements</h6>
                <p><toggleCheckOrX :argBool='visitedAllPages'/> &nbsp;Visit the Explore, Careers, Employers, and Education sections in {{selectProduct(region,worldName)}}. </p>
                <p><toggleCheckOrX :argBool='learnedAllCareers'/>  &nbsp;Learn about all of the careers in {{selectProduct(region,worldName)}} World. </p>
                <p><toggleCheckOrX :argBool='allMissionsAchievementsComplete'/>  &nbsp;Complete all of the achievements and missions in {{selectProduct(region,worldName)}}. (Awards badge)</p>

            </li><!--<achievementToggle/>-->
            <li class="mission">
              <h6>Missions</h6>
              <p><toggleCheckOrX :argBool='visitedAero'/>  &nbsp;Visit the {{selectProduct(region,worldName)}} World.</p>
              <p><toggleCheckOrX :argBool='tookFitQuiz'/>  &nbsp;Take the assessment in the Explore section of {{selectProduct(region,worldName)}} World.</p>
              <p><toggleCheckOrX :argBool='learnedOneCareer'/>  &nbsp;Learn about one career in  {{selectProduct(region,worldName)}} World.</p>
              <p><toggleCheckOrX :argBool='visitedEmployers'/>  &nbsp;Visit the Employers section in {{selectProduct(region,worldName)}} World.</p>
              <p><toggleCheckOrX :argBool='visitedEducation'/>  &nbsp;Open the Education section in {{selectProduct(region,worldName)}} World.</p>

  
            </li>
      </ul>
    </b-col>
  </b-row>
  <b-row v-if="assessToggle==false">
      <Assessment :worldName='worldName'/>
  </b-row>
</div>
</div>
</template>


<script>
import Assessment from './Assessment.vue';
import toggleCheckOrX from './toggleCheckOrX';
import worldNameMixin from '../../shared_methods/worldname_mixin'
import exploreBlurbMixin from '../../shared_methods/exploreBlurbMixin'
import videoToggleWrapper from './videoToggleWrapper';
import careersInfoMixin from '../../shared_methods/careers_Info_Mixin';

export default {
  components: { Assessment,toggleCheckOrX,videoToggleWrapper },
   mixins: [worldNameMixin,exploreBlurbMixin,careersInfoMixin],
    name:"Explore",
    computed: {
      visitedAero() {
      return this.$store.state.interaction_tracking_store[this.worldName].pagestate.pagevisited
    },
    visitedEmployers(){
      return this.$store.state.interaction_tracking_store[this.worldName].pagestate.visitedSidebarElementNames.indexOf('Employers') > -1

    },
    visitedEducation(){
      return this.$store.state.interaction_tracking_store[this.worldName].pagestate.visitedSidebarElementNames.indexOf('Education') > -1

    },

    tookFitQuiz() {
      return this.$store.state.interaction_tracking_store[this.worldName].pagestate.suitabilityQuizTaken
     
    },
    visitedAllPages(){
      return this.$store.state.interaction_tracking_store[this.worldName].pagestate.visitedSidebarElementNames.length>=4

    },
    learnedOneCareer()
    {const careersLearned= this.$store.state.interaction_tracking_store[this.worldName].pagestate.careersLearned.length
    
      return careersLearned >= 1;


    },
    learnedAllCareers()
    {const careersLearned= this.$store.state.interaction_tracking_store[this.worldName].pagestate.careersLearned.length
    
      return careersLearned >= this.jobCount;

    },
    allMissionsAchievementsComplete()
    { //TODO MAKE IT BETTER
    console.log(this.$store.state.awards_store.currentSession.completedAchievements)
      return ((this.$store.state.awards_store.currentSession.completedAchievements[this.worldName].length>=2) &&
      (this.$store.state.awards_store.currentSession.completedMissions[this.worldName].length>=5))

    }
  },
    data() {
                   var exploreBlurb= this.selectExploreBlurb(process.env.VUE_APP_CURRENT_REGION,this.worldName)
    const jobPageInfo = this.selectJobPageInfo(process.env.VUE_APP_CURRENT_REGION,this.worldName)
               //console.log(exploreBlurb)
        return{ 
                     region:process.env.VUE_APP_CURRENT_REGION.toLowerCase(),
                currentExploreBlurb:exploreBlurb,
          ipadUp:true,
        assessToggle: true,
     jobCount: jobPageInfo.jobs.length

    }
    },
     methods: {
    displayAssessment() {
    this.assessToggle= !this.assessToggle
    //this.$parent.handheldView='Assessment';//='Educatio,n'
    
    },  ipadDown(){
     this.$parent.childComponentTriggerActive(this.$options.name)
     this.ipadUp=false;
     this.$parent.sidebar
    },
     },
     props:{
       worldName:String
     }
}
</script>

<style scoped>

.achievements, .mission {
    border: .0625rem solid #d1d9e6;
    margin-bottom: 1vh;
    padding: 10px 10px;
    box-shadow: inset 2px 2px 5px #b8b9be,inset -3px -3px 7px #fff!important;
    border-radius: .55rem;
}

.neu-body{
      border-radius: 15px;
    overflow: scroll;
    overflow-x: hidden;
    height: 31vh;
    box-shadow: inset 2px 2px 5px #b8b9be, inset -3px -3px 7px #fff;
    margin-bottom: 10px;
    padding: 1vw 1vh;
}

.neu-button {
    border: 0px solid white;
    width: 24vw;
    height: 6vh;
    border-radius: 150px;
    background: #e5e5e5;
    box-shadow: 6px 6px 12px #B8B9BE, -6px -6px 12px #fff;
    line-height: 14px;
    display: flex;
    font-family: 'Montserrat', Helvetica, Arial, sans-serif !important;
    font-weight: bold;
    align-items: center;
    justify-content: center;
    margin: 1vh 0vw;
    color: #de1a21 !important;
    font-size: 1em;
    text-transform: uppercase;
}

svg.bi-chevron-left.b-icon.bi {
    color: #de1a21 !important;
}

#tabletIn p{
    font-size: 1vw;
    margin:1vh 0;
}

.world-explore-image {
    width: 12vw;
    display: block;
    float: left;
    margin-right: 11px;
}


ul {
    list-style: none;
    margin-left:0;
    padding-left:0;
}

@media only screen and (max-width:960px){
  .neu-body {
    overflow: auto !important;
    overflow-x: auto !important;
    height: auto !important;
}
}

</style>