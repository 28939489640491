<template>
<div id="tabletIn">
  <div>
      <h2>Assessment for {{selectProduct(region,this.$options.name)}} World</h2>

    <b-form @submit="onSubmit" v-if="show">
      <p style="font-size: 11px; color:#de1a21;">You must submit this assessment to go back to the home page.</p>
      <b-form-group id="input-group-1" :label="questionsS['question1']['questionText']" label-for="input-1">
        <b-form-select
          id="input-1"
          v-model="question1.questionAnswer"
          :options="questionsS.question1.questionOptions"
          required
        ></b-form-select>
      </b-form-group>

      <b-form-group id="input-group-2" :label="questionsS['question2']['questionText']"  label-for="input-2">
        <b-form-select
          id="input-2"
          v-model="question2.questionAnswer"
          :options="questionsS.question2.questionOptions"
          required
        ></b-form-select>
      </b-form-group>

 <b-form-group :label="questionsS['question3']['questionText']" v-slot="{ ariaDescribedby }">
      <b-form-radio-group
        id="btn-radios-1"
        v-model="selected"
        :options="options"
        :aria-describedby="ariaDescribedby"
        name="radios-btn-default"
        buttons
      ></b-form-radio-group>
    </b-form-group>
      <b-form-group id='input-group-4' :label="questionsS['question4']['questionText']" label-for='input-4'>
        <b-form-input  
          id='input4'
           v-model="question4.questionAnswer"
          
          required
        ></b-form-input>
      </b-form-group>
      
      
      





      <b-button class="neu-button" type="submit" variant="primary">Update Mission Log</b-button>
    </b-form>

       <div v-else>
         <h2  style="font-size: 16px; color:green;">Form info has been submitted</h2>
        </div>
  </div>
  </div>
</template>
<script>
import worldNameMixin from '../../../shared_methods/worldname_mixin'
import assessmentMixin from '../../../shared_methods/assessmentMixin'
export default {
  mixins: [worldNameMixin,assessmentMixin],
  data() {
     var assessmentInfo=this.selectAssessmentInfo(process.env.VUE_APP_CURRENT_REGION,this.$options.name)
      return {
               region:process.env.VUE_APP_CURRENT_REGION.toLowerCase(),

                selected: null,
        options: [
          { text: 'Yes', value: 'yes' },
          { text: 'No', value: 'no' },

        ],
        questionsS:assessmentInfo,
            items: [
          { worldName:this.$options.name,model:'question1', question: this.$options.name+ '1', },
          { worldName:this.$options.name,model:'question2' ,question: this.$options.name+'2' },
          { worldName:this.$options.name,model:'question3' ,question: this.$options.name+'3' },
          {worldName:this.$options.name,model:'question4' , question: this.$options.name+'4' }
        ],
        show:true}

        
  },
       watch: {
      // eslint-disable-next-line no-unused-vars
      selected: function (value, oldValue) {
        if (value.length != null) {
           this.$store.commit('assessment_form_store/SET_QUESTION',{formid:this.$options.name, questionid:'question3',value:value,region:this.region});

        }
      },
       },
    computed: {
      questions(){
        return this.$store.state.assessment_form_store[this.region][this.$options.name].formstate
      },
    question1: {
      get() {
         // //console.warn(this.$options.name)
         // console.log(this.$store.state.assessment_form_store[this.region][this.$options.name].formstate)
        return this.$store.state.assessment_form_store[this.region][this.$options.name].formstate.question1;
      },
      set(value) {
        this.$store.commit('assessment_form_store/SET_QUESTION',{formid:this.$options.name, questionid:'question1',value:value,region:this.region});
      },
    },
    question2: {
      get() {
       // console.log(this.$store.state.assessment_form_store[this.region][this.$options.name].formstate.question2);
        return this.$store.state.assessment_form_store[this.region][this.$options.name].formstate.question2;
      },
      set(value) {
        this.$store.commit('assessment_form_store/SET_QUESTION',{formid:this.$options.name, questionid:'question2',value:value,region:this.region});
      },
    },
    question3: {
      get() {
        return this.$store.state.assessment_form_store[this.region][this.$options.name].formstate.question3;
      },
      set(value) {
        this.$store.commit('assessment_form_store/SET_QUESTION',{formid:this.$options.name, questionid:'question3',value:value,region:this.region});
      },
    },
    question4: {
      get() {
        return this.$store.state.assessment_form_store[this.region][this.$options.name].formstate.question4;
      },
      set(value) {
        this.$store.commit('assessment_form_store/SET_QUESTION',{formid:this.$options.name, questionid:'question4',value:value,region:this.region});
      },
    }
   },

  methods: {
        onSubmit(event) {
                  event.preventDefault()
        this.$store.commit('assessment_form_store/SUBMIT_FORM',{formid:this.$options.name,value:true,region:this.region});

      this.$parent.onSubmit(event)
        this.show = false

      },
  self() {
      //  console.log(this)
        return this
    }
  },
    name:"engineering",}
    </script>

<style scoped>
.neu-button {
    border: 0px solid white;
    width: 18vw;
    height: 5vh;
    border-radius: 150px;
    background: #e5e5e5;
    box-shadow: 6px 6px 12px #B8B9BE, -6px -6px 12px #fff;
    line-height: 14px;
    display: flex;
    font-family: 'Montserrat', Helvetica, Arial, sans-serif !important;
    font-weight: bold;
    align-items: center;
    justify-content: center;
    margin: 1vh 0vw;
    color: #de1a21 !important;
    font-size: 1em;
    text-transform: uppercase;
}

.form-group{
  margin-bottom: 0px;
}
</style>