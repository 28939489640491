<template>
<div id="tabletIn">

<section class="assessment">
<h1></h1>
</section>
</div>
</template>


<script>
export default {
    name:"blank",
    props:{

    worldName: String,
 
    },methods:{
      backToParentComponent(){
        this.$parent.displayAssessment();
      },
    },
        computed: {
         

        },  watch: {

  },
     data() {
       var questionOptions=[
          { text: 'Yes', value: true },
          { text: 'No', value: false },
   
        ]
        var defaultSelected=null;
      return {
        
                what_it_takes:{
                          aerospace:[   
                          { 
                            text: 'An interest in science, math, and technical concepts',
          
                          },
                          {  
                            text: ' Strong math and science skills', 
    
                          },
                          {
                           text: 'Following directions', 
                   
                          },
                          {  text: 'Attention to detail  '
                          },
                          {  text: 'Working on a team', 
                          },

                        ],
                        construction:[
                          { 
                            text: 'construction bullet point 1 ',
          
                          },
                          {  
                            text: ' construction bullet point 2 ', 
    
                          },
                          {
                           text: 'construction bullet point 3 ', 
                   
                          },
                          {  text: 'construction bullet point 4   '
                          },
                          {  text: 'construction bullet point 5 ', 
                          },

                        ],
                        energy:[
                           { text: 'energy bullet point 1 '},
                          {  text: ' energy  bullet point 2 '},
                          {  text: 'energy bullet point 3 ' },
                          {  text: 'energy  bullet point 4' },
                          {  text: 'energy bullet point 5 ' },
                        ],
                        engineering:[
                           { text: 'engineering bullet point 1 '},
                          {  text: ' engineering  bullet point 2 '},
                          {  text: 'engineering bullet point 3 ' },
                          {  text: 'engineering  bullet point 4' },
                          {  text: 'engineering bullet point 5 ' },

                        ],
                        healthcare:[
                           { text: 'healthcare bullet point 1 '},
                          {  text: ' healthcare  bullet point 2 '},
                          {  text: 'healthcare bullet point 3 ' },
                          {  text: 'healthcare  bullet point 4' },
                          {  text: 'healthcare bullet point 5 ' },

                        ],                       
                        hospitality:[
                           { text: 'hospitality bullet point 1 '},
                          {  text: ' hospitality  bullet point 2 '},
                          {  text: 'hospitality bullet point 3 ' },
                          {  text: 'hospitality  bullet point 4' },
                          {  text: 'hospitality bullet point 5 ' },

                        ],
                        it:[
                            { text: 'it bullet point 1 '},
                          {  text: ' it  bullet point 2 '},
                          {  text: 'it bullet point 3 ' },
                          {  text: 'it  bullet point 4' },
                          {  text: 'it bullet point 5 ' },

                        ],
                        manufacturing:[
                            { text: 'manufacturing bullet point 1 '},
                          {  text: ' manufacturing  bullet point 2 '},
                          {  text: 'manufacturing bullet point 3 ' },
                          {  text: 'manufacturing  bullet point 4' },
                          {  text: 'manufacturing bullet point 5 ' },

                        ],
                        publicservice:[
                           { text: 'publicservice bullet point 1 '},
                          {  text: ' publicservice  bullet point 2 '},
                          {  text: 'publicservice bullet point 3 ' },
                          {  text: 'publicservice  bullet point 4' },
                          {  text: 'publicservice bullet point 5 ' },
                        ],
                        safety:[
                             { text: 'safety bullet point 1 '},
                          {  text: ' safety  bullet point 2 '},
                          {  text: 'safety bullet point 3 ' },
                          {  text: 'safety  bullet point 4' },
                          {  text: 'safety bullet point 5 ' },

                        ],
                        shipbuilding:[
                           { text: 'shipbuilding bullet point 1 '},
                          {  text: ' shipbuilding  bullet point 2 '},
                          {  text: 'shipbuilding bullet point 3 ' },
                          {  text: 'shipbuilding  bullet point 4' },
                          {  text: 'shipbuilding bullet point 5 ' },
                        ],
                        supplychain:[
                           { text: 'supplychain bullet point 1 '},
                          {  text: ' supplychain  bullet point 2 '},
                          {  text: 'supplychain bullet point 3 ' },
                          {  text: 'supplychain  bullet point 4' },
                          {  text: 'supplychain bullet point 5 ' },

                        ],
                },
                questions:{ 
                        aerospace:[
                          { 
                            questiontext: 'Do you enjoy working on projects as part of a team?',
                            selected:defaultSelected,
                            options:questionOptions 
                          },
                          {  
                            questiontext: 'Do you enjoy problem solving?', 
                            selected:defaultSelected,
                            options:questionOptions },
                          {  
                            questiontext: 'Do you enjoy working with tools?', 
                            selected:defaultSelected,  
                            options:questionOptions
                          },
                          {  questiontext: 'Do you have a strong attention to detail?', selected:defaultSelected,  options:questionOptions},
                          {  questiontext: 'Do you have strong math, science, and computer skills?', selected:defaultSelected,options:questionOptions},

                        ],
                        construction:[
                          { questiontext: ' construction_question1',selected:defaultSelected,  options:questionOptions},
                          {  questiontext: 'construction_question2', selected:defaultSelected, options:questionOptions},
                          {  questiontext: 'construction_question3', selected:defaultSelected,  options:questionOptions},
                          {  questiontext: 'construction_question4', selected:defaultSelected,  options:questionOptions},
                          {  questiontext: 'construction_question5', selected:defaultSelected,  options:questionOptions},

                        ],
                        energy:[
                          { questiontext: 'energy_question1',selected:defaultSelected,  options:questionOptions},
                          {  questiontext: 'energy_question2', selected:defaultSelected, options:questionOptions},
                          {  questiontext: 'energy_question3', selected:defaultSelected,  options:questionOptions},
                          {  questiontext: 'energy_question4', selected:defaultSelected,  options:questionOptions},
                          {  questiontext: 'energy_question5', selected:defaultSelected,  options:questionOptions},

                        ],
                        engineering:[
                          { questiontext: ' engineering_question1',selected:defaultSelected,  options:questionOptions},
                          {  questiontext: 'engineering_question2', selected:defaultSelected, options:questionOptions},
                          {  questiontext: 'engineering_question3', selected:defaultSelected,  options:questionOptions},
                          {  questiontext: 'engineering_question4', selected:defaultSelected,  options:questionOptions},
                          {  questiontext: 'engineering_question5', selected:defaultSelected,  options:questionOptions},

                        ],
                        healthcare:[
                          { questiontext: 'healthcare_question1',selected:defaultSelected,  options:questionOptions},
                          {  questiontext: 'healthcare_question2', selected:defaultSelected, options:questionOptions},
                          {  questiontext: 'healthcare_question3', selected:defaultSelected,  options:questionOptions},
                          {  questiontext: 'healthcare_question4', selected:defaultSelected,  options:questionOptions},
                          {  questiontext: 'healthcare_question5', selected:defaultSelected,  options:questionOptions},

                        ],                       
                        hospitality:[
                          { questiontext: ' hospitality_question1',selected:defaultSelected,  options:questionOptions},
                          {  questiontext: 'hospitality_question2', selected:defaultSelected, options:questionOptions},
                          {  questiontext: 'hospitality_question3', selected:defaultSelected,  options:questionOptions},
                          {  questiontext: 'hospitality_question4', selected:defaultSelected,  options:questionOptions},
                          {  questiontext: 'hospitality_question5', selected:defaultSelected,  options:questionOptions},

                        ],
                        it:[
                          { questiontext: 'it_question1',selected:defaultSelected,  options:questionOptions},
                          {  questiontext: 'it_question2', selected:defaultSelected, options:questionOptions},
                          {  questiontext: 'it_question3', selected:defaultSelected,  options:questionOptions},
                          {  questiontext: 'it_question4', selected:defaultSelected,  options:questionOptions},
                          {  questiontext: 'it_question5', selected:defaultSelected,  options:questionOptions},

                        ],
                        manufacturing:[
                          { questiontext: ' manufacturing_question1',selected:defaultSelected,  options:questionOptions},
                          {  questiontext: 'manufacturing_question2', selected:defaultSelected, options:questionOptions},
                          {  questiontext: 'manufacturing_question3', selected:defaultSelected,  options:questionOptions},
                          {  questiontext: 'manufacturing_question4', selected:defaultSelected,  options:questionOptions},
                          {  questiontext: 'manufacturing_question5', selected:defaultSelected,  options:questionOptions},

                        ],
                        publicservice:[
                          { questiontext: 'publicservice_question1',selected:defaultSelected,  options:questionOptions},
                          {  questiontext: 'publicservice_question2', selected:defaultSelected, options:questionOptions},
                          {  questiontext: 'publicservice_question3', selected:defaultSelected,  options:questionOptions},
                          {  questiontext: 'publicservice_question4', selected:defaultSelected,  options:questionOptions},
                          {  questiontext: 'publicservice_question5', selected:defaultSelected,  options:questionOptions},

                        ],
                        safety:[
                          { questiontext: 'safety_question1',selected:defaultSelected,  options:questionOptions},
                          {  questiontext: 'safety_question2', selected:defaultSelected, options:questionOptions},
                          {  questiontext: 'safety_question3', selected:defaultSelected,  options:questionOptions},
                          {  questiontext: 'safety_question4', selected:defaultSelected,  options:questionOptions},
                          {  questiontext: 'safety_question5', selected:defaultSelected,  options:questionOptions},

                        ],
                        shipbuilding:[
                          { questiontext: 'shipbuilding_question1',selected:defaultSelected,  options:questionOptions},
                          {  questiontext: 'shipbuilding_question2', selected:defaultSelected, options:questionOptions},
                          {  questiontext: 'shipbuilding_question3', selected:defaultSelected,  options:questionOptions},
                          {  questiontext: 'shipbuilding_question4', selected:defaultSelected,  options:questionOptions},
                          {  questiontext: 'shipbuilding_question5', selected:defaultSelected,  options:questionOptions},

                        ],
                        supplychain:[
                          { questiontext: 'supplychain_question1',selected:defaultSelected,  options:questionOptions},
                          {  questiontext: 'supplychain_question2', selected:defaultSelected, options:questionOptions},
                          {  questiontext: 'supplychain_question3', selected:defaultSelected,  options:questionOptions},
                          {  questiontext: 'supplychain_question4', selected:defaultSelected,  options:questionOptions},
                          {  questiontext: 'supplychain_question5', selected:defaultSelected,  options:questionOptions},

                        ],
                        

      }
      }
    }
}
</script>

<style>


</style>