<template>
  <div class="hello">
    <h1 class="top-covering-title"> {{selectProduct(region,$route.params.worldname)}}</h1>
     <!-- <lottie-animation id="lottieWorldAnim"
      ref="anim"
      :animationData="require(`@/assets/lottie_worlds/${region}/${world}.json`)"
      :loop="true"/>-->

    <div id="lottieWorldAnim" ref="animworld"/>

    <Sidebar :worldname="world"/>
    <button class="l-pause" @click="lottiePause"><b-icon icon="pause" aria-hidden="true" variant="success"></b-icon></button>
    <button class="l-play" @click="lottiePlay"><b-icon icon="play" aria-hidden="true" variant="success"></b-icon></button>
  </div>
</template>

<script>
import Sidebar from  './sidebarcomp'
//import LottieAnimation from 'lottie-web-vue' // import lottie-web-vue
import worldNameMixin from '../shared_methods/worldname_mixin'
import Lottie from 'lottie-web';
import gamificationMixin from '../shared_methods/gamificationMixin';

export default {
  name: 'WorldHome',
  mixins: [worldNameMixin,gamificationMixin],

  props: {
    msg: String
  },

  data () {
      return {
                world:"",
                region:process.env.VUE_APP_CURRENT_REGION.toLowerCase(),
                animName: 'worldAnim',
              pauseAmbient:false
            }
  },
  methods: {
        init() {
      // Grab the current world's animation data
      const animationData =  JSON.parse(JSON.stringify(require(`@/assets/lottie_worlds/${this.region}/${this.world}.json`)));

      this.anim = Lottie.loadAnimation({
        container: document.getElementById('lottieWorldAnim'), // TODO: Using Vue ref needs to be debugged
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData,
        name: this.animName
      });
    },
      lottiePause() {
        this.$refs.anim.pause() // .play, .pause, .stop available
      },  
      lottiePlay() {
        this.$refs.anim.play() // .play, .pause, .stop available
      },  
  
},
    created(){
    this.world = this.$route.params.worldname;


  },
  
  mounted() {
          this.init();
      //    if (this.pauseAmbient ==true)
      //    {
    //      this.$parent.$parent.restartAmbient()

     //     }
 //console.log(this.init())
      // `this` points to the vm instance
   //    console.log(this.$store.state.interaction_tracking_store[this.world].pagestate.pagevisited)
         var visitedPage =this.$store.state.interaction_tracking_store[this.world].pagestate.pagevisited;
         //alert(visitedPage)
          if (visitedPage==false)
          {
               this.$store.commit('interaction_tracking_store/SET_PAGEVISIT',{
        worldname:this.world,
     
      
      
      });
        this.$bvToast.toast(` You Visited : ${this.selectProduct(this.region,this.world)} World! You Earned +25 Points! `, {
          title: `Mission Completed`,
          autoHideDelay: 5000,
          appendToast: true
        })
           //##INSERT FIREBASE INSERT
   //##INSERT FIREBASE INSERT ADD COUNT


                              this.$store.commit('awards_store/ADD_COMP_MISSIONS',{
        missionName:`${this.world}_VISITWORLD`,
        world:this.world
      
     
      
     
       });
                             this.$store.commit('awards_store/ADD_POINTS',{
       points:25
     
      
      
      });
          } 
       
         else{
                 const badgeAwarded= this.badgeAwarded(this.$store.state,{badgeSrc:"persistance",badgeName:"persistance"})
                 if (badgeAwarded){
                 //  console.log("already")
                 }
                 else{
            this.$store.commit('awards_store/ADD_BADGE',{
        badgeSrc:"persistance",
        badgeName:'persistance'
     
      
      
      });
        this.$bvToast.toast(`Welcome Back `, {
          title: `${this.selectProduct(this.region,this.world)} World`,
          autoHideDelay: 5000,
          appendToast: true
        })

// Use a shorter name for this.$createElement
        const h2 = this.$createElement
        // Increment the toast count
        this.count++
        // Create the message
        const vNodesMsg2 = h2(
     'p',
          { class: ['text-center', 'mb-0'] },
           [
            h2('b-spinner', { props: { type: 'grow', small: true } }),
            ` Persistance Pays: Visited Same World More Than Once +100 Points! `,
            h2('b-spinner', { props: { type: 'grow', small: true } })
          ]
        )
        // Create the title
        const vNodesTitle2 = h2(
          'div',
          { class: ['d-flex', 'flex-grow-1', 'align-items-baseline', 'mr-2'] },
          [
            h2('strong', { class: 'mr-2' }, 'SECRET ACHIEVEMENT'),
            h2('small', { class: 'ml-auto text-italics' }, '5 minutes ago')
          ]
        )
        // Pass the VNodes as an array for message and title
        this.$bvToast.toast([vNodesMsg2], {
          title: [vNodesTitle2],
          solid: true,
          autoHideDelay: 3500,
          appendToast: true,

          variant: 'info'
        })
     // Use a shorter name for this.$createElement
        const h = this.$createElement
        // Increment the toast count
        this.count++
        // Create the message
        const vNodesMsg = h(
          'b-img',
          { props: { src: require('@/assets/badges/persistance-badge.png') } 
          },
           
        )
        // Create the title
        const vNodesTitle = h(
          'div',
          { class: ['d-flex', 'flex-grow-1', 'align-items-baseline', 'mr-2'] },
          [
            h('strong', { class: 'mr-2' }, 'BADGE ACQUIRED'),
            h('small', { class: 'ml-auto text-italics' }, '5 minutes ago')
          ]
        )
        // Pass the VNodes as an array for message and title
        this.$bvToast.toast([vNodesMsg], {
          title: [vNodesTitle],
          solid: true,
          autoHideDelay: 3400,
          appendToast: true,

          variant: 'info'
        })
                          this.$store.commit('awards_store/ADD_POINTS',{
       points:100
     
      
      
      });
                                this.$store.commit('awards_store/ADD_SECRET_ACHIEVE',{
       achieveName:"persistance"
     
      
      
      });
         }
         }
    
   },
  components: {
    Sidebar,
   // LottieAnimation
  },
  beforeDestroy() {
          Lottie.destroy(this.animName);

  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

#lottieWorldAnim svg {
    width: 100vw !important;
    height: auto !important;
}

h1.top-covering-title {
    top: 0vh !important;
    width: 100vw;
    background-color: #0e2332;
    height: 7vh;
    padding: 3vh 0 8vh;
    color: white !important;
    z-index: 1;
    text-align: center;
    text-transform: uppercase;
    font-weight: bolder;
    border-top: 10px solid #de1a21;
}

h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
  #lottieWorldAnim svg {
    /* I'm in worldhome */
    width: auto !important;
    height: 100vh !important;
}

#nav {
    width: 77vw !important;
    height: 29vh !important;
    bottom: -10vh !important;
    left: 13.5vw !important;
}

h1.top-covering-title {
    height: 20vh !important;
}

}


</style>
