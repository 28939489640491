import { render, staticRenderFns } from "./law_public_safety_and_corrections_form.vue?vue&type=template&id=0a8a4162&scoped=true&"
import script from "./law_public_safety_and_corrections_form.vue?vue&type=script&lang=js&"
export * from "./law_public_safety_and_corrections_form.vue?vue&type=script&lang=js&"
import style0 from "./law_public_safety_and_corrections_form.vue?vue&type=style&index=0&id=0a8a4162&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a8a4162",
  null
  
)

export default component.exports