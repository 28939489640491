<template>
<div>
<h1>{{selectProduct(region,worldName)}} Job  </h1>
<button class="back-button sub-back" @click="backToParentComponent"><b-icon icon="chevron-left" aria-hidden="true" style="width: 42px; height: 42px; color: #de1a21 !important;"></b-icon></button>
  <b-row>
    <b-col class="job-content" cols="6">
        <h2 class="job-title">{{jobInfo.jobtitle}}</h2>
        <p class="job-description">{{jobInfo.description}}</p>
        <h5>Salary: {{jobInfo.salary}}</h5>
             <div v-if="jobInfo.jobMedia.imageOrVideo=='video'">
               
              <videoToggleWrapper :worldName='worldName'  :videoInfo='jobInfo.jobMedia'/> 
              </div>
              <div v-else>
                 <img :src="require(`@/assets/${region}-images/${jobInfo.srcOrHref}.jpg`)">

  
                </div>
    </b-col>
    <b-col cols="6" class="rightJobSpace">
      <p style="text-align:center; margin-top: 0vh; margin-bottom:5px; font-size:0.8vw;">Explore the Skills and Tasks Below</p>
      <div v-for='(buttonrow,index) in jobInfo.communicationbuttons' :key='index' >
          <b-col cols="6">
              <div v-if='index==0'>
                <b-overlay :variant="variant" blur="5px"  @click="OverlayClick(index)" :show="show0" rounded="sm" :opacity="1">
                  <b-card  @click="show0 = !show0" :aria-hidden="show ? 'true' : null">
                    <b-card-text style="margin-bottom:4px;"><b>{{buttonrow.title}}</b></b-card-text>
                    <b-card-text>{{buttonrow.innerText}}  </b-card-text>
                  </b-card>
                  <template #overlay >
                        <div class="text-bottom">
                          <img src="https://sawdcalabamaworks.com/wp-content/uploads/maintenance-icon.png" width="40px" height="auto"/>
                          <p id="cancel-label">{{buttonrow.title}}</p>
                        </div>
                  </template>
                </b-overlay>
              </div>
              <div v-if='index==1'>
                <b-overlay :variant="variant"  blur="5px" @click="OverlayClick(index)" :show="show1" rounded="sm" :opacity="1">
                  <b-card  @click="show1 = !show1"  :aria-hidden="show ? 'true' : null">
                    <b-card-text style="margin-bottom:4px;"><b>{{buttonrow.title}}</b></b-card-text>
                    <b-card-text>{{buttonrow.innerText}}  </b-card-text>
                  </b-card>
                  <template #overlay >
                        <div class="text-bottom">
                          <img src="https://sawdcalabamaworks.com/wp-content/uploads/maintenance-icon.png" width="40px" height="auto"/>
                          <p id="cancel-label">{{buttonrow.title}}</p>
                        </div>
                  </template>
                </b-overlay>
              </div>
              <div v-if='index==2'>
                <b-overlay :variant="variant"  blur="5px" @click="OverlayClick(index)" :show="show2" rounded="sm" :opacity="1">
                  <b-card  @click="show2 = !show2" :aria-hidden="show ? 'true' : null">
                    <b-card-text style="margin-bottom:4px;"><b>{{buttonrow.title}}</b></b-card-text>
                    <b-card-text>{{buttonrow.innerText}}  </b-card-text>
                  </b-card>
                  <template #overlay >
                        <div class="text-bottom">
                          <img src="https://sawdcalabamaworks.com/wp-content/uploads/maintenance-icon.png" width="40px" height="auto"/>
                          <p id="cancel-label">{{buttonrow.title}}</p>
                        </div>
                  </template>
                </b-overlay>
              </div>
          </b-col>
          <b-col cols="6">
              <div v-if='index==3'>
                <b-overlay :variant="variant"  blur="5px" @click="OverlayClick(index)" :show="show3" rounded="sm" :opacity="1">
                  <b-card  @click="show3 = !show3"  :aria-hidden="show ? 'true' : null">
                    <b-card-text style="margin-bottom:4px;"><b>{{buttonrow.title}}</b></b-card-text>
                    <b-card-text>{{buttonrow.innerText}}  </b-card-text>
                  </b-card>
                  <template #overlay >
                        <div class="text-bottom">
                          <img src="https://sawdcalabamaworks.com/wp-content/uploads/maintenance-icon.png" width="40px" height="auto"/>
                          <p id="cancel-label">{{buttonrow.title}}</p>
                        </div>
                  </template>
                </b-overlay>
              </div>
              <div v-if='index==4'>
                <b-overlay :variant="variant"  blur="5px" @click="OverlayClick(index)" :show="show4" rounded="sm" :opacity="1">
                  <b-card  @click="show4 = !show4" :aria-hidden="show ? 'true' : null">
                    <b-card-text style="margin-bottom:4px;"><b>{{buttonrow.title}}</b></b-card-text>
                    <b-card-text>{{buttonrow.innerText}}  </b-card-text>
                  </b-card>
                  <template #overlay >
                        <div class="text-bottom">
                          <img src="https://sawdcalabamaworks.com/wp-content/uploads/maintenance-icon.png" width="40px" height="auto"/>
                          <p id="cancel-label">{{buttonrow.title}}</p>
                        </div>
                  </template>
                </b-overlay>
              </div>
              <div v-if='index==5'>
                <b-overlay :variant="variant"  blur="5px" @click="OverlayClick(index)" :show="show5" rounded="sm" :opacity="1">
                  <b-card  @click="show5 = !show5"  :aria-hidden="show ? 'true' : null">
                    <b-card-text style="margin-bottom:4px;"><b>{{buttonrow.title}}</b></b-card-text> 
                    <b-card-text>{{buttonrow.innerText}}  </b-card-text>
                  </b-card>
                  <template #overlay >
                        <div class="text-bottom">
                          <img src="https://sawdcalabamaworks.com/wp-content/uploads/maintenance-icon.png" width="40px" height="auto"/>
                          <p id="cancel-label">{{buttonrow.title}}</p>
                        </div>
                  </template>
                </b-overlay>
              </div>
          </b-col>
      </div>
    </b-col>
  </b-row>
</div>
</template>


<script>
import videoToggleWrapper from '../videoToggleWrapper';
import worldNameMixin from '../../../shared_methods/worldname_mixin'
import gamificationMixin from '../../../shared_methods/gamificationMixin'
export default {

    name:"IndividualJobPage",
     mixins: [worldNameMixin,gamificationMixin],
    props:{
     jobInfo:{},
    worldName: String,
     
    },methods:{
      backToParentComponent(){
        this.$parent.displayJobPage(true);
      },
      OverlayClick(index){
        //console.log("Click")
        //console.log(index)
        this["show"+index] = !this["show"+index]
        //console.log(this["show"+index]);
      }
    },
mounted(){
  //console.log(this.jobInfo.jobtitle)
  //console.log()
        const careerExists = this.careerLearnedAndLogged(this.$store.state,this.worldName,this.jobInfo.id_gamification)
    if (careerExists){
            //console.log("exists")      
    }

    else{
        this.$store.commit('interaction_tracking_store/ADD_CAREERLEARNED',{
              worldname:this.worldName,
            
          careerId:this.jobInfo.id_gamification,
            
            });

    }

},
components:{
        videoToggleWrapper
       
    },

     data() {
       

        
      return {
                   region:process.env.VUE_APP_CURRENT_REGION.toLowerCase(),

                show: [true, true, true, true, true, true],
                show0:true,
                show1:true,
                show2:true,
                show3:true,
                show4:true,
                show5:true,
                showArray: [true,true,true,true,true],
          variant:'transparent',


      }
    }
}
</script>
<style scoped>
.rightJobSpace .col-6 {
    flex: 0 0 50%;
    max-width: 50%;
    float: left;
}

.videoToggle iframe {
    width: 100%;
    height: 32vh;
    position: static;
    display: block;
}
.job-content{
  background-color: transparent;
  box-shadow: inset 2px 2px 5px #B8B9BE, inset -3px -3px 7px #fff;
  height: 48vh;
  overflow: scroll;
  overflow-x: hidden;
}
h4.card-title {
    font-size: 1.1vw;
    font-weight: bold;
    margin-bottom: .4vh;
    margin-top: 0;
}
p.card-text {
    font-size: 0.7vw;
}
.rightJobSpace .col-6 .b-overlay-wrap {
    height: 17.3vh;
    margin-bottom: 0vh;
}
.rightJobSpace {
    margin-top: 0vh;
}
h2 {
    font-size: 1.2vw;
    font-weight: bold;
    margin-top: 1vh;
}
.card{
  height:15vh;
}
.card-body {
  padding: .6rem;
}
.bg-transparent {
  height: 19vh;
}
.text-bottom {
    text-align: center;
    font-weight: bold;
    color: #062830;
}

.show-button {
  display: none !important;
}

.job-title{
  font-size: 2vw;
    font-weight: bold;
    margin-top: 0.4vw;
    position: fixed;
    top: 12vh;
    left: 23vw;
    width: 53vw;
    text-align: center;
    color: #de1a21 
}

.job-description{
  margin-top:1vh;
}
h4 {
    margin-top: 0px;
    margin-bottom: 1vw;
    font-size: 1vw;
    font-weight: bolder !important;
}
p {
    font-size: 0.8vw;;
}

.position-absolute p{
  font-size:1.2vw;
}
</style>