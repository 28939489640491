<template>
<section>
  <h1> world:{{selectProduct(region,worldName)}}</h1>

  <FormTemplates  v-bind:is="formTemplate" :world="worldName" ></FormTemplates>

</section>
</template>

<script>
import worldNameMixin from '../../shared_methods/worldname_mixin'
//sawdc imports//
import sawdc_aerospace_and_aviation    from "../forms/sawdc/aerospace_and_aviation_form";
import sawdc_construction  from "../forms/sawdc/construction_form";
import sawdc_energy  from "../forms/sawdc/energy_form";
import sawdc_engineering_and_design from "../forms/sawdc/engineering_form";
import sawdc_healthcare  from "../forms/sawdc/healthcare_form";
import sawdc_hospitality_and_tourism   from "../forms/sawdc/hospitality_form";
import sawdc_information_technology  from "../forms/sawdc/it_form";
import sawdc_manufacturing   from "../forms/sawdc/manufacturing_form";
import sawdc_public_service from "../forms/sawdc/publicservice_form";
import sawdc_safety   from "../forms/sawdc/safety_form";
import sawdc_shipbuilding_and_repair from "../forms/sawdc/shipbuilding_form";
import sawdc_transportation_distribution_and_logistics from "../forms/sawdc/transportation_distribution_and_logistics_form";
//caw imports//
import caw_agriculture from "../forms/caw/agriculture_form";
import caw_architecture_construction_and_utilities from "../forms/caw/architecture_construction_and_utilities_form";
import caw_communication_and_information_technology from "../forms/caw/communication_and_information_technology_form";
import caw_education_and_training from "../forms/caw/education_and_training_form";
import caw_healthcare from "../forms/caw/healthcare_form";
import caw_hospitality_and_tourism from "../forms/caw/hospitality_and_tourism_form";           
import caw_manufacturing from "../forms/caw/manufacturing_form";
import caw_public_service from "../forms/caw/publicservice_form";
import caw_transportation_distribution_and_logistics from "../forms/caw/transportation_distribution_and_logistics_form";
///eaw imports///
import eaw_agriculture_and_natural_resources from "../forms/eaw/agriculture_and_natural_resources";
import eaw_automotive_and_manufacturing from "../forms/eaw/automotive_and_manufacturing";
import eaw_communications_media_and_marketing from "../forms/eaw/communications_media_and_marketing";
import eaw_construction from "../forms/eaw/construction_form";
import eaw_energy_and_utilities from "../forms/eaw/energy_form";
import eaw_engineering from "../forms/eaw/engineering_form";
import eaw_healthcare from "../forms/eaw/healthcare_form";
import eaw_information_technology from "../forms/eaw/information_technology_form";
import eaw_public_safety from "../forms/eaw/public_safety_form copy";
import eaw_transportation_distribution_and_logistics from "../forms/eaw/transportation_distribution_and_logistics_form"
//waw imports//
import waw_agriculture from "../forms/waw/agriculture_form";
import waw_cosmetology from "../forms/waw/cosmetology_form";
import waw_construction from "../forms/waw/construction_form";
import waw_energy_and_utilities from "../forms/waw/energy_and_utilities_form";
import waw_engineering_and_environment from "../forms/waw/engineering_and_environment_form";
import waw_healthcare from "../forms/waw/healthcare_form";
import waw_hospitality_and_tourism from "../forms/waw/hospitality_and_tourism_form";
import waw_manufacturing_auto_logistics from "../forms/waw/manufacturing_auto_logistics_form";
import waw_public_safety from "../forms/waw/public_safety_form";
import waw_transportation from "../forms/waw/transportation_form";
import waw_education from "../forms/waw/education_form";
///naw imports///
import naw_agriculture_food_and_natural_resources from "../forms/naw/agriculture_form";
import naw_aerospace from '../forms/naw/aerospace_form.vue';
import naw_architecture_and_construction from '../forms/naw/architecture_and_construction_form'
import naw_business_and_finance from '../forms/naw/business_and_finance_form'
import naw_communications_media_and_marketing from '../forms/naw/communications_media_and_marketing_form'
import naw_cybersecurity_and_information_technology from '../forms/naw/cybersecurity_and_information_technology_form'
import naw_education_and_training from '../forms/naw/education_and_training_form'
import naw_energy_and_utilities from '../forms/naw/energy_form'
import naw_healthcare from '../forms/naw/healthcare_form'
import naw_hospitality_and_tourism from '../forms/naw/hospitality_and_tourism_form'
import naw_law_public_safety_and_corrections from '../forms/naw/law_public_safety_and_corrections_form'
import naw_modern_manufacturing_auto from '../forms/naw/modern_manufacturing_auto_form'
import naw_modern_manufacturing from '../forms/naw/modern_manufacturing_form'
import naw_transportation_distribution_and_logistics from '../forms/naw/transportation_distribution_and_logistics_form'
//sawdc imports//
import nctejw_agriculture    from "../forms/nctejw/agriculture_form";
import nctejw_biotechnology  from "../forms/nctejw/biotechnology_form";
import nctejw_construction  from "../forms/nctejw/construction_form";
import nctejw_energy_and_technology  from "../forms/nctejw/energy_and_technology_form";
import nctejw_healthcare  from "../forms/nctejw/healthcare_form";
import nctejw_hospitality_tourism_and_retail   from "../forms/nctejw/hospitality_tourism_and_retail_form";
import nctejw_manufacturing   from "../forms/nctejw/manufacturing_form";
import nctejw_public_works_and_safety from "../forms/nctejw/public_works_and_safety_form";
import nctejw_transportation_and_logistics from "../forms/nctejw/transportation_and_logistics_form";
export default {
name: 'PageSpecificAssessmentForm',

  props: {
    worldName: String,
  },
     mixins: [worldNameMixin],

  components:{
    sawdc_aerospace_and_aviation,
    sawdc_construction,
    sawdc_energy,
    sawdc_engineering_and_design,
    sawdc_healthcare,
    sawdc_hospitality_and_tourism,
    sawdc_information_technology,
    sawdc_manufacturing,
    sawdc_public_service,
    sawdc_safety,
    sawdc_shipbuilding_and_repair,
    sawdc_transportation_distribution_and_logistics,
    ///////CAW////////
    caw_agriculture,
    caw_architecture_construction_and_utilities,
    caw_communication_and_information_technology,
    caw_education_and_training,

    caw_healthcare,
    caw_hospitality_and_tourism,
    caw_manufacturing,
    caw_public_service,
    caw_transportation_distribution_and_logistics,
    ////EAW//////
    eaw_agriculture_and_natural_resources,
    eaw_automotive_and_manufacturing,
    eaw_communications_media_and_marketing,
    eaw_construction,
    eaw_energy_and_utilities,
    eaw_engineering,
    eaw_healthcare,
    eaw_information_technology,
    eaw_public_safety,
    eaw_transportation_distribution_and_logistics,
    /// WAW///
    waw_agriculture,
    waw_construction,
    waw_cosmetology,
    waw_energy_and_utilities,
    waw_engineering_and_environment,
    waw_healthcare,
    waw_hospitality_and_tourism,
    waw_manufacturing_auto_logistics,
    waw_public_safety,
    waw_transportation,
    waw_education,
    ///NAW///
    naw_agriculture_food_and_natural_resources,
    naw_aerospace,
    naw_architecture_and_construction,
    naw_business_and_finance,
    naw_communications_media_and_marketing,
    naw_cybersecurity_and_information_technology,
    naw_education_and_training,
    naw_energy_and_utilities,
    naw_healthcare,
    naw_hospitality_and_tourism,
    naw_law_public_safety_and_corrections,
    naw_modern_manufacturing_auto,
    naw_modern_manufacturing,
    naw_transportation_distribution_and_logistics,
    //nctejw
    nctejw_agriculture,
    nctejw_construction,
    nctejw_energy_and_technology,
    nctejw_biotechnology,
    nctejw_healthcare,
    nctejw_hospitality_tourism_and_retail,

    nctejw_manufacturing,
    nctejw_public_works_and_safety,

    nctejw_transportation_and_logistics,
  },
    data() {
      return {
            formTemplate:process.env.VUE_APP_CURRENT_REGION.toLowerCase()+"_"+this.worldName,
                       region:process.env.VUE_APP_CURRENT_REGION.toLowerCase(),

      }
    },
 created(){
    //console.log(this.$store.state.assessment_form_store.mutations);
  
this.$store.watch(
  // eslint-disable-next-line no-unused-vars
  (state)=>{
      //console.log(state)
    return this.$store.state.assessment_form_store // could also put a Getter here
  },
  // eslint-disable-next-line no-unused-vars
  (newValue, oldValue)=>{
    //something changed do something
    //console.log(oldValue)
    //console.log(newValue)
  },
//Optional Deep if you need it
    {
      deep:true
    }
  )

  },
  computed: {
      
    question1: {
      get() {
          //console.warn(this.world)
        return this.$store.state.assessment_form_store[this.world].formstate.firstName;
      },
      set(value) {
        this.$store.commit('assessment_form_store/SET_FIRSTNAME',{formid:this.world, value:value});
      },
    },
    question2: {
      get() {
        return this.$store.state.assessment_form_store[this.world].formstate.lastName;
      },
      set(value) {
        this.$store.commit('assessment_form_store/SET_LASTNAME',{formid:this.world, value:value});
      },
    },
    question3: {
      get() {
        return this.$store.state.assessment_form_store[this.world].formstate.message;
      },
      set(value) {
        this.$store.commit('assessment_form_store/SET_MESSAGE',{formid:this.world, value:value});
      },
    },
    question4: {
      get() {
        return this.$store.state.assessment_form_store[this.world].formstate.message;
      },
      set(value) {
        this.$store.commit('assessment_form_store/SET_MESSAGE',{formid:this.world, value:value});
      },
    }
    },
  //  ...mapFields('assessment_form_store',[
    //  'form2.formstate.firstName',
      //'form2.formstate.lastName',
      //'form2.formstate.message',
      // ...
    //]),
      methods:{
      // eslint-disable-next-line no-unused-vars
      onSubmit(event) {
        console.log(event);
                //alert("form Info Saved")
        this.$bvToast.toast(` Go Back to Research Lab `, {
                    to: '/worlds',

          title: `Form Info Saved`,
          autoHideDelay: 5000,
          appendToast: true
        })       


      },

      }
};
</script>

<style scoped>
  .btn span {
    font-size: 14px;
    font-weight: bold;
    color: #de1a21;
  }
  
</style>

