//copy this to create a new all world template.  database eventually.

var exploreBlurbMixin  = {
    methods: {
      selectExploreBlurb: function (regionName,worldName) {
        var worldsArrays={
            SAWDC:SAWDC_Quiz_Object,
            CAW:CAW_Explore_Object,
            WAW:WAW_Quiz_Object,
            NAW:NAW_Quiz_Object,
            EAW:EAW_Quiz_Object,
            NCTEJW:NCTEJW_Quiz_Object

  
          }
          return worldsArrays[regionName][worldName]
      }
    }
  };
  export default exploreBlurbMixin
  /*########################################################################################################*/
/*########################################################################################################*/
/*########################################################################################################*/
var SAWDC_Quiz_Object={ 
    aerospace_and_aviation:{
      mediaInfo:{
          videoType:"youtube",link:"",videoId:"IHkj7hKHFtM", imageOrVideo:'video',srcOrHref:'https://youtu.be/IHkj7hKHFtM'
        },
      paragraph1:"The aerospace industry is one of the key clusters in Southwest Alabama and includes several of the largest employers in the region. This cluster employs highly-skilled workers in the region who earn on average $68,000. The aerospace industry bolsters the Southwest Alabama regions reputation as a leader in high technology and innovation. ",
      paragraph2:"The SAWDC region is part of the 3rd largest Aerospace Corridor in the world, known as the Aerospace Alliance. This multi-state corridor is home to over 200,000 workers and that number continues to grow. With the right training, you too could join this field, and continue the regions tradition of Aerospace excellence.",
       
  },
    construction:{
      mediaInfo:{
          videoType:"youtube",link:"",videoId:"nEgbNw7TNl0", imageOrVideo:'video',srcOrHref:'https://youtu.be/nEgbNw7TNl0'
        },
      paragraph1:"Architecture and construction workers work on buildings and other structures. This includes highways, bridges, houses, and buildings. You might create the designs or plans for new structures. Or, you might use the plans to build it or manage the workers on the project.",
      paragraph2:"Some architecture and construction workers do skilled trades, like carpentry, painting, or plumbing. For example, you might do all of the electrical work for a renovated office space. Or, you might work as a landscaper and take care of flowers and trees on large properties.",
       
  },
    energy:{
      mediaInfo:{
          videoType:"youtube",link:"",videoId:"11dLR8MUu_A", imageOrVideo:'video',srcOrHref:'https://youtu.be/11dLR8MUu_A'
        },
      paragraph1:"Looking for a cutting-edge career? You’ll want to explore careers in this cluster. From building offshore wind farms to designing solar roads, the energy industry offers some of the coolest careers out there. And they’re not just for engineers and scientists!",
      paragraph2:" Discover the types of careers below that exist in wind, ethanol, residential energy-efficiency, and commercial energy-efficiency industries.",
       
  },
    engineering_and_design:{
      mediaInfo:{
          videoType:"youtube",link:"",videoId:"Yja-S9jYjVQ", imageOrVideo:'video',srcOrHref:'https://youtu.be/Yja-S9jYjVQ'
        },
      paragraph1:"Southwest Alabama is home to thousands of opportunities for those interested in engineering and design.  This world requires strong math and science skills paired with creativity and an open mind. ",
      paragraph2:"Whether you are building the next skyscraper, designing a faster airplane, planning a city block, or creating something completely new, engineers and designers find homes in every industry and bring their visions to life.",
       
  },
    healthcare:{
      mediaInfo:{
          videoType:"youtube",link:"",videoId:"wD1is_qkoPE", imageOrVideo:'video',srcOrHref:'https://youtu.be/wD1is_qkoPE'
        },
      paragraph1:"Southwest Alabama is home to a vibrant healthcare community that includes not only health care providers but researches and educators as well. The healthcare industry is a stabilizing force in the regional labor market that can stay strong and expand employment throughout the economic cycle. The healthcare cluster provides world-class treatment for residents in Southwest Alabama while also providing challenging and rewarding jobs.",
      paragraph2:"In SAWDC’s 9 county region, healthcare provides employment for over 40,000 individuals, and make slightly above average wages compared to the rest of the jobs in the area. For those eager to serve, few jobs are as rewarding as going into the healthcare field, where they can make a difference every day.",
       
  },
  information_technology:{
    mediaInfo:{
        videoType:"youtube",link:"",videoId:"LUwpH6QEZ1Y", imageOrVideo:'video',srcOrHref:'https://youtu.be/LUwpH6QEZ1Y'
      },
    paragraph1:"Our country is increasingly reliant upon computers and IT skills are becoming critical across industries. Southwest Alabama is home to nearly 3000 IT and computer software jobs, but the number of jobs that need some level of computer skills is much greater.",
    paragraph2:"This world changes rapidly and those in the IT world must always be willing to learn and improve to stay the best in the business.",
     
},
    hospitality_and_tourism:{
      mediaInfo:{
          videoType:"youtube",link:"",videoId:"jpVJw77_C38", imageOrVideo:'video',srcOrHref:'https://youtu.be/jpVJw77_C38'
        },
      paragraph1:"The hospitality industry is one of the key clusters in Southwest Alabama. This cluster employs highly-skilled workers in the region who earn on average $69,500 with a high school education or higher. Alabamians whose first job was in Hospitality and Tourism obtain an average career salary of $81,900. Hospitality and Tourism supports roughly 60,000 jobs in Southwest Alabama.",
      paragraph2:"This industry adds a new position every 2.5 seconds and is the fastest growing in the world.  Hospitality and Tourism offers many exciting careers in the areas of: Attractions, Country Clubs, Cruise Ships, Hotels, Museums, Resorts, Restaurants, Theme Parks, Travel, and more!",
      
  },
    manufacturing:{
      mediaInfo:{
          videoType:"youtube",link:"",videoId:"L1kYKXLXf2A", imageOrVideo:'video',srcOrHref:'https://youtu.be/L1kYKXLXf2A'
        },
      paragraph1:"The manufacturing cluster in Southwest Alabama includes a wide range of advanced manufacturing companies. Process manufacturers in the region include leading producers of steel, chemicals, and paper related products. The region’s skilled workforce supports manufacturing which directly employs over 25,000 workers in Southwest Alabama.",
      paragraph2:"Manufacturer employers seek individuals who are problem solvers with technical skills and creative minds. Whether you are an engineer looking to solve the latest puzzle in production or an operator ensuring everything runs as planned, manufacturing can provide challenging and rewarding employment.",
       
  },
    public_service:{
      mediaInfo:{
          videoType:"youtube",link:"",videoId:"iRQcB32C_j4", imageOrVideo:'video',srcOrHref:'https://youtu.be/iRQcB32C_j4'
        },
      paragraph1:"Law, public service, corrections, and security workers are found in a variety of settings. For example, you might guard the public and enforce the law as a police officer or security guard. Or, you might provide fire protection as a firefighter.",
      paragraph2:"Other workers provide legal services to people who commit crimes. For example, you might monitor activities within a prison, advise clients of their legal rights, or research legal procedures to support court cases.",
       
  },
    safety:{
      mediaInfo:{
          videoType:"youtube",link:"",videoId:"zXTZQQURY0E", imageOrVideo:'video',srcOrHref:'https://youtu.be/zXTZQQURY0E'
        },
      paragraph1:"If you are looking for a rewarding career that allows you to apply many different skills, work with people and influence leaders, the safety profession is for you.Occupational safety and health (OSH) professionals have rewarding jobs, creating safe work environments by preventing workplace fatalities, injuries and illnesses.",
      paragraph2:" But choosing to be a safety professional isn’t only about being altruistic. It is also a practical decision. Safety careers offer job availability, excellent salaries and career advancement and leadership opportunities.",
       
   },
    shipbuilding_and_repair:{
      mediaInfo:{
          videoType:"youtube",link:"",videoId:"dr8j1oR4xgc", imageOrVideo:'video',srcOrHref:'https://youtu.be/dr8j1oR4xgc'
        },
      paragraph1:"Southwest Alabama supports a major maritime cluster, spearheaded by its renowned shipbuilding industry. The Mobile Bay gives the region a natural advantage to propel and sustain this highly competitive cluster. The maritime cluster includes not only shipbuilding, but port activities and the economic activity generated by water transportation enterprises.",
      paragraph2:"Shipbuilding alone supports over 16,000 employees, with an average wage of $63,000. According to Top Rank 2019 magazine, the largest high-tech manufacturer in the state of Alabama is a maritime company in Mobile. The port has long driven commerce to Mobile, and with the proper support it will continue to do so for generations to come.",
       
  },
    transportation_distribution_and_logistics:{
      mediaInfo:{
          videoType:"youtube",link:"",videoId:"6NQxqfgXBIc", imageOrVideo:'video',srcOrHref:'https://youtu.be/6NQxqfgXBIc'
        },
      paragraph1:"Today, we are able to order an item and expect to receive it very quickly, often in less than a week. This is because of the people in the Transportation and Logistics World. In Southwest Alabama we have over 15,000 individuals working everyday to ensure we get our goods on time.",
      paragraph2:"From the warehouse stocker loading the truck, to the trucker driving across country, these men and women work together to get us what we need.",
       
  },
  
   
  }
  
  
  /*########################################################################################################*/
  /*########################################################################################################*/
  /*########################################################################################################*/
  var NAW_Quiz_Object={ 
    agriculture_food_and_natural_resources:{
      mediaInfo:{
        videoType:'',link:"agriculture-explore",videoId:'', imageOrVideo:'img',srcOrHref:'agriculture-explore'
      },
      paragraph1:"You are surrounded by agriculture. From the food we eat, the clothes we wear, and the houses we live in, agriculture is all around us.",
      paragraph2:"Agriculture has a significant impact on Alabama’s economy, the health of its citizens, and the beauty of its landscapes. If you are interested in a career taking you outdoors, consider a career in agriculture.",
       
  },
    architecture_and_construction:{
      mediaInfo:{
        videoType:"youtube",link:"",videoId:"ko_OMynE3Bo", imageOrVideo:'video',srcOrHref:'https://youtu.be/ko_OMynE3Bo'
      },
      paragraph1:"Architecture and construction workers work on buildings and other structures. This includes highways, bridges, houses, and buildings. You might create the designs or plans for new structures. Or, you might use the plans to build it or manage the workers on the project.",
      paragraph2:"Some architecture and construction workers do skilled trades, like carpentry, painting, or plumbing. For example, you might do all of the electrical work for a renovated office space. Or, you might work as a landscaper and take care of flowers and trees on large properties.",
       
  },
    education_and_training:{
      mediaInfo:{
        videoType:'',link:"education-explore",videoId:'', imageOrVideo:'img',srcOrHref:'education-explore'
      },
      paragraph1:"Education and training workers guide and train people. As a teacher, you could influence young lives. You could also support the work of a classroom teacher as a counselor, librarian, or principal. You could coach sports activities or lead community classes.",
      paragraph2:"Discover the types of careers below that exist in wind, ethanol, residential energy-efficiency, and commercial energy-efficiency industries.",
       
  },
    energy_and_utilities:{
      mediaInfo:{
        videoType:"youtube",link:"",videoId:"oI1yXljQybE", imageOrVideo:'video',srcOrHref:'https://youtu.be/oI1yXljQybE'
      },
      paragraph1:"Looking for a cutting-edge career? You’ll want to explore careers in this cluster. From building offshore wind farms to designing solar roads, the energy industry offers some of the coolest careers out there. And they’re not just for engineers and scientists!",
      paragraph2:"Some architecture and construction workers do skilled trades, like carpentry, painting, or plumbing. For example, you might do all of the electrical work for a renovated office space. Or, you might work as a landscaper and take care of flowers and trees on large properties.",
       
  },
    healthcare:{
      mediaInfo:{
        videoType:"youtube",link:"",videoId:"i2pMEhEzbEs", imageOrVideo:'video',srcOrHref:'https://youtu.be/i2pMEhEzbEs'
      },
      paragraph1:"Health science workers promote health and wellness. They diagnose and treat injuries and disease. As a physician, dentist, or nurse, you could work directly with patients. You could also work in a laboratory to get information used in research or provide administrative support by keeping medical records.",
      paragraph2:"Health science jobs are found at a variety of different sites. For example, you could work in a hospital, office, clinic, or nursing home. Or you could work on a cruise ship, at a sports arena, or within a patient's home.",
       
  },
  hospitality_and_tourism:{
      mediaInfo:{
        videoType:"youtube",link:"",videoId:"Q7tTV5Ki5KU", imageOrVideo:'video',srcOrHref:'https://youtu.be/Q7tTV5Ki5KU'
      },
      paragraph1:"Hospitality and tourism workers help people enjoy vacations and entertainment activities. You might work at a restaurant, resort, sports arena, theme park, museum, or hotel.",
      paragraph2:"For example, you might manage operations of a college cafeteria, guide high school students on a trip to Spain, or rent equipment at a recreation center.",
      
  },
    modern_manufacturing:{
      mediaInfo:{
        videoType:"youtube",link:"",videoId:"vbb4SZOTK2Y", imageOrVideo:'video',srcOrHref:'https://youtu.be/vbb4SZOTK2Y'
      },
      paragraph1:"Manufacturing workers work with products and equipment. You might design a new product, decide how the product will be made, or make the product. You might work on cars, computers, appliances, airplanes, or electronic devices.",
      paragraph2:"Other manufacturing workers install or repair products. For example, you might install refrigerators or washers, repair plumbing in commercial buildings, or diagnose engine problems in cars and trucks.",
      
  },
    modern_manufacturing_auto:{
      mediaInfo:{
        videoType:'',link:"automotive-explore",videoId:'', imageOrVideo:'img',srcOrHref:'automotive-explore'
      },
      paragraph1:"Automotive manufacturing includes companies and activities involved in the manufacture of motor vehicles, including most components, such as engines and bodies, but excluding tires, batteries, and fuel. The industry’s principal products are passenger automobiles and light trucks, including pickups, vans, and sport utility vehicles. You could work as a designer, creating concept cars or work on the line ensuring that vehicles are built properly.",
      paragraph2:"Employment in Alabama’s automotive manufacturing sector now exceeds 40,000, surging from just a few thousand in the days before Mercedes. Around 26,000 of these jobs are in Alabama’s growing automotive supplier network, which now counts 150 major companies.",
       
  },
    law_public_safety_and_corrections:{
      mediaInfo:{
        videoType:'',link:"law-explore",videoId:'', imageOrVideo:'img',srcOrHref:'law-explore'
      },
      paragraph1:"Law, public safety, corrections, and security workers are found in a variety of settings. For example, you might guard the public and enforce the law as a police officer or security guard. Or, you might provide fire protection as a firefighter.",
      paragraph2:"Other workers provide legal services to people who commit crimes. For example, you might monitor activities within a prison, advise clients of their legal rights, or research legal procedures to support court cases.",
       
  },
    transportation_distribution_and_logistics:{
      mediaInfo:{
        videoType:"youtube",link:"",videoId:"5Gsam6jyRI0", imageOrVideo:'video',srcOrHref:'https://youtu.be/5Gsam6jyRI0'
      },
      paragraph1:"Transportation, distribution, and logistics workers move people and products by road, air, rail, and water. You might work as a driver, pilot, engineer, or captain.",
      paragraph2:"You might repair or maintain the vehicles, trains, planes, and ships that move people and products. Or, you might work behind the scenes to make sure the products and people get to the right place on time.",
      
  },
    aerospace:{
      mediaInfo:{
        videoType:'',link:"aerospace-explore",videoId:'', imageOrVideo:'img',srcOrHref:'aerospace-explore'
      },
      paragraph1:"The Wright brothers set up the nation’s first civilian flight school in Montgomery in 1910, and Alabamians have been reinventing the aerospace industry ever since. Rocket scientists at NASA’s Marshall Space Flight Center put man on the moon with the Saturn V during the 1960s. ",
      paragraph2:"But the future of Alabama’s aerospace sector looks just as bright today, with all the major industry players, from Airbus to Boeing, operating advanced manufacturing and research facilities in our state.",
       
  },
    business_and_finance:{
      mediaInfo:{
        videoType:'',link:"business-explore",videoId:'', imageOrVideo:'img',srcOrHref:'business-explore'
      },
      paragraph1:"Business, management, and administrative workers give the support needed to make a business run. You might check employee time records or train new employees. Or, you might work as a top executive and provide the overall direction for a company or department.",
      paragraph2:"Careers in finance involve working with money. You might work in financial planning, banking, or insurance and work with either businesses or individuals. You could also work as a consultant to business executives on how to operate their business.",
       
  },
    communications_media_and_marketing:{
      mediaInfo:{
        videoType:'',link:"marketing-explore",videoId:'', imageOrVideo:'img',srcOrHref:'marketing-explore'
      },
      paragraph1:"Communications, media and marketing workers help businesses sell products. You might advertise and promote products so customers want to buy them. Or, you might sell products and services to customers.",
      paragraph2:"For example, you might develop a marketing plan for a small start-up company; sell furniture; or measure and fit lenses and frames for eyeglass customers.",
       
  },
    cybersecurity_and_information_technology:{
      mediaInfo:{
        videoType:"youtube",link:"",videoId:"KuTOO28tRI4", imageOrVideo:'video',srcOrHref:'https://youtu.be/KuTOO28tRI4'
      },
      paragraph1:"Information technology workers are in a growing and always changing field. You might work with computer hardware, software, multimedia, or network systems. You might design new computer equipment or work on a new computer game.",
      paragraph2:"Some information technology workers support and manage computer hardware. For example, you might make sure the networks are working or manage a network that links people across the world. Or, you might support others and answer questions about specific software or hardware.",
      
  }
  }
  
  /*########################################################################################################*/
  /*########################################################################################################*/
  /*########################################################################################################*/
  var EAW_Quiz_Object={ 
    agriculture_and_natural_resources:{
        mediaInfo:{
            videoType:"youtube",link:"",videoId:"MBYHvraw-Mg", imageOrVideo:'video',srcOrHref:'https://youtu.be/1eOnm-61cDE'
          },
        paragraph1:"Agriculture, food, and natural resources workers produce agricultural goods. This includes food, plants, animals, fabrics, wood, and crops. You might work on a farm, ranch, dairy, orchard, greenhouse, or plant nursery. You could also work in a clinic or laboratory as a scientist or engineer.",
        paragraph2:"Some agriculture, food, and natural resources workers market, sell, or finance agricultural goods. For example, you might market products made from plants and animals. Or, you might sell services that farmers and ranchers use to improve products. You could also work to conserve natural resources or protect the environment.",
         
    },
    automotive_and_manufacturing:{
        mediaInfo:{
            videoType:"youtube",link:"",videoId:"vbb4SZOTK2Y", imageOrVideo:'video',srcOrHref:'https://youtu.be/1eOnm-61cDE'
          },
        paragraph1:"Manufacturing workers work with products and equipment. You might design a new product, decide how the product will be made, or make the product. You might work on cars, computers, appliances, airplanes, or electronic devices.",
        paragraph2:"Other manufacturing workers install or repair products. For example, you might install refrigerators or washers, repair plumbing in commercial buildings, or diagnose engine problems in cars and trucks.",
         
    },
    communications_media_and_marketing:{
        mediaInfo:{//Image: In Dropbox Marketing Folder – marketing-careers.jpg
            videoType:"",link:"marketing-careers",videoId:"", imageOrVideo:'img',srcOrHref:'marketing-careers'      
          },
        paragraph1:"Communications, media and marketing workers help businesses sell products. You might advertise and promote products so customers want to buy them. Or, you might sell products and services to customers.",
        paragraph2:"For example, you might develop a marketing plan for a small start-up company; sell furniture; or measure and fit lenses and frames for eyeglass customers.",
         
    },
    construction:{
        mediaInfo:{
            videoType:"youtube",link:"",videoId:"ko_OMynE3Bo", imageOrVideo:'video',srcOrHref:'https://youtu.be/1eOnm-61cDE'
          },
        paragraph1:"Architecture and construction workers work on buildings and other structures. This includes highways, bridges, houses, and buildings. You might create the designs or plans for new structures. Or, you might use the plans to build it or manage the workers on the project.",
        paragraph2:"Some architecture and construction workers do skilled trades, like carpentry, painting, or plumbing. For example, you might do all of the electrical work for a renovated office space. Or, you might work as a landscaper and take care of flowers and trees on large properties.",
         
    },
    energy_and_utilities:{
        mediaInfo:{
            videoType:"youtube",link:"",videoId:"oI1yXljQybE", imageOrVideo:'video',srcOrHref:'https://youtu.be/1eOnm-61cDE'
          },
        paragraph1:"Looking for a cutting-edge career? You’ll want to explore careers in this cluster. From building offshore wind farms to designing solar roads, the energy industry offers some of the coolest careers out there. And they’re not just for engineers and scientists! ",
        paragraph2:"Discover the types of careers below that exist in wind, ethanol, residential energy-efficiency, and commercial energy-efficiency industries.",
         
    },
    engineering:{
        mediaInfo:{
            videoType:"youtube",link:"",videoId:"9ZdNopKi7M0", imageOrVideo:'video',srcOrHref:'https://youtu.be/1eOnm-61cDE'
          },
        paragraph1:"Are you detail-oriented? Do you want to know how things work, or enjoy solving problems?",
        paragraph2:"Engineering workers do scientific research in laboratories or the field. Others plan or design products and systems. Or, you might support scientists, mathematicians, or other engineers as they do their work. For example, you might oversee the construction of roads, develop systems to prevent diseases, or help engineers do research and read blueprints.",
         
    },
    healthcare:{
        mediaInfo:{
            videoType:"youtube",link:"",videoId:"i2pMEhEzbEs", imageOrVideo:'video',srcOrHref:'https://youtu.be/1eOnm-61cDE'
          },
        paragraph1:"Health science workers promote health and wellness. They diagnose and treat injuries and disease. As a physician, dentist, or nurse, you could work directly with patients. You could also work in a laboratory to get information used in research or provide administrative support by keeping medical records.",
        paragraph2:"Health science jobs are found at a variety of different sites. For example, you could work in a hospital, office, clinic, or nursing home. Or you could work on a cruise ship, at a sports arena, or within a patient's home.",
         
    },
    information_technology:{
        mediaInfo:{
            videoType:"youtube",link:"",videoId:"KuTOO28tRI4", imageOrVideo:'video',srcOrHref:'https://youtu.be/1eOnm-61cDE'
          },
        paragraph1:"Information technology workers are in a growing and always changing field. You might work with computer hardware, software, multimedia, or network systems. You might design new computer equipment or work on a new computer game.",
        paragraph2:"Some information technology workers support and manage computer hardware. For example, you might make sure the networks are working or manage a network that links people across the world. Or, you might support others and answer questions about specific software or hardware.",
         
    },
  

    public_safety:{
      mediaInfo:{
        videoType:'',link:"law-explore",videoId:'', imageOrVideo:'img',srcOrHref:'law-explore'
      },
        paragraph1:"Law, public safety, corrections, and security workers are found in a variety of settings. For example, you might guard the public and enforce the law as a police officer or security guard. Or, you might provide fire protection as a firefighter.",
        paragraph2:"Other workers provide legal services to people who commit crimes. For example, you might monitor activities within a prison, advise clients of their legal rights, or research legal procedures to support court cases.",
         
    },
   
    transportation_distribution_and_logistics:{
        mediaInfo:{
            videoType:"youtube",link:"",videoId:"5Gsam6jyRI0", imageOrVideo:'video',srcOrHref:'https://youtu.be/5Gsam6jyRI0'
          },
        paragraph1:"Transportation, distribution, and logistics workers move people and products by road, air, rail, and water. You might work as a driver, pilot, engineer, or captain.",
        paragraph2:"You might repair or maintain the vehicles, trains, planes, and ships that move people and products. Or, you might work behind the scenes to make sure the products and people get to the right place on time.",
         
    },
   
   
  }
  
  /*########################################################################################################*/
  /*########################################################################################################*/
  /*########################################################################################################*/
  var WAW_Quiz_Object={ 
    agriculture:{
      mediaInfo:{
        videoType:"youtube",link:"",videoId:"zau3EdnxLwM", imageOrVideo:'video',srcOrHref:'https://youtu.be/'
      },
      paragraph1:"You are surrounded by agriculture. From the food we eat, the clothes we wear, and the houses we live in, agriculture is all around us.",
      paragraph2:"Agriculture has a significant impact on Alabama’s economy, the health of its citizens, and the beauty of its landscapes. If you are interested in a career taking you outdoors, consider a career in agriculture.",  
  },
    cosmetology:{
      mediaInfo:{
          videoType:'',link:"cosmetology-explore",videoId:'', imageOrVideo:'img',srcOrHref:'cosmetology-explore'
        },
      paragraph1:"Working in cosmetology allows you to meet new people and give them confidence by helping them look and feel their best.",
      paragraph2:"You have the freedom to work in an existing salon, studio or spa, work individually for special events, or even open your own salon as a small business owner.",

       
  },
    construction:{
      mediaInfo:{
        videoType:"youtube",link:"",videoId:"ZGKMbVa7ALQ", imageOrVideo:'video',srcOrHref:'https://youtu.be/'
      },
      paragraph1:"The construction industry plays a significant role in our economy. A developing economy means specific goals must be met to respond to increased housing needs, provide new employment, and create infrastructure upgrades and improvements.",
      paragraph2:"Right now in West Alabama, high demand, competitive salary opportunities are readily available, and the demand continues to grow. If you are interested in a career in construction, visit www.gobuildalabama.com for in depth information on the available career choices.",

  },
    education:{
      mediaInfo:{
        videoType:"youtube",link:"",videoId:"0KsANXsS3fo", imageOrVideo:'video',srcOrHref:'https://youtu.be/'
      },
      paragraph1:"Education and training workers guide and train people. As a teacher, you could influence young lives. You could also support the work of a classroom teacher as a counselor, librarian, or principal. You could coach sports activities or lead community classes.",
      paragraph2:"You could also work with adults. For example, you could lead training to employees in a business. Or you could work as a university or college professor for undergraduate or graduate students.",

  },
    energy_and_utilities:{
      mediaInfo:{
          videoType:'youtube',link:"",videoId:'agDvcXj4ZfI', imageOrVideo:'video',srcOrHref:'https://youtu.be/'
        },
      paragraph1:"Looking for a cutting-edge career? You’ll want to explore careers in this cluster. From building offshore wind farms to designing solar roads, the energy industry offers some of the coolest careers out there. ",
      paragraph2:"And they’re not just for engineers and scientists! Discover the types of careers below that exist in wind, ethanol, residential energy-efficiency, and commercial energy-efficiency industries.",

  },
  
    engineering_and_environment:{
      mediaInfo:{
          videoType:'youtube',link:"",videoId:'1bwaLbfdLbo', imageOrVideo:'video',srcOrHref:'https://youtu.be/'
        },
      paragraph1:"Engineering workers do scientific research in laboratories or the field. Others plan or design products and systems. Or, you might support scientists, mathematicians, or other engineers as they do their work. For example, you might oversee the construction of roads, develop systems to prevent diseases, or help engineers do research and read blueprints.",
      paragraph2:"Almost everything you see was created prior to construction through the expertise of engineers and designers. If you are interested in being part of designing a building, a ship, an airplane, or even an electronic device, consider a career in engineering or design.",

  },
    healthcare:{
      mediaInfo:{
        videoType:'youtube',link:"",videoId:'glJ3beFiFG0', imageOrVideo:'video',srcOrHref:'https://youtu.be/'
      },
      paragraph1:"Health science workers promote health and wellness. They diagnose and treat injuries and disease. As a physician, dentist, or nurse, you could work directly with patients. You could also work in a laboratory to get information used in research or provide administrative support by keeping medical records.",
      paragraph2:"Health science jobs are found at a variety of different sites. For example, you could work in a hospital, office, clinic, or nursing home. Or you could work on a cruise ship, at a sports arena, or within a patient's home.",

  },
    hospitality_and_tourism:{
      mediaInfo:{
        videoType:'youtube',link:"",videoId:'4utX0BZ21so', imageOrVideo:'video',srcOrHref:'https://youtu.be/'
      },
      paragraph1:"Careers in hospitality and tourism are found in a variety of places. Careers in the food industry may involve working directly with culinary staff, but they often involve working with the public. Golf pros or park rangers may work daily with small groups or individuals, while tour guides or hotel managers may work with hundreds of people.",
      paragraph2:"Some careers begin through on-the-job-training while in high school, and others may combine on-the-job training with formal education at a technical college. Management positions may require a bachelor’s degree. All careers in hospitality and tourism are part of fast-paced environments and require stress tolerance, the ability to take direction, and self-motivation.",

  },
    manufacturing_auto_logistics:{
      mediaInfo:{
          videoType:'youtube',videoUrl:"",videoId:'DsS3jBZxMuk', imageOrVideo:'video',srcOrHref:'https://youtu.be/'
        },
      paragraph1:"Law, public safety, corrections, and security workers are found in a variety of settings. For example, you might guard the public and enforce the law as a police officer or security guard. Or, you might provide fire protection as a firefighter.",
      paragraph2:"Other workers provide legal services to people who commit crimes. For example, you might monitor activities within a prison, advise clients of their legal rights, or research legal procedures to support court cases.",

  },
    public_safety:{
      mediaInfo:{
        videoType:'youtube',videoUrl:"",videoId:'_nyVHXFUw4s', imageOrVideo:'video',srcOrHref:'https://youtu.be/'
      },
      paragraph1:"Law, public safety, corrections, and security workers are found in a variety of settings. For example, you might guard the public and enforce the law as a police officer or security guard. Or, you might provide fire protection as a firefighter.",
      paragraph2:"Other workers provide legal services to people who commit crimes. For example, you might monitor activities within a prison, advise clients of their legal rights, or research legal procedures to support court cases.",

  },
   transportation:{
    mediaInfo:{
        videoType:'youtube',link:"",videoId:'QcXMf_g_pkc', imageOrVideo:'video',srcOrHref:'https://youtu.be/'
      },
    paragraph1:"Careers in transportation are found in a variety of places. Everything we use is transported by roadway, railway, airway, or waterway. Within transportation, there is a variety of careers. The level of education required varies from high school graduate to a bachelor’s degree. After successfully completing high school, some students enter directly into the workforce.",
    paragraph2:"Some positions, such as a mechanic, may only require on-the-job or technical training. Other careers, such as maintenance director, recruiter, safety specialist, and marketing representative, require an associate degree. A bachelor’s degree may be required for positions such as safety or account managers.",

},
  
   
  }
  
  /*########################################################################################################*/
  /*#######################################################################################################
  /*########################################################################################################*/
  var CAW_Explore_Object={ 
    agriculture:{
        mediaInfo:{
            videoType:'youtube',videoUrl:"",videoId:'uSZVN7JaJmQ', imageOrVideo:'video',srcOrHref:'https://youtu.be/1eOnm-61cDE'
          },
        paragraph1:"Agriculture, food, and natural resources workers produce agricultural goods. This includes food, plants, animals, fabrics, wood, and crops. You might work on a farm, ranch, dairy, orchard, greenhouse, or plant nursery. You could also work in a clinic or laboratory as a scientist or engineer.",
        paragraph2:"Some agriculture, food, and natural resources workers market, sell, or finance agricultural goods. For example, you might market products made from plants and animals. Or, you might sell services that farmers and ranchers use to improve products. You could also work to conserve natural resources or protect the environment.",
  
         
    },
    architecture_construction_and_utilities:{
        mediaInfo:{
            videoType:'youtube',link:"",videoId:'ko_OMynE3Bo', imageOrVideo:'video',srcOrHref:'https://youtu.be/1eOnm-61cDE'
          },
        paragraph1:"Architecture, construction and utility workers work on buildings and other structures. This includes highways, bridges, houses, and buildings. You might create the designs or plans for new structures. Or, you might use the plans to build it or manage the workers on the project.",
        paragraph2:"Some architecture, construction and utility workers do skilled trades, like carpentry, painting, or plumbing. For example, you might do all of the electrical work for a renovated office space.",
  
         
    },
    communication_and_information_technology:{
        mediaInfo:{
            videoType:'youtube',link:"",videoId:'KuTOO28tRI4', imageOrVideo:'video',srcOrHref:'https://youtu.be/1eOnm-61cDE'
          },
        paragraph1:"Information technology workers are in a growing and always changing field. You might work with computer hardware, software, multimedia, or network systems. You might design new computer equipment or work on a new computer game.",
        paragraph2:"Some information technology workers support and manage computer hardware. For example, you might make sure the networks are working or manage a network that links people across the world. Or, you might support others and answer questions about specific software or hardware.",
  
         
    },
    education_and_training:{
        mediaInfo:{
            videoType:'youtube',link:"",videoId:'N6_cGS9yy7w', imageOrVideo:'video',srcOrHref:'https://youtu.be/N6_cGS9yy7w'
          },
        paragraph1:"Education and training workers guide and train people. As a teacher, you could influence young lives. You could also support the work of a classroom teacher as a counselor, librarian, or principal. You could coach sports activities or lead community classes.",
        paragraph2:"You could also work with adults. For example, you could lead training to employees in a business. Or you could work as a university or college professor for undergraduate or graduate students.",
  
         
    },

    healthcare:{
        mediaInfo:{
            videoType:'youtube',link:"",videoId:'i2pMEhEzbEs', imageOrVideo:'video',srcOrHref:'https://youtu.be/1eOnm-61cDE'
          },
        paragraph1:"Health science workers promote health and wellness. They diagnose and treat injuries and disease. As a physician, dentist, or nurse, you could work directly with patients. You could also work in a laboratory to get information used in research or provide administrative support by keeping medical records.",
        paragraph2:"Health science jobs are found at a variety of different sites. For example, you could work in a hospital, office, clinic, or nursing home. Or you could work on a cruise ship, at a sports arena, or within a patient's home.",
  
         
    },
    hospitality_and_tourism:{
        mediaInfo:{
            videoType:'youtube',link:"",videoId:'Q7tTV5Ki5KU', imageOrVideo:'video',srcOrHref:'https://youtu.be/1eOnm-61cDE'
          },
        paragraph1:"Hospitality and tourism workers help people enjoy vacations and entertainment activities. You might work at a restaurant, resort, sports arena, theme park, museum, or hotel.",
        paragraph2:"For example, you might manage operations of a college cafeteria, guide high school students on a trip to Spain, or rent equipment at a recreation center.",
  
    },
    manufacturing:{
        mediaInfo:{
            videoType:'youtube',link:"",videoId:'kJ0MTvwCw_4', imageOrVideo:'video',srcOrHref:'https://youtu.be/1eOnm-61cDE'
          },
        paragraph1:"Manufacturing workers work with products and equipment. You might design a new product, decide how the product will be made, or make the product. You might work on cars, computers, appliances, airplanes, or electronic devices.",
        paragraph2:"",
  
         
    },
    public_service:{
        mediaInfo:{
          videoType:'youtube',link:"",videoId:'Ru9dyuV7KcU', imageOrVideo:'video',srcOrHref:'https://youtu.be/Ru9dyuV7KcU'
        },
        paragraph1:"Law, public safety, corrections, and security workers are found in a variety of settings. For example, you might guard the public and enforce the law as a police officer or security guard. Or, you might provide fire protection as a firefighter.",
        paragraph2:"Other workers provide legal services to people who commit crimes. For example, you might monitor activities within a prison, advise clients of their legal rights, or research legal procedures to support court cases.",
  
         
    },
    transportation_distribution_and_logistics:{
        mediaInfo:{
            videoType:'youtube',link:"https://youtu.be/5Gsam6jyRI0",videoId:'5Gsam6jyRI0', imageOrVideo:'video',srcOrHref:'https://youtu.be/5Gsam6jyRI0'
          },
        paragraph1:"Transportation, distribution, and logistics workers move people and products by road, air, rail, and water. You might work as a driver, pilot, engineer, or captain.",
        paragraph2:"You might repair or maintain the vehicles, trains, planes, and ships that move people and products. Or, you might work behind the scenes to make sure the products and people get to the right place on time.",
  
         
    },
  
  
   
  }
  
  
   /*********************** */
   var NCTEJW_Quiz_Object={ 
  agriculture:{
      mediaInfo:{
          videoType:"youtube",link:"",videoId:"oSwNaHHhKjQ", imageOrVideo:'video',srcOrHref:'https://youtu.be/oSwNaHHhKjQ'
        },
      paragraph1:"Agriculture, food, and natural resources workers produce agricultural goods. This includes food, plants, animals, fabrics, wood, and crops. You might work on a farm, ranch, dairy, orchard, greenhouse, or plant nursery. You could also work in a clinic or laboratory as a scientist or engineer.",
      paragraph2:"Some agriculture, food, and natural resources workers market, sell, or finance agricultural goods. For example, you might market products made from plants and animals. Or, you might sell services that farmers and ranchers use to improve products. You could also work to conserve natural resources or protect the environment.",
       
  },
  biotechnology:{
      mediaInfo:{
          videoType:"youtube",link:"",videoId:"8Mwlhg8tpD0", imageOrVideo:'video',srcOrHref:'https://youtu.be/8Mwlhg8tpD0'
        },
      paragraph1:"Biotechnology is an industry that is focused on the manipulation of living organisms to create commercial products. However, this is a very broad view of this fast-growing scientific industry. By such definitions, centuries of agriculture and animal breeding would qualify as types of biotechnology. Modern understanding and usage of this science, also known as biotech, has been refined to create novel drugs and pest-resistant crops.",
      paragraph2:"​​The biotech industry is largely divided into the medical and agricultural markets. Although enterprising biotechnology is also applied to other areas, such as the industrial production of chemicals and bioremediation, the use in these areas is still specialized and limited.",
       
  },
  construction:{
      mediaInfo:{
          videoType:"youtube",link:"",videoId:"OHlOKxZ8it0", imageOrVideo:'video',srcOrHref:'https://youtu.be/OHlOKxZ8it0'
        },
      paragraph1:"Construction workers work on buildings and other structures. This includes highways, bridges, houses, and buildings. You might create the designs or plans for new structures. Or, you might use the plans to build it or manage the workers on the project.",
      paragraph2:"Some construction workers do skilled trades, like carpentry, painting, or plumbing. For example, you might do all of the electrical work for a renovated office space. Or, you might work as a landscaper and take care of flowers and trees on large properties.",
       
  },
  energy_and_technology:{
      mediaInfo:{
          videoType:"youtube",link:"",videoId:"oI1yXljQybE", imageOrVideo:'video',srcOrHref:'https://youtu.be/oI1yXljQybE'
        },
      paragraph1:"Looking for a cutting-edge career? You’ll want to explore careers in this cluster. From building offshore wind farms to designing solar roads, the energy industry offers some of the coolest careers out there. And they’re not just for engineers and scientists! Discover the types of careers below that exist in wind, ethanol, residential energy-efficiency, and commercial energy-efficiency industries.",
      paragraph2:"",
       
  },
  healthcare:{
      mediaInfo:{
          videoType:"youtube",link:"",videoId:"i2pMEhEzbEs", imageOrVideo:'video',srcOrHref:'https://youtu.be/i2pMEhEzbEs'
        },
      paragraph1:"Health science workers promote health and wellness. They diagnose and treat injuries and disease. As a physician, dentist, or nurse, you could work directly with patients. You could also work in a laboratory to get information used in research or provide administrative support by keeping medical records.",
      paragraph2:"Health science jobs are found at a variety of different sites. For example, you could work in a hospital, office, clinic, or nursing home. Or you could work on a cruise ship, at a sports arena, or within a patient's home.",
       
  },
  manufacturing:{
    mediaInfo:{
        videoType:"youtube",link:"",videoId:"vbb4SZOTK2Y", imageOrVideo:'video',srcOrHref:'https://youtu.be/vbb4SZOTK2Y'
      },
    paragraph1:"Manufacturing workers work with products and equipment. You might design a new product, decide how the product will be made, or make the product. You might work on cars, computers, appliances, airplanes, or electronic devices.",
    paragraph2:"Other manufacturing workers install or repair products. For example, you might install refrigerators or washers, repair plumbing in commercial buildings, or diagnose engine problems in cars and trucks.",
     
  },
  public_works_and_safety:{
      mediaInfo:{
          videoType:"youtube",link:"",videoId:"Ru9dyuV7KcU", imageOrVideo:'video',srcOrHref:'https://youtu.be/Ru9dyuV7KcU'
        },
      paragraph1:"Law, public safety, corrections, and security workers are found in a variety of settings. For example, you might guard the public and enforce the law as a police officer or security guard. Or, you might provide fire protection as a firefighter.",
      paragraph2:"Other workers provide legal services to people who commit crimes. For example, you might monitor activities within a prison, advise clients of their legal rights, or research legal procedures to support court cases.",
      
  },
  transportation_and_logistics:{
      mediaInfo:{
          videoType:"youtube",link:"",videoId:"5Gsam6jyRI0", imageOrVideo:'video',srcOrHref:'https://youtu.be/5Gsam6jyRI0'
        },
      paragraph1:"Transportation, distribution, and logistics workers move people and products by road, air, rail, and water. You might work as a driver, pilot, engineer, or captain.",
      paragraph2:"You might repair or maintain the vehicles, trains, planes, and ships that move people and products. Or, you might work behind the scenes to make sure the products and people get to the right place on time.",
       
  },
  hospitality_tourism_and_retail:{
      mediaInfo:{
          videoType:"youtube",link:"",videoId:"Q7tTV5Ki5KU", imageOrVideo:'video',srcOrHref:'https://youtu.be/Q7tTV5Ki5KU'
        },
      paragraph1:"Hospitality, tourism and retail workers help people enjoy vacations and entertainment activities. You might work at a restaurant, resort, sports arena, retail store, theme park, museum, or hotel.",
      paragraph2:"For example, you might manage operations of a college cafeteria, guide high school students on a trip to Spain, or rent equipment at a recreation center.",
       
  }
  
  }
  
  
  
  