import Vue from 'vue'
import App from './App.vue'
import VuePageTransition from './components/transition'
import VueAwesomeSwiper from 'vue-awesome-swiper'

import VueYoutube from 'vue-youtube'

import vueVimeoPlayer from 'vue-vimeo-player'

//import VueGtag from "vue-gtag";
//Vue.use(VueGtag, {
  //config: { id: "UA-1234567-1" }
//});
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
// import style (<= Swiper 5.x)
import 'swiper/css/swiper.css'
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

Vue.use(VueAwesomeSwiper, 
  /* { default options with global component } */)
  Vue.use(VueYoutube)
  Vue.use(vueVimeoPlayer)
//import router from './_router/mouter';
//port { TweenMax, TimelineLite, EasePack, Draggable } from "gsap";
import store from "./state/store.js";

import router from './router'
//Vue.config.productionTip = false
Vue.use(VuePageTransition )
  // Your web app's Firebase configuration
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional

new Vue({
  store:store,
  router,
  render: h => h(App),

}).$mount('#app')
