export default {
  isAuthenticated: false,
  isReady: false,
  uid: '',
  worldPoints:{},
  worldAccomplishments:{sampleWorld:{accomplishmenttext:"", wasAccomplished:""}},
  worldMissions:{sampleWorld:{accomplishmenttext:"", wasAccomplished:""}},
  worldsVisited:[],
  worldsCompleted:[],
}
