<template>
<span>

  
<b-icon v-if="argBool==true" icon="check2-circle" aria-hidden="true" variant="success"></b-icon>      
<b-icon v-if="argBool==false" icon="x-circle" aria-hidden="true" variant="danger"></b-icon>
</span>
</template>


<script>
export default {
    name:"toggleCheckOrX",
    props:{
     argBool:Boolean,
    worldName: String,
 
    },  

     data() {

      return {
        
			height: 500,
			options: {},
			playerReady: false,
                 region:process.env.VUE_APP_CURRENT_REGION.toLowerCase(),


      }
      
    }
}
</script>