//copy this to create a new all world template.  database eventually.

var schoolInfoMixin = {
    methods: {
      selectSchoolInfo: function (regionName) {
        var worldsArrays={
            SAWDC:SAWDC_School_Object,
            CAW:CAW_School_Object,
            WAW:WAW_School_Object,
            NAW:NAW_School_Object,
            EAW:EAW_School_Object,
            NCTEJW:NCTEJW_School_Object,

  
          }
          return worldsArrays[regionName]
      }
    }
  };
  export default schoolInfoMixin
  /*########################################################################################################*/
/*########################################################################################################*/
/*########################################################################################################*/
var SAWDC_School_Object={ 
  schools:[
  {id:0,name:'Alba'},
  {id:1,name:'Burns'},
  {id:2,name:'Calloway '},
  {id:3,name:'Causey'},
  {id:4,name:'Chastang'},
  {id:5,name:'Clark Shaw'},
  {id:6,name:'Denton'},
  {id:7,name:'Dunbar'},
  {id:8,name:'Grand Bay'},
  {id:9,name:'Hankins'},
  {id:10,name:'Lott'},
  {id:11,name:'Mobile County Training'},
  {id:12,name:'North Mobile'},
  {id:13,name:'Phillips'},
  {id:14,name:'Pillans'},
  {id:15,name:'Scarborough'},
  {id:16,name:'Semmes'},
  {id:17,name:'Pathway'},
  {id:18,name:'Washington'},
  {id:19,name:'Williamson'},
  {id:20,name:'Evans Special School'},
  {id:21,name:'Special Sevices School'},
  {id:22,name:'Envision Virtual School'},
  {id:23,name:'CLC'},
  {id:24,name:'West Mobile Academy'},
  {id:25,name:'Alabama Destinations Career Academy'},
  {id:26,name:'Foley Middle School'},
  {id:27,name:'Daphne Middle School'}, 
  {id:28,name:'Spanish Fort Middle School'}, 
  {id:29,name:'Central Baldwin Middle School'},
  {id:30,name:'Bay Minette Middle School'},
  {id:31,name:'Perdido School'},
  {id:32,name:'Fairhope Middle School'},
  {id:33,name:'Summerdale School'},
  {id:34,name:'Alberta Middle School'},
  {id:35,name:'Orange Beach Middle School'},
  {id:36,name:'Baldwin County Virtual School'},
  {id:37,name:'Chickasaw City Schools'},
  {id:38,name:'ACCEL Day & Evening Academy'},
  {id:39,name:'Wilson Hall Middle School'},
  {id:40,name:'Clarke County HIgh School'},
  {id:41,name:'Jackson Middle School'},
  {id:42,name:'Jackson High School'},
  {id:43,name:'Thomasville Middle School'},
  {id:44,name:'Thomasville High School'},
  {id:45,name:'Choctaw County High School'},
  {id:46,name:'Southern Choctaw High School'},
  {id:47,name:'Gulf Shores Middle School'},
  {id:48,name:'Orange Beach Middle'},
  {id:49,name:'J.U. Blacksher'},
  {id:50,name:'J.F. Shields'},
  {id:51,name:'Monroeville Middle'},
  {id:52,name:'Monroe County High'}, 
  {id:53,name:'Excel'},
  {id:54,name:'Camden School of Arts and Technology'},
  {id:55,name:'Wilcox County High School'},
  {id:56,name:'Fruitdale'},
  {id:57,name:'Leroy'},
  {id:58,name:'McIntosh'},
  {id:59,name:'Millry'},
  {id:60,name:'Chatom'},
  {id:61,name:'Covenant Christian School'},
  {id:62,name:'Hillcrest High School'},
  {id:63,name:'Lyeffion Jr. High'},
  {id:64,name:'Southside Preparatory Magnet'},
  {id:65,name:'Conecuh County Jr. High'},
  {id:66,name:'Repton Jr. High'},
  {id:67,name:'Thurgood Marshall Middle'},
  ]
   
  }
  
  
  /*########################################################################################################*/
  /*########################################################################################################*/
  /*########################################################################################################*/
  var NAW_School_Object={
    schools:[
      {id:0, name:"Academy for Academics and Arts Middle School"},
      {id:1, name:"Academy For Science & Foreign Language Middle School"},
      {id:2, name:" Addison High School"},
      {id:3, name:"Alabama Connections Academy"},
      {id:4, name:"Albertville Middle School"},
      {id:5, name:" Arab Middle School"},
      {id:6, name:"Ardmore High School"},
      {id:7, name:"Asbury High School"},
      {id:8, name:"Athens Middle School"},
      {id:9, name:"Austin Junior High School"},
    {id:10, name:"Belgreen High School"},
   {id:11, name:" Boaz Middle School"},
   {id:12, name:"  Bridgeport Middle School"},
 {id:13, name:" Brilliant School"},
    {id:14, name:"Brindlee Mountain High School"},
    {id:15, name:"Brooks High School"},
     {id:16, name:" Bryant School"},
     {id:17, name:" Buckhorn Middle School"},
        {id:18, name:" Central High School"},
     {id:19, name:" Central School"},
     {id:20, name:" Challenger Middle School"},
     {id:21, name:" Chapman Middle School"},
     {id:22, name:" Cherokee High School"},
     {id:23, name:" Clements High School"},
     {id:24, name:" Colbert County High School"},
    {id:25, name:" Colbert Heights High School"},
    {id:26, name:" Cold Springs Elementary School"},
    {id:27, name:" Collinsville High School"},
     {id:28, name:"Cotaco School"},
 {id:29, name:"Crossville Middle School"},
    {id:30, name:"Cullman Middle School"},
  {id:31, name:"Danville Middle School"},
 {id:31, name:" Decatur Middle School"},
    {id:32, name:" Deshler Middle School"},
 {id:33, name:" Discovery Middle School"},
 {id:34, name:"Double Springs Middle School"},
{id:35, name:"Douglas Middle School"},
{id:36, name:"Dutton Elementary School"},
{id:37, name:"East Franklin Junior High School"},
 {id:38, name:" East Lawrence Middle School"},
  {id:39, name:" East Limestone High School"},
{id:40, name:" Elkmont High School"},
 {id:41, name:"Eva School"},
  {id:42, name:"Fairview Middle School"},
  {id:43, name:" Falkville High School"},
{id:44, name:"Florence Middle School"},
 {id:45, name:" Fort Payne Middle School"},
{id:46, name:" Fyffe High School"},
{id:47, name:" Geraldine School"},
{id:48, name:" Good Hope Middle School"},
{id:49, name:" Guntersville Middle School"},
{id:50, name:" Hackleburg High School"},
{id:51, name:" Haleyville Middle School"},
{id:52, name:" Hamilton Middle School"},
{id:53, name:" Hampton Cove Middle School"},
{id:54, name:" Hanceville Middle School"},
{id:55, name:" Harmony School"},
{id:56, name:" Hartselle Junior High School"},
{id:57, name:" Hatton High School"},
{id:58, name:"  Henagar Junior High School"},
{id:59, name:"  Holly Pond Middle School"},
{id:60, name:"  Hollywood Elementary School"},
{id:61, name:" Huntsville Junior High School"},
{id:62, name:"  Ider School"},
{id:63, name:"  Kate Duncan Smith DAR Middle"},
{id:64, name:"  Laceys Spring Elementary School"},
{id:65, name:"  Lauderdale County High School"},
{id:66, name:" Lexington School"},
{id:67, name:"  Liberty Middle School"},
{id:68, name:"  Lynn High School"},
{id:69, name:"  Macedonia School"},
{id:70, name:"  Madison County Elementary School"},
{id:71, name:"   Madison County Virtual Academy"},
{id:72, name:"   Marion County High School"},
{id:73, name:"  Meek High School"},
{id:74, name:"  Meridianville Middle School"},
{id:75, name:"  Monrovia Middle School"},
{id:76, name:"  Morris Middle School"},
{id:77, name:"  Moulton Middle School"},
{id:78, name:"  Mountain Gap Middle School"},
 {id:79, name:"  Muscle Shoals Middle School"},
{id:80, name:"  New Hope High School"},
{id:81, name:"  North Sand Mountain School"},
{id:82, name:"  Parkside Elementary School"},
{id:83, name:" Phil Campbell High School"},
{id:84, name:"  Phillips High School"},
{id:85, name:" Pisgah High School"},
 {id:86, name:" Plainview School"},
{id:87, name:" Priceville Junior High School"},
{id:88, name:" R A Hubbard High School"},
{id:89, name:" Red Bay High School"},
{id:90, name:" Rogers High School"},
{id:91, name:" Ronald McNair Middle School"},
{id:92, name:" Rosalie Elementary School"},
{id:93, name:" Ruhuma Junior High School"},
{id:94, name:" Russellville Middle School"},
 {id:95, name:" Scottsboro Middle School"},
{id:96, name:" Section High School"},
{id:97, name:" Sheffield Junior High School"},
{id:98, name:" Skyline High School"},
{id:99, name:" Sparkman Middle School"},
{id:100, name:"Stevenson Middle School"},
{id:101, name:"Sylvania School"},
 {id:102, name:"Tanner High School"},
{id:103, name:"Tharptown High School"},
{id:104, name:"Union Hill School"},
{id:105, name:"Valley Head High School"},
 {id:106, name:"Vina High School"},
 {id:107, name:"Vinemont Middle School"},
{id:108, name:"Waterloo High School"},
{id:109, name:"West Limestone High School"},
 {id:110, name:"West Morgan Middle School"},
 {id:111, name:"West Point Middle School"},
{id:112, name:"Whitesburg Middle School"},
 {id:113, name:"Williams Middle School"},
 {id:114, name:"Wilson High School"},
 {id:115, name:"Winfield Middle School"},
{id:116, name:"Woodville High School"},
      

    ]
  }
  
  /*########################################################################################################*/
  /*########################################################################################################*/
  /*########################################################################################################*/
  var EAW_School_Object={ 
    schools:[ {id:0,name:"AIDB - E. H. Gentry"},
    {id:1,name:"Alabama Destinations Career Academy"},
    {id:2,name:"Alabama School for the Blind"},
    {id:3,name:"Alabama School for the Deaf"},
    {id:4,name:"Alexanria High School"},
    {id:5,name:"Alexandria Middle School"},
    {id:6,name:"Anniston High School"},
    {id:7,name:"Anniston Middle School"},
    {id:8,name:"B.B. Comer High School"},
    {id:9,name:"Cedar Bluff School"},
    {id:10,name:"Centre Middle School"},
    {id:11,name:"Charles Drew Middle School"},
    {id:12,name:"CHEAHA Home School"},
    {id:13,name:"Cherokee County High School"},
    {id:14,name:"Childersburg High School"},
    {id:15,name:"Childersburg Middle School"},
    {id:16,name:"Clay Central High School"},
    {id:17,name:"Clay Central Junior High School"},
    {id:18,name:"Cleburne County High School"},
    {id:19,name:"Coosa Christian"},
    {id:20,name:"Donoho School"},
    {id:21,name:"Emma Sansom Middle School"},
    {id:22,name:"Episcopal Day School - Gadsden"},
    {id:23,name:"Etowah High School (Attalla)"},
    {id:24,name:"Etowah Middle School (Attalla)"},
    {id:25,name:"Faith Christian School"},
    {id:26,name:"Fayetteville High School"},
    {id:27,name:"Gadsden City High School"},
    {id:28,name:"Gadsden Job Corps"},
    {id:29,name:"Gadsden Middle School"},
    {id:30,name:"Gaston High School"},
    {id:31,name:"Gaston Middle School"},
    {id:32,name:"Gaylesville School"},
    {id:33,name:"Glencoe High School"},
    {id:34,name:"Glencoe Middle School"},
    {id:35,name:"Handley High School"},
    {id:36,name:"Handley Middle School"},
    {id:37,name:"Helen Keller School"},
    {id:38,name:"Hokes Bluff High School"},
    {id:39,name:"Hokes Bluff Middle School"},
    {id:40,name:"Jacksonville Christian Academy"},
    {id:41,name:"Jacksonville High School"},
    {id:42,name:"Lincoln High School"},
    {id:43,name:"Litchfield Middle School"},
    {id:44,name:"Munford High School"},
    {id:45,name:"Munford Middle School"},
    {id:46,name:"Nichols Lawson Middle School"},
    {id:47,name:"Ohatchee High School"},
    {id:48,name:"Oxford High School"},
    {id:49,name:"Oxford Middle School"},
    {id:50,name:"Pathways Academy"},
    {id:51,name:"Piedmont High School"},
    {id:52,name:"Piedmont Middle School"},
    {id:53,name:"Pleasant Valley High School"},
    {id:54,name:"Project Search - Etowah"},
    {id:55,name:"Rainbow Middle School"},
    {id:56,name:"Ranburne High School"},
    {id:57,name:"Randolph County High School"},
    {id:58,name:"Rock Mills Junior High School"},
    {id:59,name:"Sacred Heart School"},
    {id:60,name:"Saks High School"},
    {id:61,name:"Saks Middle School"},
    {id:62,name:"Sand Rock School"},
    {id:63,name:"Sardis High School"},
    {id:64,name:"Sardis Middle School"},
    {id:65,name:"Southside High School"},
    {id:66,name:"Spring Garden High School"},
    {id:67,name:"Sylacauga High School"},
    {id:68,name:"Talladega Central High School"},
    {id:69,name:"Talladega City High School"},
    {id:70,name:"Trinity Christian Academy"},
    {id:71,name:"Wadley High School"},
    {id:72,name:"Weaver High School"},
    {id:73,name:"Wellborn High School"},
    {id:74,name:"West End High School"},
    {id:75,name:"Westbrook Christian"},
    {id:76,name:"White Plains High School"},
    {id:77,name:"White Plains Middle School"},
    {id:78,name:"Winterboro High School"},
    {id:79,name:"Woodland High School"},
    {id:80,name:"Zora Ellis Jr. High"},

    ]
   
  }
  
  /*########################################################################################################*/
  /*########################################################################################################*/
  /*########################################################################################################*/
  var WAW_School_Object={ 
    schools:[{id:0,name:"Duncanville Middle"},
    {id:1,name:"Echols Middle"},
    {id:2,name:"Hillcrest Middle"},
    {id:3,name:"Northside Middle"},
    {id:4,name:"Sipsey Valley Middle"},
    {id:5,name:"Robert Brown Middle School"},
    {id:6,name:"Greensboro Middle"},
    {id:7,name:"Hale County Middle"},
    {id:8,name:"Centreville Middle"},
    {id:9,name:"West Blocton Middle"},
    {id:10,name:"Kinterbish Jr. High"},
    {id:11,name:"Livingston Jr. High"},
    {id:12,name:"York West End Jr. High"},
    {id:13,name:"Aliceville Middle"},
    {id:14,name:"Gordo High"},
    {id:15,name:"Pickens County High"},
    {id:16,name:"Hubbertville School"},
    {id:17,name:"Berry High"},
    {id:18,name:"Fayette Middle"},
    {id:19,name:"Sulligent School"},
    {id:20,name:"LCHS & Vernon Intermediate"},
    {id:21,name:"South Lamar School"},
    {id:22,name:"American Christian Academy"},
    {id:23,name:"Bear Creek Academy"},
    {id:24,name:"Holy Spirit Catholic High School"},
    {id:25,name:"North River Christian Academy"},
    {id:26,name:"Pickens Academy"},
    {id:27,name:"Southern Academy"},
    {id:28,name:"The Capitol School"},
    {id:29,name:"Tuscaloosa Academy"},
    {id:30,name:"Tuscaloosa Christian School"},
    {id:31,name:"University Charter"},
    {id:32,name:"Marion Couty High School"},
    {id:33,name:"Brilliant High School"},
    {id:34,name:"Hackleburg High School"},]
  }
  
  /*########################################################################################################*/
  /*########################################################################################################*/
  /*########################################################################################################*/
  var CAW_School_Object={ 
    schools:[
      {id:0,name:"Autauga County Schools"},
      {id:1,name:"Bullock County Schools"},
      {id:2,name:"Chambers County Schools"},
      {id:3,name:"Lanett City Schools"},
      {id:4,name:"Coosa County Schools"},
      {id:5,name:"Dallas County Schools"},
      {id:6,name:"Selma City Schools"},
      {id:7,name:"Elmore County Schools"},
      {id:8,name:"Tallassee City Schools"},
      {id:9,name:"Lee County Schools"},
      {id:10,name:"Auburn City Schools"},
      {id:11,name:"Opelika City Schools"},
      {id:12,name:"Lowndes County Schools"},
      {id:13,name:"Macon County Schools"},
      {id:14,name:"Montgomery County Schools"},
      {id:15,name:"Pike Road City Schools"},
      {id:16,name:"Perry County Schools"},
      {id:17,name:"Russell County Schools"},
      {id:18,name:"Phenix City Schools"},
      {id:19,name:"Tallapoosa County Schools"},
      {id:20,name:"Alexander City Schools"},



    ],
    teachers:[],
    
  
  
   
  }
  
  
  
   /*********************** */
   var NCTEJW_School_Object={ 
    schools:[
      {id:0,name:"Autauga County Schools"},
      {id:1,name:"Bullock County Schools"},
      {id:2,name:"Chambers County Schools"},
      {id:3,name:"Lanett City Schools"},
      {id:4,name:"Coosa County Schools"},
      {id:5,name:"Dallas County Schools"},
      {id:6,name:"Selma City Schools"},
      {id:7,name:"Elmore County Schools"},
      {id:8,name:"Tallassee City Schools"},
      {id:9,name:"Lee County Schools"},
      {id:10,name:"Auburn City Schools"},
      {id:11,name:"Opelika City Schools"},
      {id:12,name:"Lowndes County Schools"},
      {id:13,name:"Macon County Schools"},
      {id:14,name:"Montgomery County Schools"},
      {id:15,name:"Pike Road City Schools"},
      {id:16,name:"Perry County Schools"},
      {id:17,name:"Russell County Schools"},
      {id:18,name:"Phenix City Schools"},
      {id:19,name:"Tallapoosa County Schools"},
      {id:20,name:"Alexander City Schools"},



    ],
    teachers:[],
    


  }
  
  
  