<template>
  <div>
    <transition
      :name="transition"
      :mode="mode"
    >
      <slot></slot>
    </transition>
    <div class="overlay-top"></div>
    <div class="overlay-right"></div>
    <div class="overlay-bottom"></div>
    <div class="overlay-left"></div>
  </div>
</template>

<script>
export default {
  name: 'vue-page-transition',
  props: ['name'],
  data () {
    return {
      transition: 'fade',
      mode: 'out-in',
    }
  },
  created () {
    this.$router.beforeEach((to, from, next) => {
      this.transition = to.meta.transition
        ? to.meta.transition
        : this.$props.name

      next()
    })
  },
}
</script>

<style>
  :root {
    --overlay-bg: rgba(0,0,0,0);
    --transition-duration: .5s;
  }
  .overlay-right{
    background-image: url('../styles/left-door.png') !important;
    background-size: 100vw 190vh !important;
    background-position: right !important;

    background-repeat: no-repeat !important;
    z-index: 99999999;
    background-attachment: fixed;
  }
  .overlay-left{
    background-image: url('../styles/right-door.png') !important;
    background-size: 100vw 190vh !important;
    background-position: left !important;

    background-repeat: no-repeat !important;
    z-index: 99999999;
    background-attachment: fixed;
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
    
    #overlay-left{
      /* I'm in vuepagetransition*/
      width: 100vw !important;
    }
    #overlay-right{
      width: 100vw !important;
    }
}
@media (max-width: 600px) {
.overlay-left, .overlay-right{
    background-image: none !important;
  }
  }
</style>



<style lang="scss" scoped>
// fade
@import '../styles/transitions/fade/fade';
@import '../styles/transitions/fade/fade-in-down';
@import '../styles/transitions/fade/fade-in-right';
@import '../styles/transitions/fade/fade-in-up';
@import '../styles/transitions/fade/fade-in-left';

// zoom
@import '../styles/transitions/zoom/zoom';

// flip
@import '../styles/transitions/flip/flip-x';
@import '../styles/transitions/flip/flip-y';

// overlay
@import '../styles/transitions/overlay/overlay-right';
@import '../styles/transitions/overlay/overlay-down';
@import '../styles/transitions/overlay/overlay-up';
@import '../styles/transitions/overlay/overlay-left';
@import '../styles/transitions/overlay/overlay-up-full';
@import '../styles/transitions/overlay/overlay-right-full';
@import '../styles/transitions/overlay/overlay-down-full';
@import '../styles/transitions/overlay/overlay-left-full';
@import '../styles/transitions/overlay/overlay-up-down';
@import '../styles/transitions/overlay/overlay-left-right';
</style>

