<template>
  <div id="Mission Control">
   aerospace:



  <p> <toggleCheckOrX :argBool='visitedAllPages("aerospace")'/> Achievement 1: Visit the Explore, Careers, Employers, and Education sections in Aerospace & Aviation World. (Awards badge) <strong>{{bigState.aerospace.pagestate.visitedSidebarElementNames.length >= 4}} :{{bigState.aerospace.pagestate.visitedSidebarElementNames}}</strong></p><!--<achievementToggle/>-->           
   <p><toggleCheckOrX :argBool='learnedAllCareers("aerospace")'/> Achievement 2: Learn about all of the careers in Aerospace & Aviation World. (Awards badge)</p>
 <p><toggleCheckOrX :argBool='visitedPage("aerospace")'/> Mission 1: Visit the Aerospace & Aviation World. <strong>{{bigState.aerospace.pagestate.pagevisited}}</strong></p>
 <p><toggleCheckOrX :argBool='tookFitQuiz("aerospace")'/>  Mission 2: Take the assessment in the Explore section of Aerospace & Aviation World.<strong>{{bigState.aerospace.pagestate.suitabilityQuizTaken}}</strong></p>
 <p><toggleCheckOrX :argBool='learnedAllCareers("aerospace")'/> Mission 3: Learn about one career in Aerospace & Aviation World.</p>
 <p><toggleCheckOrX :argBool='visitedEmployers("aerospace")'/> Mission 4: Visit the Employers section in Aerospace & Aviation World.<strong>{{bigState.aerospace.pagestate.visitedSidebarElementNames.indexOf('Employers') > -1}}</strong></p>
 <p><toggleCheckOrX :argBool='visitedEducation("aerospace")'/>Mission 5: Open the Education section in Aerospace & Aviation World.<strong>{{bigState.aerospace.pagestate.visitedSidebarElementNames.indexOf('Education') > -1}}</strong></p>
<p> watched video <strong>{{bigState.aerospace.pagestate.employmentVideoWatched}}</strong></p>
<p> watched video list:  <strong>{{bigState.aerospace.pagestate.videoswatched}}</strong> </p>

 Points Earned: <strong>{{bigState.aerospace.pagestate.pointsAchieved}}</strong>
 Points Available:<strong>{{bigState.aerospace.pagestate.AvailablePoints}}</strong>
<br/>
 <div style="display:none">

  construction:
  <p>Achievement 1: Visit the Explore, Careers, Employers, and Education sections in Construction World. (Awards badge)</p><!--<achievementToggle/>-->            
  <p>Achievement 2: Learn about all of the careers in Construction World. (Awards badge)</p>
 <p>Mission 1: Visit the Construction World.</p>{{bigState.construction.pagestate.pagevisited}}
 <p>Mission 2: Take the assessment in the Explore section of Construction World.</p>{{bigState.construction.suitabilityQuizTaken}}
 <p>Mission 3: Learn about one career in Construction World.</p>
 <p>Mission 4: Visit the Employers section in Construction World.</p>
 <p>Mission 5: Open the Education section in Construction World.</p>
<p> watched video {{bigState.construction.pagestate.employerVideoWatched}}</p>
<p> watched video list:  {{bigState.construction.pagestate.videoswatched}} </p>

 Points Earned: {{bigState.construction.pagestate.pointsAchieved}}
 Points Available:{{bigState.construction.pagestate.AvailablePoints}}
 <br/>
 energy:
  <p>Achievement 1: Visit the Explore, Careers, Employers, and Education sections in Energy World. (Awards badge)</p><!--<achievementToggle/>-->            
  <p>Achievement 2: Learn about all of the careers in Energy World. (Awards badge)</p>
 <p>Mission 1: Visit the Energy World.</p>{{bigState.energy.pagestate.pagevisited}}
 <p>Mission 2: Take the assessment in the Explore section of Energy World.</p>{{bigState.energy.suitabilityQuizTaken}}
 <p>Mission 3: Learn about one career in Energy World.</p>
 <p>Mission 4: Visit the Employers section in Energy World.</p>
 <p>Mission 5: Open the Education section in Energy World.</p>
<p> watched video {{bigState.energy.pagestate.employerVideoWatched}}</p>
<p> watched video list:  {{bigState.energy.pagestate.videoswatched}} </p>

 Points Earned: {{bigState.energy.pagestate.pointsAchieved}}
 Points Available:{{bigState.energy.pagestate.AvailablePoints}}
 <br/>
 engineering
   <p>Achievement 1: Visit the Explore, Careers, Employers, and Education sections in Engineering World. (Awards badge)</p><!--<achievementToggle/>-->           
    <p>Achievement 2: Learn about all of the careers in Engineering World. (Awards badge)</p>
 <p>Mission 1: Visit the Engineering World.</p>{{bigState.engineering.pagestate.pagevisited}}
 <p>Mission 2: Take the assessment in the Explore section of Engineering World.</p>{{bigState.engineering.suitabilityQuizTaken}}
 <p>Mission 3: Learn about one career in Engineering World.</p>
 <p>Mission 4: Visit the Employers section in Engineering World.</p>
 <p>Mission 5: Open the Education section in Engineering World.</p>
<p> watched video {{bigState.engineering.pagestate.employerVideoWatched}}</p>
<p> watched video list:  {{bigState.engineering.pagestate.videoswatched}} </p>

 Points Earned: {{bigState.engineering.pagestate.pointsAchieved}}
 Points Available:{{bigState.engineering.pagestate.AvailablePoints}}
 <br/>
 healthcare
    <p>Achievement 1: Visit the Explore, Careers, Employers, and Education sections in Healthcare World. (Awards badge)</p><!--<achievementToggle/>-->            
    <p>Achievement 2: Learn about all of the careers in Healthcare World. (Awards badge)</p>
 <p>Mission 1: Visit the Healthcare World.</p>{{bigState.healthcare.pagestate.pagevisited}}
 <p>Mission 2: Take the assessment in the Explore section of Healthcare World.</p>{{bigState.healthcare.suitabilityQuizTaken}}
 <p>Mission 3: Learn about one career in Healthcare World.</p>
 <p>Mission 4: Visit the Employers section in Healthcare World.</p>
 <p>Mission 5: Open the Education section in Healthcare World.</p>
<p> watched video {{bigState.healthcare.pagestate.employerVideoWatched}}</p>
<p> watched video list:  {{bigState.healthcare.pagestate.videoswatched}} </p>

 Points Earned: {{bigState.healthcare.pagestate.pointsAchieved}}
 Points Available:{{bigState.healthcare.pagestate.AvailablePoints}}
<br/>
hospitality
    <p>Achievement 1: Visit the Explore, Careers, Employers, and Education sections in Hospitality World. (Awards badge)</p><!--<achievementToggle/>-->            
    <p>Achievement 2: Learn about all of the careers in Hospitality World. (Awards badge)</p>
 <p>Mission 1: Visit the Hospitality World.</p>{{bigState.hospitality.pagestate.pagevisited}}
 <p>Mission 2: Take the assessment in the Explore section of Hospitality World.</p>{{bigState.hospitality.suitabilityQuizTaken}}
 <p>Mission 3: Learn about one career in Hospitality World.</p>
 <p>Mission 4: Visit the Employers section in Hospitality World.</p>
 <p>Mission 5: Open the Education section in Hospitality World.</p>
<p> watched video {{bigState.hospitality.pagestate.employerVideoWatched}}</p>
<p> watched video list:  {{bigState.hospitality.pagestate.videoswatched}} </p>

 Points Earned: {{bigState.hospitality.pagestate.pointsAchieved}}
 Points Available:{{bigState.hospitality.pagestate.AvailablePoints}}
<br/>
Information Technology
    <p>Achievement 1: Visit the Explore, Careers, Employers, and Education sections in Information Technology World. (Awards badge)</p><!--<achievementToggle/>-->          
      <p>Achievement 2: Learn about all of the careers in Information Technology World. (Awards badge)</p>
 <p>Mission 1: Visit the Information Technology World.</p>{{bigState.it.pagestate.pagevisited}}
 <p>Mission 2: Take the assessment in the Explore section of Information Technology World.</p>{{bigState.it.suitabilityQuizTaken}}
 <p>Mission 3: Learn about one career in Information Technology World.</p>
 <p>Mission 4: Visit the Employers section in Information Technology World.</p>
 <p>Mission 5: Open the Education section in Information Technology World.</p>
<p> watched video {{bigState.it.pagestate.employerVideoWatched}}</p>
<p> watched video list:  {{bigState.it.pagestate.videoswatched}} </p>

 Points Earned: {{bigState.it.pagestate.pointsAchieved}}
 Points Available:{{bigState.it.pagestate.AvailablePoints}}
<br/>
Manufacturing
    <p>Achievement 1: Visit the Explore, Careers, Employers, and Education sections in Manufacturing World. (Awards badge)</p><!--<achievementToggle/>-->            
    <p>Achievement 2: Learn about all of the careers in Manufacturing World. (Awards badge)</p>
 <p>Mission 1: Visit the Manufacturing World.</p>{{bigState.manufacturing.pagestate.pagevisited}}
 <p>Mission 2: Take the assessment in the Explore section of Manufacturing World.</p>{{bigState.manufacturing.suitabilityQuizTaken}}
 <p>Mission 3: Learn about one career in Manufacturing World.</p>
 <p>Mission 4: Visit the Employers section in Manufacturing World.</p>
 <p>Mission 5: Open the Education section in Manufacturing World.</p>
<p> watched video {{bigState.manufacturing.pagestate.employerVideoWatched}}</p>
<p> watched video list:  {{bigState.manufacturing.pagestate.videoswatched}} </p>

 Points Earned: {{bigState.manufacturing.pagestate.pointsAchieved}}
 Points Available:{{bigState.manufacturing.pagestate.AvailablePoints}}
<br/>
Public Service 
    <p>Achievement 1: Visit the Explore, Careers, Employers, and Education sections in Public Service World. (Awards badge)</p><!--<achievementToggle/>-->            
    <p>Achievement 2: Learn about all of the careers in Public Service World. (Awards badge)</p>
 <p>Mission 1: Visit the Public Service World.</p>{{bigState.publicservice.pagestate.pagevisited}}
 <p>Mission 2: Take the assessment in the Explore section of Public Service World.</p>{{bigState.publicservice.suitabilityQuizTaken}}
 <p>Mission 3: Learn about one career in Public Service World.</p>
 <p>Mission 4: Visit the Employers section in Public Service World.</p>
 <p>Mission 5: Open the Education section in Public Service World.</p>
<p> watched video {{bigState.publicservice.pagestate.employerVideoWatched}}</p>
<p> watched video list:  {{bigState.publicservice.pagestate.videoswatched}} </p>

 Points Earned: {{bigState.publicservice.pagestate.pointsAchieved}}
 Points Available:{{bigState.publicservice.pagestate.AvailablePoints}}
<br/>
Safety
    <p>Achievement 1: Visit the Explore, Careers, Employers, and Education sections in Safety World. (Awards badge)</p><!--<achievementToggle/>-->           
     <li>Achievement 2: Learn about all of the careers in Safety World. (Awards badge)</li>
 <p>Mission 1: Visit the Safety World.</p>{{bigState.safety.pagestate.pagevisited}}
 <p>Mission 2: Take the assessment in the Explore section of Safety World.</p>{{bigState.safety.suitabilityQuizTaken}}
 <p>Mission 3: Learn about one career in Safety World.</p>
 <p>Mission 4: Visit the Employers section in Safety World.</p>
 <p>Mission 5: Open the Education section in Safety World.</p>
<p> watched video {{bigState.safety.pagestate.employerVideoWatched}}</p>
<p> watched video list:  {{bigState.safety.pagestate.videoswatched}} </p>

 Points Earned: {{bigState.safety.pagestate.pointsAchieved}}
 Points Available:{{bigState.safety.pagestate.AvailablePoints}}
<br/>
Shipbuilding
    <p>Achievement 1: Visit the Explore, Careers, Employers, and Education sections in Shipbuilding World. (Awards badge)</p><!--<achievementToggle/>-->            
    <li>Achievement 2: Learn about all of the careers in Shipbuilding World. (Awards badge)</li>
 <p>Mission 1: Visit the Shipbuilding World.</p>{{bigState.shipbuilding.pagestate.pagevisited}}
 <p>Mission 2: Take the assessment in the Explore section of Shipbuilding World.</p>{{bigState.shipbuilding.suitabilityQuizTaken}}
 <p>Mission 3: Learn about one career in Shipbuilding World.</p>
 <p>Mission 4: Visit the Employers section in Shipbuilding World.</p>
 <p>Mission 5: Open the Education section in Shipbuilding World.</p>
<p> watched video {{bigState.shipbuilding.pagestate.employerVideoWatched}}</p>
<p> watched video list:  {{bigState.shipbuilding.pagestate.videoswatched}} </p>

 Points Earned: {{bigState.shipbuilding.pagestate.pointsAchieved}}
 Points Available:{{bigState.shipbuilding.pagestate.AvailablePoints}}
<br/>
Supply Chain

<br/>    
<p>Achievement 1: Visit the Explore, Careers, Employers, and Education sections in Supply Chain World. (Awards badge): {{bigState.supplychain.pagestate.visitedSidebarElementNames}}</p><!--<achievementToggle/>-->            
<li>Achievement 2: Learn about all of the careers in Supply Chain World. (Awards badge)</li>
 <p>Mission 1: Visit the Supply Chain World.</p>{{bigState.supplychain.pagestate.pagevisited}}
 <p>Mission 2: Take the assessment in the Explore section of Supply Chain World.</p>{{bigState.supplychain.suitabilityQuizTaken}}
 <p>Mission 3: Learn about one career in Supply Chain World.</p>
 <p>Mission 4: Visit the Employers section in Supply Chain World.</p>
 <p>Mission 5: Open the Education section in Supply Chain World.</p>
<p> watched video {{bigState.supplychain.pagestate.employerVideoWatched}}</p>
<p> watched video list:  {{bigState.supplychain.pagestate.videoswatched}} </p>

 Points Earned: {{bigState.supplychain.pagestate.pointsAchieved}}
 Points Available:{{bigState.supplychain.pagestate.AvailablePoints}}
 </div>
  </div>
</template>
 
<script>

//import { mapActions } from 'vuex'
import toggleCheckOrX from '../components/handheld_components/toggleCheckOrX';

export default {
  name: 'Awards',
  data: function() {
        const accomplishmentState= this.$store.state.interaction_tracking_store;

      return{
          
    // declare message: with an empty value
    bigState: accomplishmentState
      }
  },
  created(){
  //console.log(this.$store.state.interaction_tracking_store.aerospace.pagestate.pagevisited);
  //  this.fetchStages()
  //  this.fetchTeams()

  },components:{
     toggleCheckOrX
  },
  methods: {
      // eslint-disable-next-line no-unused-vars
      visitedPage(worldname) {
        //console.log(worldname)
      return this.$store.state.interaction_tracking_store['aerospace'].pagestate.pagevisited
    },
    visitedEmployers(worldname){
      return this.$store.state.interaction_tracking_store[worldname].pagestate.visitedSidebarElementNames.indexOf('Employers') > -1

    },
    visitedEducation(worldname){
      return this.$store.state.interaction_tracking_store[worldname].pagestate.visitedSidebarElementNames.indexOf('Education') > -1

    },

    tookFitQuiz(worldname) {
      return this.$store.state.interaction_tracking_store[worldname].pagestate.suitabilityQuizTaken
     
    },
    visitedAllPages(worldname){
      return this.$store.state.interaction_tracking_store[worldname].pagestate.visitedSidebarElementNames.length>=4

    },
    learnedAllCareers(worldname)
    {
      return this.$store.state.interaction_tracking_store[worldname].pagestate.employmentVideoWatched

    }

    
  } // end of methods
}

</script>

<style>

</style>