<template>
<div>
<button v-if="ipadUp && !jobToggle"  :class="ipadUp ? 'back-button show-button' : 'back-button hid-button'"  @click="ipadDown"><b-icon icon="chevron-down" aria-hidden="true" style="width: 42px; height: 42px; color: #de1a21 !important;"></b-icon></button>
<div id="tabletIn">
<h1>Careers</h1>
  <b-row v-if="jobToggle==false">
    <b-col  cols="6" class="careers-right">
      <h2>Careers in {{selectProduct(region,worldName)}}</h2>
      <p>Click the buttons on the right to learn more about the various careers in {{selectProduct(region,worldName)}}</p>
        
         <div v-if="jobPageInfo.mediaInfo.imageOrVideo=='video'">
               
        <videoToggleWrapper :worldName='worldName' :key="1" :videoInfo='jobPageInfo.mediaInfo'/> 
              </div>
              <div v-else>
                                 <img :src="require(`@/assets/${region}-images/${jobPageInfo.mediaInfo.srcOrHref}.jpg`)" alt="Career Image">

            
                </div>

        
    </b-col>
    <b-col cols="6" class=careers-left>
         <div class="accordion" role="tablist">
            <div v-for="(job, index) in jobPageInfo.jobs" :key="index" no-body class="mb-1">
                <b-button class="job-buttons" @click="displayJobPage(false,$event)" :id='index'>{{job.jobtitle}}</b-button>     
            </div>
        </div>
    </b-col>
  </b-row>
   <b-row v-if="jobToggle==true">
      <JobPage :worldName='worldName' :jobInfo='jobPageInfo.jobs[this.jobIndex]'/>
  </b-row>
</div>
</div>
</template>


<script>
import JobPage from './subcomponents/jobpage.vue';
import worldNameMixin from '../../shared_methods/worldname_mixin'
import careersInfoMixin from '../../shared_methods/careers_Info_Mixin';
import videoToggleWrapper from './videoToggleWrapper';

export default {
    name:"Careers",
      mixins: [worldNameMixin,careersInfoMixin],
         props:{
       worldName:String
     },components:{
        JobPage,
        videoToggleWrapper
     },   
      methods: {
    displayJobPage(fromBackButtonBool,event) {
    this.jobToggle= !this.jobToggle;
    if(fromBackButtonBool==false)
    {
    //console.log(event);
    this.jobIndex=event.target.id;
    }//this.$parent.handheldView='Assessment';//='Educatio,n'
    
    },
          ipadDown(){
     this.$parent.childComponentTriggerActive(this.$options.name)
     this.ipadUp=false;
     this.$parent.sidebar
    },

    },
        data() {
          var jobInfos= this.selectJobPageInfo(process.env.VUE_APP_CURRENT_REGION,this.worldName)

          //console.log(jobInfos);
      return {
                   region:process.env.VUE_APP_CURRENT_REGION.toLowerCase(),

        ipadUp:true,
        jobIndex:0,
        jobToggle:false,
       
          jobPageInfo:jobInfos

        
      }
    }
}

/*                    {


              jobtitle:"A&P Mechanic",
              description:"Diagnose, adjust, repair, or overhaul aircraft engines and assemblies, such as hydraulic and pneumatic systems.",
              salary:"37,000 – 76,500",
              education: "On-The Job Training/Technical Training",
              jobMedia:{ imageOrVideo:"video",videoType:"youtube",srcOrHref:"https://youtu.be/C4B8S540PbE",id:"C4B8S540PbE"},
                          
              communicationbuttons:[
                     {
                      title:"Quality Control",
                      innerText:""                     
                      },
                     {
                      title:" ",
                      innerText:""
                     },
                     {
                      title:"",
                      innerText:""
                     },
                     {
                      title:" ",
                      innerText:"."
                     },
                     {
                      title:"",
                      innerText:""                     },
                     {
                      title:"",
                      innerText:"."
                     },
              ]
          },*/
</script>
<style scoped>
p{
  font-size: 12px;
}
.btn-info{
  background-color:#15414d;
  font-family: 'Montserrat', Helvetica, Arial, sans-serif !important;
}
.job-buttons {
    border: 0px solid white;
    width: 35vw;
    height: 9vh;
    box-shadow: 3px 3px 6px #b8b9be, -3px -3px 6px #fff;
    border-radius: 150px;
    background-color: #e5e5e5;
    line-height: 14px;
    display: flex;
    font-family: 'Montserrat', Helvetica, Arial, sans-serif !important;
    font-weight: bold;
    align-items: center;
    justify-content: center;
    margin: 3vh 1vw;
    color: #de1a21 !important;
    font-size: 1em;
}
.card-body button {
    display: block;
    margin: auto;
}
.back-btn {
    border: 0px solid white;
    background-color: #e5e5e5;
    box-shadow: 3px 3px 6px #b8b9be, -3px -3px 6px #fff;
    border-radius: 5px;   
}

.careers-right{
  box-shadow: inset 2px 2px 5px #B8B9BE, inset -3px -3px 7px #fff;
  padding: 15 10px;
}

.careers-right img {
    width: 66%;
    height: auto;
    display: block;
    /* margin: auto; */
}

</style>