/*
  Remember, we have access to our Vue instance not using
  an arrow function in our actions file. This allows us
  to create a scoped reference to our firebaseService - $fb
  That was assigned to the Vue instance earlier in our serviceConnection
  boot file.
*/

import { firestoreAction } from 'vuexfire'
import User from '../../models/User'
import { docRef } from '../../_services/firebase/db.js'
//import { collectionRef } from '../../_services/firebase/db.js'
import router from '../../router/index'
//import { Notify } from 'quasar'
//import classroom from '../classroom/index.js'

export const addUserToUsersCollection = function (state, userRef) {
  const email = state.email
  const user = new User({ email })
  console.log(state)
  user.familyName = state.familyName
  user.givenName = state.givenName
  if (state.role == 'student') {
    user.email = state.email
    user.role = state.role
    user.classroom = state.classroom

    // user.enrollmmentdata.organization = state.organization
    //user.organizationid = state.organizationid
  } else if (state.role == 'organizer') {
    user.email = state.email
    user.role = state.role

    user.organizationid = state.organizationid
  } else if (state.role == 'educator') {
    console.log(state)

    user.email = state.email
    user.role = state.role
    user.employmentdata.county = state.county
    user.employmentdata.state = state.state
    user.organizationid = state.organizationid
  }
  console.log(email)
  console.log(user)
  return userRef.set(user)
}

/**
 * @param {Object} $root - Place holder argument(Vuex store) to allow data to be passed
 *  as second argument.
 * @param data.email - User's email
 * @param data.password - User's password
 * @returns {function} - Firebase services function:
 * src/services/firebase/email.js > createUserWithEmail
 */
export const createNewUser = async function ($root, data) {
  const $fb = this.$fb
  console.log('Data')
  console.log(data)
  const { email, password } = data
  console.log(email)
  console.log(password)
  const fbAuthResponse = await $fb.createUserWithEmail(email, password)
  console.log(fbAuthResponse.user.uid)
  const id = fbAuthResponse.user.uid
  const userRef = docRef('users', id)

  return addUserToUsersCollection(data, userRef)
}
export const createAnswer = async function ($root, data) {
  const $fb = this.$fb
  console.log('Data')
  console.log(data)
  const { email, password } = data
  console.log(email)
  console.log(password)
  const fbAuthResponse = await $fb.createUserWithEmail(email, password)
  console.log(fbAuthResponse.user.uid)
  const id = fbAuthResponse.user.uid
  const userRef = docRef('users', id)

  return addUserToUsersCollection(data, userRef)
}

/**
 * @param {Object} $root - Place holder argument(Vuex store) to allow data to be passed
 *  as second argument.
 * @param data.email - User's email
 * @param data.password - User's password
 * @returns {function} - Firebase services function:
 * src/services/firebase/email.js > loginWithEmail
 */
export const loginUser = async function ($root, data) {
  console.log("login")

  const $fb = this.$fb
  const { email, password } = data
  //var successLoginEmail=
  // console.log(successLoginEmail)
  // console.log(successLoginEmail.i)
  // if (successLoginEmail){
  //   console.log('succeeded')
  // const userRef = docRef('users', id)

  //this.commit('user/setCurrentUserData', {role:"organize"}, { root: true })

  // }
  console.log(data)
  var userstuff= $fb.loginWithEmail(email, password)
  console.log(userstuff)
  return userstuff
}

/**
 * @returns {function} - Firebase services function:
 * src/services/firebase/email.js > logoutUser
 */
export const logoutUser = async function ({ commit }) {
  try {
    await firestoreAction(({ unbindFirestoreRef }) => {
      unbindFirestoreRef('currentUser')
    })
    commit('user/setCurrentUserData', null, { root: true })
    await this.$fb.logoutUser()
    //commit('common/setDrawerOpen', false, { root: true })
    // commit('user/setNullUserOnLogout', this)
  } catch (err) {

    console.log(err)
    //Notify.create({
    //  type: 'webapp_error',
    //  message: `An error as occured [logoutUser]: ${err}`
   // })
  }
}

export function routeUserToAuth() {
  router.push({
    path: '/login'
  })
}
