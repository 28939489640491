
var goodFit_Quiz_Mixin = {
    methods: {
      selectQuizInfo: function (regionName,worldName) {
        var worldsArrays={
            SAWDC:SAWDC_Quiz_Object,
            CAW:CAW_Quiz_Object,
            WAW:WAW_Quiz_Object,
            NAW:NAW_Quiz_Object,
            EAW:EAW_Quiz_Object,
            NCTEJW:NCTEJW_Quiz_Object

  
          }
          return worldsArrays[regionName][worldName]
      }
    }
  };
export default goodFit_Quiz_Mixin;
var questionOptions=[
  { text: 'Yes', value: true },
  { text: 'No', value: false },

]
var defaultSelected=null;
/*########################################################################################################*/
/*########################################################################################################*/
/*########################################################################################################*/
var SAWDC_Quiz_Object={ 
  aerospace_and_aviation:{

    questions:[ 
     { 
      questiontext: 'Do you enjoy working on projects as part of a team?',
      selected:defaultSelected,
      options:questionOptions 
    },
    {  
      questiontext: 'Do you enjoy problem solving?', 
      selected:defaultSelected,
      options:questionOptions },
    {  
      questiontext: 'Do you enjoy working with tools?', 
      selected:defaultSelected,  
      options:questionOptions
    },
    {  questiontext: 'Do you have a strong attention to detail?', selected:defaultSelected,  options:questionOptions},
    {  questiontext: 'Do you have strong math, science, and computer skills?', selected:defaultSelected,options:questionOptions},
],
    what_it_takes:[   
      { 
        text: 'An interest in science, math, and technical concepts',

      },
      {  
        text: ' Strong math and science skills', 

      },
      {
       text: 'Following directions', 

      },
      {  text: 'Attention to detail  '
      },
      {  text: 'Working on a team', 
      },

    ]
  },
  construction:{

    questions:[                          
{ questiontext: 'Do you enjoy working with tools?',selected:defaultSelected,  options:questionOptions},
{  questiontext: 'Do you have a strong attention to detail?', selected:defaultSelected, options:questionOptions},
{  questiontext: 'Do you have strong math, science, and computer skills?', selected:defaultSelected,  options:questionOptions},  
{  questiontext: 'Do you have strong organizational skills?', selected:defaultSelected,  options:questionOptions},
{  questiontext: 'Do you like building things or drawing?', selected:defaultSelected,  options:questionOptions},
],
    what_it_takes:[
{ 
  text: 'Strong Math, Science, and Time Management Skills',

},
{  
  text: 'Attention to Detail', 

},
{
 text: 'Ability to Follow Directions', 

},
{  text: 'Satisfaction in Teamwork', 
},
{  text: 'Hands-on Work with Tools', 
}
]
},
  energy:{

        questions:[                          
    { questiontext: 'Do you enjoy working on projects as part of a team?',selected:defaultSelected,  options:questionOptions},
    {  questiontext: 'Are you good at fixing, designing, or building things?', selected:defaultSelected, options:questionOptions},
    {  questiontext: 'Do you enjoy measuring and mapping data and details?', selected:defaultSelected,  options:questionOptions},  
    {  questiontext: 'Do you enjoy problem solving?', selected:defaultSelected,  options:questionOptions},
    {  questiontext: 'Do you thrive under pressure?', selected:defaultSelected,  options:questionOptions},
    ],
        what_it_takes:[
    { 
      text: 'Foundational Skills in Math & Technical Concepts',

    },
    {  
      text: 'Satisfaction in Teamwork', 

    },
    {
     text: 'Self-motivation and Attention to Detail', 

    },
    {  text: 'Leadership Skills and a Desire for Continuous Improvement'
    },
    {  text: 'Commitment to Life-long Learning', 
    }
  ]
  },
  engineering_and_design:{

        questions:[                          
    { questiontext: 'Do you enjoy working on projects as part of a team?',selected:defaultSelected,  options:questionOptions},
    {  questiontext: 'Are you good at fixing, designing, or building things?', selected:defaultSelected, options:questionOptions},
    {  questiontext: 'Do you enjoy measuring and mapping data and details?', selected:defaultSelected,  options:questionOptions},  
    {  questiontext: 'Do you have strong math, science, and computer skills?', selected:defaultSelected,  options:questionOptions},
    {  questiontext: 'Do you take it well when others suggest ways to improve your work performance?', selected:defaultSelected,  options:questionOptions},
    ],
        what_it_takes:[
    { 
      text: 'Strong math, science, computer and communication skills.',

    },
    {  
      text: 'Focus and attention to details', 

    },
    {
     text: 'Ability to stay well-organized', 

    },
    {  text: 'You enjoy problem-solving'
    },
    {  text: 'Teamwork and coordination', 
    },
    {  text: 'You enjoy being creative and expressing yourself visually', 
    }
  ]
  },
  healthcare:{

        questions:[                          
    { questiontext: 'Do you enjoy helping people?',selected:defaultSelected,  options:questionOptions},
    {  questiontext: 'Do you enjoy measuring and mapping data and details?', selected:defaultSelected, options:questionOptions},
    {  questiontext: 'Are you interested in how the human body works?', selected:defaultSelected,  options:questionOptions},  
    {  questiontext: 'Do you manage your own stress well?', selected:defaultSelected,  options:questionOptions},
    {  questiontext: 'Would you describe yourself as “patient” and “compassionate”?', selected:defaultSelected,  options:questionOptions},
    ],
        what_it_takes:[
    { 
      text: 'Strong math, science, and computer skills',

    },
    {  
      text: 'Attention to detail', 

    },
    {
     text: 'Desire to help others', 

    },
    {  text: 'Satisfaction in teamwork'
    },
    {  text: 'Work well under pressure', 
    }
  ]
  },
  information_technology:{

        questions:[                          
    { questiontext: 'Do you like working with computers?',selected:defaultSelected,  options:questionOptions},
    {  questiontext: 'Do you enjoy problem solving?', selected:defaultSelected, options:questionOptions},
    {  questiontext: 'Can you explain computers to people who have limited experience with them?', selected:defaultSelected,  options:questionOptions},  
    {  questiontext: 'Are you interested in the way businesses work?', selected:defaultSelected,  options:questionOptions},
    {  questiontext: 'Are you good at math?', selected:defaultSelected,  options:questionOptions},
    ],
        what_it_takes:[
    { 
      text: 'Communication skills',

    },
    {  
      text: 'Writing', 

    },
    {
     text: 'Organizational Skills', 

    },
    {  text: 'Problem Solving Skills'
    },
    {  text: 'Planning', 
    },
    {  text: 'Computer Skills/Typing', 
    }
  ]
  },
  hospitality_and_tourism:{

    questions:[                          
{ questiontext: 'Are you interested in people from different backgrounds?',selected:defaultSelected,  options:questionOptions},
{  questiontext: 'Can you multi-task and handle deadlines?', selected:defaultSelected, options:questionOptions},
{  questiontext: 'Can you study and master new subjects and skills?', selected:defaultSelected,  options:questionOptions},  
{  questiontext: 'Can you work extra hours and weekends?', selected:defaultSelected,  options:questionOptions},
{  questiontext: 'Do you enjoy helping people?', selected:defaultSelected,  options:questionOptions},
],
    what_it_takes:[
{ 
  text: 'Adaptability and a positive attitude',

},
{  
  text: 'Willingness to work hard as a team player', 

},
{
 text: 'Cooperation, diplomacy, and competitive nature', 

},
{  text: 'Ability to multi-task'
},
{  text: 'Organizational skills', 
},
{  text: 'Strong work ethic and competitive nature', 
},
{  text: 'Strong verbal and written communication skills', 
}
]
},
  manufacturing:{

        questions:[                          
    { questiontext: 'Do you have a strong attention to detail?',selected:defaultSelected,  options:questionOptions},
    {  questiontext: 'Do you have strong math, science, and computer skills?', selected:defaultSelected, options:questionOptions},
    {  questiontext: 'Do you enjoy problem solving?', selected:defaultSelected,  options:questionOptions},  
    {  questiontext: 'Do you enjoy working with tools?', selected:defaultSelected,  options:questionOptions},
    {  questiontext: 'Do you enjoy working on projects as part of a team?', selected:defaultSelected,  options:questionOptions},
    ],
        what_it_takes:[
    { 
      text: 'Strong Math and Science Skills',

    },
    {  
      text: 'Willingness to be Productive', 

    },
    {
     text: 'Satisfaction in Teamwork', 

    },
    {  text: 'Mastery of Complicated Machinery and Processes'
    },
    {  text: 'Problem-solving Skills', 
    }
  ]
  },
  public_service:{

        questions:[                          
    { questiontext: 'Are you a good communicator?',selected:defaultSelected,  options:questionOptions},
    {  questiontext: 'Do you enjoy helping people?', selected:defaultSelected, options:questionOptions},
    {  questiontext: 'Do you enjoy problem solving?', selected:defaultSelected,  options:questionOptions},  
    {  questiontext: 'Do you follow the rules?', selected:defaultSelected,  options:questionOptions},
    {  questiontext: 'Do you manage your own stress well?', selected:defaultSelected,  options:questionOptions},
    ],
        what_it_takes:[
    { 
      text: 'Sound moral principles',

    },
    {  
      text: 'Loyalty to country and community', 

    },
    {
     text: 'Dependability and a positive attitude', 

    },
    {  text: 'Willingness to work long and varied hours'
    },
    {  text: 'Physical fitness', 
    }
  ]
  },
  safety:{

        questions:[                          
    { questiontext: 'Are you a good communicator?',selected:defaultSelected,  options:questionOptions},
    {  questiontext: 'Do you enjoy measuring and mapping data and details?', selected:defaultSelected, options:questionOptions},
    {  questiontext: 'Do you have strong organizational skills?', selected:defaultSelected,  options:questionOptions},  
    {  questiontext: 'Do you follow the rules?', selected:defaultSelected,  options:questionOptions},
    {  questiontext: 'Do you have a strong attention to detail?', selected:defaultSelected,  options:questionOptions},
    ],
        what_it_takes:[
    { 
      text: 'Strong attention to detail',

    },
    {  
      text: 'An interest in science, math and technical concepts', 

    },
    {
     text: 'Good at working with people and communicating', 

    },
    {  text: 'A desire to help and provide service to people',
    },
    {  text: 'Strong planning and organizational skills', 
    },
    {  text: 'A genuine desire to learn and adapt to change', 
    }
  ]
  },
  shipbuilding_and_repair:{

        questions:[                          
    { questiontext: 'Do you enjoy working on projects as part of a team?',selected:defaultSelected,  options:questionOptions},
    {  questiontext: 'Do you enjoy problem solving?', selected:defaultSelected, options:questionOptions},
    {  questiontext: 'Do you enjoy working with tools?', selected:defaultSelected,  options:questionOptions},  
    {  questiontext: 'Do you have a strong attention to detail?', selected:defaultSelected,  options:questionOptions},
    {  questiontext: 'Do you have strong math, science, and computer skills?', selected:defaultSelected,  options:questionOptions},
    ],
        what_it_takes:[
    { 
      text: 'An interest in science, math, and technical concepts ',

    },
    {  
      text: 'Following directions', 

    },
    {
     text: 'Attention to detail', 

    },
    {  text: 'Working on a team',
    },
    {  text: 'Enjoy working with your hands and tools', 
    }
  ]
  },
  transportation_distribution_and_logistics:{

        questions:[                          
    { questiontext: 'Are you good at operating tools and machinery?',selected:defaultSelected,  options:questionOptions},
    {  questiontext: 'Are you physically strong and coordinated?', selected:defaultSelected, options:questionOptions},
    {  questiontext: 'Do you have strong math, science, and computer skills?', selected:defaultSelected,  options:questionOptions},  
    {  questiontext: 'Do you have strong organizational skills?', selected:defaultSelected,  options:questionOptions},
    {  questiontext: 'Do you like to travel?', selected:defaultSelected,  options:questionOptions},
    ],
        what_it_takes:[
    { 
      text: 'Time management skills',

    },
    {  
      text: 'Attention to Detail', 

    },
    {
     text: 'Problem-Solving Skills', 

    },
    {  text: 'Customer Service'
    },
    {  text: 'Strong Work Ethic', 
    }
  ]
  },

 
}


/*########################################################################################################*/
/*########################################################################################################*/
/*########################################################################################################*/
var NAW_Quiz_Object={ 
  agriculture_food_and_natural_resources:{

        questions:[                          
    { questiontext: 'Are you good with animals, or interested in biological sciences?',selected:defaultSelected,  options:questionOptions},
    {  questiontext: 'Are you interested in environmental issues?', selected:defaultSelected, options:questionOptions},
    {  questiontext: 'Do you enjoy gardening, camping, hunting, or fishing?', selected:defaultSelected,  options:questionOptions},  
    {  questiontext: 'Do you have a love for the great outdoors?', selected:defaultSelected,  options:questionOptions},
    {  questiontext: 'Do you have strong organizational skills?', selected:defaultSelected,  options:questionOptions},
    ],
        what_it_takes:[
    { 
      text: 'Entrepreneurial Spirit',

    },
    {  
      text: 'Strong Math and Science Skills', 

    },
    {
     text: 'Management Ability', 

    },
    {  text: 'Problem Solving Skills'
    },
    {  text: 'Working with Natural Resources, Such as Livestock, Plants, and Timber', 
    },
    {  text: 'Interest in Seeing a Product Grow from Start to Finish', 
    }
  ]
  },
  architecture_and_construction:{

        questions:[                          
    { questiontext: 'Do you enjoy working with tools?',selected:defaultSelected,  options:questionOptions},
    {  questiontext: 'Do you have a strong attention to detail?', selected:defaultSelected, options:questionOptions},
    {  questiontext: 'Do you have strong math, science, and computer skills?', selected:defaultSelected,  options:questionOptions},  
    {  questiontext: 'Do you have strong organizational skills?', selected:defaultSelected,  options:questionOptions},
    {  questiontext: 'Do you like building things or drawing?', selected:defaultSelected,  options:questionOptions},
    ],
        what_it_takes:[
    { 
      text: 'Strong Math, Science, and Time Management Skills',

    },
    {  
      text: 'Attention to Detail', 

    },
    {
     text: 'Ability to Follow Directions', 

    },
    {  text: 'Satisfaction in Teamwork',
    },
    {  text: 'Hands-on Work with Tools', 
    }
  ]
  },
  education_and_training:{

        questions:[                          
    { questiontext: 'Would you describe yourself as “patient” and “compassionate”?',selected:defaultSelected,  options:questionOptions},
    {  questiontext: 'Do you enjoy helping people?', selected:defaultSelected, options:questionOptions},
    {  questiontext: 'Are you organized and reliable?', selected:defaultSelected,  options:questionOptions},  
    {  questiontext: 'Do you have strong public speaking skills?', selected:defaultSelected,  options:questionOptions},
    {  questiontext: 'Are you a good communicator?', selected:defaultSelected,  options:questionOptions},
    ],
        what_it_takes:[
    { 
      text: 'Great communication skills',

    },
    {  
      text: 'A real grasp of the subject matter', 

    },
    {
     text: 'Organizational skills and the ability to think fast', 

    },
    {  text: 'Ability to motivate your students to do their best'
    },
    {  text: 'Ability to empathize with students', 
    },
    {  text: 'Ability to give feedback', 
    },
    {  text: 'Confidence', 
    },
    {  text: 'Ability to work effectively in groups', 
    }
  ]
  },
  energy_and_utilities:{

        questions:[                          
    { questiontext: 'Do you enjoy working on projects as part of a team?',selected:defaultSelected,  options:questionOptions},
    {  questiontext: 'Are you good at fixing, designing, or building things?', selected:defaultSelected, options:questionOptions},
    {  questiontext: 'Do you enjoy measuring and mapping data and details?', selected:defaultSelected,  options:questionOptions},  
    {  questiontext: 'Do you enjoy problem solving?', selected:defaultSelected,  options:questionOptions},
    {  questiontext: 'Do you thrive under pressure?', selected:defaultSelected,  options:questionOptions},
    ],
        what_it_takes:[
    { 
      text: 'Foundational Skills in Math & Technical Concepts',

    },
    {  
      text: 'Satisfaction in Teamwork', 

    },
    {
     text: 'Self-motivation and Attention to Detail', 

    },
    {  text: 'Leadership Skills and a Desire for Continuous Improvement'
    },
    {  text: 'Commitment to Life-long Learning', 
    }
  ]
  },
  healthcare:{

        questions:[                          
    { questiontext: 'Do you enjoy helping people?',selected:defaultSelected,  options:questionOptions},
    {  questiontext: 'Do you enjoy measuring and mapping data and details?', selected:defaultSelected, options:questionOptions},
    {  questiontext: 'Are you interested in how the human body works?', selected:defaultSelected,  options:questionOptions},  
    {  questiontext: 'Do you manage your own stress well?', selected:defaultSelected,  options:questionOptions},
    {  questiontext: 'Would you describe yourself as “patient” and “compassionate”?', selected:defaultSelected,  options:questionOptions},
    ],
        what_it_takes:[
    { 
      text: 'Strong math, science, and computer skills',

    },
    {  
      text: 'Attention to detail', 

    },
    {
     text: 'Desire to help others', 

    },
    {  text: 'Satisfaction in teamwork'
    }
  ]
  },
  hospitality_and_tourism:{

        questions:[                          
    { questiontext: 'Are you interested in people from different backgrounds?',selected:defaultSelected,  options:questionOptions},
    {  questiontext: 'Can you multi-task and handle deadlines?', selected:defaultSelected, options:questionOptions},
    {  questiontext: 'Can you study and master new subjects and skills?', selected:defaultSelected,  options:questionOptions},  
    {  questiontext: 'Can you work extra hours and weekends?', selected:defaultSelected,  options:questionOptions},
    {  questiontext: 'Do you enjoy helping people?', selected:defaultSelected,  options:questionOptions},
    ],
        what_it_takes:[
    { 
      text: 'Adaptability and a positive attitude',

    },
    {  
      text: 'Willingness to work hard as a team player', 

    },
    {
     text: 'Cooperation, diplomacy, and competitive nature', 

    },
    {  text: 'Ability to multi-task'
    },
    {  text: 'Organizational skills', 
    },
    {  text: 'Strong work ethic and competitive nature'
    },
    {  text: 'Strong verbal and written communication skills', 
    }
  ]
  },
  modern_manufacturing:{

        questions:[                          
    { questiontext: 'Do you have a strong attention to detail?',selected:defaultSelected,  options:questionOptions},
    {  questiontext: 'Do you have strong math, science, and computer skills?', selected:defaultSelected, options:questionOptions},
    {  questiontext: 'Do you enjoy problem solving?', selected:defaultSelected,  options:questionOptions},  
    {  questiontext: 'Do you enjoy working with tools?', selected:defaultSelected,  options:questionOptions},
    {  questiontext: 'Do you enjoy working on projects as part of a team?', selected:defaultSelected,  options:questionOptions},
    ],
        what_it_takes:[
    { 
      text: 'Strong Math and Science Skills',

    },
    {  
      text: 'Willingness to be Productive', 

    },
    {
     text: 'Satisfaction in Teamwork', 

    },
    {  text: 'Mastery of Complicated Machinery and Processes'
    },
    {  text: 'Problem-solving Skills', 
    }
  ]
  },
  modern_manufacturing_auto:{

        questions:[                          
    { questiontext: 'Do you have a strong attention to detail?',selected:defaultSelected,  options:questionOptions},
    {  questiontext: 'Do you have strong math, science, and computer skills?', selected:defaultSelected, options:questionOptions},
    {  questiontext: 'Do you enjoy problem solving?', selected:defaultSelected,  options:questionOptions},  
    {  questiontext: 'Do you enjoy working with tools?', selected:defaultSelected,  options:questionOptions},
    {  questiontext: 'Do you enjoy working on projects as part of a team?', selected:defaultSelected,  options:questionOptions},
    ],
        what_it_takes:[
    { 
      text: 'Strong Math and Science Skills',

    },
    {  
      text: 'Willingness to be Productive', 

    },
    {
     text: 'Satisfaction in Teamwork', 

    },
    {  text: 'Mastery of Complicated Machinery and Processes'
    },
    {  text: 'Problem-solving Skills', 
    }
  ]
  },
  law_public_safety_and_corrections:{

        questions:[                          
    { questiontext: 'Are you a good communicator?',selected:defaultSelected,  options:questionOptions},
    {  questiontext: 'Do you enjoy helping people?', selected:defaultSelected, options:questionOptions},
    {  questiontext: 'Do you enjoy problem solving?', selected:defaultSelected,  options:questionOptions},  
    {  questiontext: 'Do you follow the rules?', selected:defaultSelected,  options:questionOptions},
    {  questiontext: 'Do you manage your own stress well?', selected:defaultSelected,  options:questionOptions},
    ],
        what_it_takes:[
    { 
      text: 'Sound moral principles',

    },
    {  
      text: 'Loyalty to country and community', 

    },
    {
     text: 'Dependability and a positive attitude', 

    },
    {  text: 'Willingness to work long and varied hours'
    },
    {  text: 'Physical fitness', 
    }
  ]
  },
  transportation_distribution_and_logistics:{

        questions:[                          
    { questiontext: 'Are you good at operating tools and machinery?',selected:defaultSelected,  options:questionOptions},
    {  questiontext: 'Are you physically strong and coordinated?', selected:defaultSelected, options:questionOptions},
    {  questiontext: 'Do you have strong math, science, and computer skills?', selected:defaultSelected,  options:questionOptions},  
    {  questiontext: 'Do you have strong organizational skills?', selected:defaultSelected,  options:questionOptions},
    {  questiontext: 'Do you like to travel?', selected:defaultSelected,  options:questionOptions},
    ],
        what_it_takes:[
    { 
      text: 'Time management skills',

    },
    {  
      text: 'Attention to Detail', 

    },
    {
     text: 'Problem-Solving Skills', 

    },
    {  text: 'Customer Service'
    },
    {  text: 'Strong Work Ethic', 
    }
  ]
  },
  aerospace:{

        questions:[                          
    { questiontext: 'Do you enjoy working on projects as part of a team?',selected:defaultSelected,  options:questionOptions},
    {  questiontext: 'Do you enjoy problem solving?', selected:defaultSelected, options:questionOptions},
    {  questiontext: 'Do you enjoy working with tools?', selected:defaultSelected,  options:questionOptions},  
    {  questiontext: 'Do you have a strong attention to detail?', selected:defaultSelected,  options:questionOptions},
    {  questiontext: 'Do you have strong math, science, and computer skills?', selected:defaultSelected,  options:questionOptions},
    ],
        what_it_takes:[
    { 
      text: 'An interest in science, math, and technical concepts ',

    },
    {  
      text: 'Strong math and science skills', 

    },
    {
     text: 'Following directions', 

    },
    {  text: 'Attention to detail'
    },
    {  text: 'Working on a team ', 
    },
    {  text: 'Problem solving', 
    }
  ]
  },
  business_and_finance:{

        questions:[                          
    { questiontext: 'Are you a good communicator?',selected:defaultSelected,  options:questionOptions},
    {  questiontext: 'Do you enjoy problem solving?', selected:defaultSelected, options:questionOptions},
    {  questiontext: 'Are you good at math?', selected:defaultSelected,  options:questionOptions},  
    {  questiontext: 'Are you interested in the way businesses work?', selected:defaultSelected,  options:questionOptions},
    {  questiontext: 'Are you organized and reliable?', selected:defaultSelected,  options:questionOptions},
    ],
        what_it_takes:[
    { 
      text: 'An interest in math and problem-solving',

    },
    {  
      text: 'Strong organizational skills', 

    },
    {
     text: 'Attention to detail', 

    },
    {  text: 'Strong communication skills'
    },
    {  text: 'Understanding of how businesses work', 
    }
  ]
  },
  communications_media_and_marketing:{

        questions:[                          
    { questiontext: 'Do you enjoy art, writing, or public speaking?',selected:defaultSelected,  options:questionOptions},
    {  questiontext: 'Are you friendly and outgoing?', selected:defaultSelected, options:questionOptions},
    {  questiontext: 'Do you enjoy working on projects as part of a team?', selected:defaultSelected,  options:questionOptions},  
    {  questiontext: 'Do you like to write, act, sing, or create visual art?', selected:defaultSelected,  options:questionOptions},
    {  questiontext: 'Are you a good communicator?', selected:defaultSelected,  options:questionOptions},
    ],
        what_it_takes:[
    { 
      text: 'Strong Math and Science Skills',

    },
    {  
      text: 'Willingness to be Productive', 

    },
    {
     text: 'Satisfaction in Teamwork', 

    },
    {  text: 'Mastery of Complicated Machinery and Processes'
    },
    {  text: 'Problem-solving Skills', 
    }
  ]
  },
  cybersecurity_and_information_technology:{

        questions:[                          
    { questiontext: 'Do you like working with computers?',selected:defaultSelected,  options:questionOptions},
    {  questiontext: 'Do you enjoy problem solving?', selected:defaultSelected, options:questionOptions},
    {  questiontext: 'Can you explain computers to people who have limited experience with them?', selected:defaultSelected,  options:questionOptions},  
    {  questiontext: 'Are you interested in the way businesses work?', selected:defaultSelected,  options:questionOptions},
    {  questiontext: 'Are you good at math?', selected:defaultSelected,  options:questionOptions},
    ],
        what_it_takes:[
    { 
      text: 'Communication skills',

    },
    {  
      text: 'Writing', 

    },
    {
     text: 'Organizational Skills', 

    },
    {  text: 'Problem Solving Skills'
    },
    {  text: 'Planning', 
    },
    {  text: 'Computer Skills/Typing', 
    }

  ]
  },
 
}

/*########################################################################################################*/
/*########################################################################################################*/
/*########################################################################################################*/
var EAW_Quiz_Object={ 
  agriculture_and_natural_resources:{

        questions:[                          
    { questiontext: 'Are you good with animals, or interested in biological sciences?',selected:defaultSelected,  options:questionOptions},
    {  questiontext: 'Are you interested in environmental issues?', selected:defaultSelected, options:questionOptions},
    {  questiontext: 'Do you enjoy gardening, camping, hunting, or fishing?', selected:defaultSelected,  options:questionOptions},  
    {  questiontext: 'Do you have a love for the great outdoors?', selected:defaultSelected,  options:questionOptions},
    {  questiontext: 'Do you have strong organizational skills?', selected:defaultSelected,  options:questionOptions},
    ],
        what_it_takes:[
    { 
      text: 'Entrepreneurial Spirit',

    },
    {  
      text: 'Strong Math and Science Skills', 

    },
    {
     text: 'Management Ability', 

    },
    {  text: 'Problem Solving Skills '
    },
    {  text: 'Working with Natural Resources, Such as Livestock, Plants, and Timber ', 
    },
    {  text: 'Interest in Seeing a Product Grow from Start to Finish', 
    }
  ]
  },
  automotive_and_manufacturing:{

        questions:[                          
    { questiontext: 'Do you have a strong attention to detail?',selected:defaultSelected,  options:questionOptions},
    {  questiontext: 'Do you have strong math, science, and computer skills?', selected:defaultSelected, options:questionOptions},
    {  questiontext: 'Do you enjoy problem solving?', selected:defaultSelected,  options:questionOptions},  
    {  questiontext: 'Do you enjoy working with tools?', selected:defaultSelected,  options:questionOptions},
    {  questiontext: 'Do you enjoy working on projects as part of a team?', selected:defaultSelected,  options:questionOptions},
    ],
        what_it_takes:[
    { 
      text: 'Strong Math and Science Skills',

    },
    {  
      text: 'Willingness to be Productive', 

    },
    {
     text: 'Satisfaction in Teamwork', 

    },
    {  text: 'Mastery of Complicated Machinery and Processes'
    },
    {  text: 'Problem-solving Skills', 
    }
  ]
  },
  communications_media_and_marketing:{

        questions:[                          
    { questiontext: 'Do you enjoy art, writing, or public speaking?',selected:defaultSelected,  options:questionOptions},
    {  questiontext: 'Are you friendly and outgoing?', selected:defaultSelected, options:questionOptions},
    {  questiontext: 'Do you enjoy working on projects as part of a team?', selected:defaultSelected,  options:questionOptions},  
    {  questiontext: 'Do you like to write, act, sing, or create visual art?', selected:defaultSelected,  options:questionOptions},
    {  questiontext: 'Are you a good communicator?', selected:defaultSelected,  options:questionOptions},
    ],
        what_it_takes:[
    { 
      text: 'Strong Math and Science Skills',

    },
    {  
      text: 'Willingness to be Productive', 

    },
    {
     text: 'Satisfaction in Teamwork', 

    },
    {  text: 'Mastery of Complicated Machinery and Processes'
    },
    {  text: 'Problem-solving Skills', 
    }
  ]
  },
  construction:{

        questions:[                          
    { questiontext: 'Do you enjoy working with tools?',selected:defaultSelected,  options:questionOptions},
    {  questiontext: 'Do you have a strong attention to detail?', selected:defaultSelected, options:questionOptions},
    {  questiontext: 'Do you have strong math, science, and computer skills?', selected:defaultSelected,  options:questionOptions},  
    {  questiontext: 'Do you have strong organizational skills?', selected:defaultSelected,  options:questionOptions},
    {  questiontext: 'Do you like building things or drawing?', selected:defaultSelected,  options:questionOptions},
    ],
        what_it_takes:[
    { 
      text: 'Strong Math, Science, and Time Management Skills',

    },
    {  
      text: 'Attention to Detail', 

    },
    {
     text: 'Ability to Follow Directions', 

    },
    {  text: 'Satisfaction in Teamwork'
    },
    {  text: 'Hands-on Work with Tools', 
    }
  ]
  },
  energy_and_utilities:{

        questions:[                          
    { questiontext: 'Do you enjoy working on projects as part of a team?',selected:defaultSelected,  options:questionOptions},
    {  questiontext: 'Are you good at fixing, designing, or building things?', selected:defaultSelected, options:questionOptions},
    {  questiontext: 'Do you enjoy measuring and mapping data and details?', selected:defaultSelected,  options:questionOptions},  
    {  questiontext: 'Do you enjoy problem solving?', selected:defaultSelected,  options:questionOptions},
    {  questiontext: 'Do you thrive under pressure?', selected:defaultSelected,  options:questionOptions},
    ],
        what_it_takes:[
    { 
      text: 'Foundational Skills in Math & Technical Concepts',

    },
    {  
      text: 'Satisfaction in Teamwork', 

    },
    {
     text: 'Self-motivation and Attention to Detail', 

    },
    {  text: 'Leadership Skills and a Desire for Continuous Improvement' 
    },
    {  text: 'Commitment to Life-long Learning', 
    }
  ]
  },
  engineering:{

        questions:[                          
    { questiontext: 'Do you enjoy working on projects as part of a team?',selected:defaultSelected,  options:questionOptions},
    {  questiontext: 'Are you good at fixing, designing, or building things?', selected:defaultSelected, options:questionOptions},
    {  questiontext: 'Do you enjoy measuring and mapping data and details?', selected:defaultSelected,  options:questionOptions},  
    {  questiontext: 'Would you like to fly airplanes or explore the solar system?', selected:defaultSelected,  options:questionOptions},
    {  questiontext: 'Do you thrive under pressure?', selected:defaultSelected,  options:questionOptions},
    ],
        what_it_takes:[
    { 
      text: 'Strong math, science, computer and communication skills.',

    },
    {  
      text: 'Ability to stay well-organized', 

    },
    {
     text: 'You enjoy problem-solving', 

    },
    {  text: 'Teamwork and coordination'
    }
  ]
  },
  healthcare:{

        questions:[                          
    { questiontext: 'Do you enjoy helping people?',selected:defaultSelected,  options:questionOptions},
    {  questiontext: 'Do you enjoy measuring and mapping data and details?', selected:defaultSelected, options:questionOptions},
    {  questiontext: 'Are you interested in how the human body works?', selected:defaultSelected,  options:questionOptions},  
    {  questiontext: 'Do you manage your own stress well?', selected:defaultSelected,  options:questionOptions},
    {  questiontext: 'Would you describe yourself as “patient” and “compassionate”?', selected:defaultSelected,  options:questionOptions},
    ],
        what_it_takes:[
    { 
      text: 'Strong math, science, and computer skills',

    },
    {  
      text: 'Attention to detail', 

    },
    {
     text: 'Desire to help others', 

    },
    {  text: 'Satisfaction in teamwork'
    },
  
  ]
  },
  information_technology:{

        questions:[                          
    { questiontext: 'Do you like working with computers?',selected:defaultSelected,  options:questionOptions},
    {  questiontext: 'Do you enjoy problem solving?', selected:defaultSelected, options:questionOptions},
    {  questiontext: 'Can you explain computers to people who have limited experience with them?', selected:defaultSelected,  options:questionOptions},  
    {  questiontext: 'Are you interested in the way businesses work?', selected:defaultSelected,  options:questionOptions},
    {  questiontext: 'Are you good at math?', selected:defaultSelected,  options:questionOptions},
    ],
        what_it_takes:[
    { 
      text: 'Communication skills',

    },
    {  
      text: 'Writing', 

    },
    {
     text: 'Organizational Skills', 

    },
    {  text: 'Problem Solving Skills'
    },
    {  text: 'Planning', 
    },
    {  text: 'Computer Skills/Typing', 
    }
  ]
  },
  public_safety:{

        questions:[                          
    { questiontext: 'Are you a good communicator?',selected:defaultSelected,  options:questionOptions},
    {  questiontext: 'Do you enjoy helping people?', selected:defaultSelected, options:questionOptions},
    {  questiontext: 'Do you enjoy problem solving?', selected:defaultSelected,  options:questionOptions},  
    {  questiontext: 'Do you follow the rules?', selected:defaultSelected,  options:questionOptions},
    {  questiontext: 'Do you manage your own stress well?', selected:defaultSelected,  options:questionOptions},
    ],
        what_it_takes:[
    { 
      text: 'Sound moral principles',

    },
    {  
      text: 'Loyalty to country and community', 

    },
    {
     text: 'Dependability and a positive attitude', 

    },
    {  text: 'Willingness to work long and varied hours'
    },
    {  text: 'Physical fitness', 
    }
  ]
  },
 
  transportation_distribution_and_logistics:{

        questions:[                          
    { questiontext: 'Are you good at operating tools and machinery?',selected:defaultSelected,  options:questionOptions},
    {  questiontext: 'Are you physically strong and coordinated?', selected:defaultSelected, options:questionOptions},
    {  questiontext: 'Do you have strong math, science, and computer skills?', selected:defaultSelected,  options:questionOptions},  
    {  questiontext: 'Do you have strong organizational skills?', selected:defaultSelected,  options:questionOptions},
    {  questiontext: 'Do you like to travel?', selected:defaultSelected,  options:questionOptions},
    ],
        what_it_takes:[
    { 
      text: 'Time management skills',

    },
    {  
      text: 'Attention to Detail', 

    },
    {
     text: 'Problem-Solving Skills', 

    },
    {  text: 'Customer Service'
    },
    {  text: 'Strong Work Ethic', 
    }
  ]
  },
 
 
}

/*########################################################################################################*/
/*########################################################################################################*/
/*########################################################################################################*/
var WAW_Quiz_Object={ 
  agriculture:{

    questions:[                          
      { questiontext: 'Are you good with animals, or interested in biological sciences?',selected:defaultSelected,  options:questionOptions
      },
      { questiontext: 'Are you interested in environmental issues?',selected:defaultSelected,  options:questionOptions
      },
      { questiontext: 'Do you enjoy gardening, camping, hunting, or fishing?',selected:defaultSelected,  options:questionOptions
      },
      { questiontext: 'Do you have a love for the great outdoors?',selected:defaultSelected,  options:questionOptions
      },
      { questiontext: 'Do you have strong organizational skills?',selected:defaultSelected,  options:questionOptions
      }
    ],
    what_it_takes:[
      { 
        text: 'Entrepreneurial Spirit'
      },
      { 
        text: 'Strong Math and Science Skills'
      },
      { 
        text: 'Management Ability'
      },
      { 
        text: 'Problem Solving Skills'
      },
      { 
        text: 'Working with Natural Resources, Such as Livestock, Plants, and Timber'
      },
      { 
        text: 'Interest in Seeing a Product Grow from Start to Finish'
      }
    ]
  },
  cosmetology:{

    questions:[                          
      { questiontext: 'Are you a good communicator?',selected:defaultSelected,  options:questionOptions
      },
      { questiontext: 'Can you complete multiple assignments on time and on your own without reminders?',selected:defaultSelected,  options:questionOptions
      },
      { questiontext: 'Does providing customer service to people sound appealing to you?',selected:defaultSelected,  options:questionOptions
      },
      { questiontext: 'Do you like to express yourself artistically?',selected:defaultSelected,  options:questionOptions
      },
      { questiontext: 'Do you take it well when others suggest ways to improve your work performance?',selected:defaultSelected,  options:questionOptions
      }
    ],
    what_it_takes:[
      { 
        text: 'Communication Skills'
      },
      { 
        text: 'Creativity'
      },
      { 
        text: 'Strong Customer Service Skills'
      },
      { 
        text: 'Willingness to Continue Learning'
      },
      { 
        text: 'Time Management Skills'
      },
    ]
  },
  construction:{

    questions:[                          
      { questiontext: 'Do you enjoy working with tools?',selected:defaultSelected,  options:questionOptions
      },
      { questiontext: 'Do you have a strong attention to detail?',selected:defaultSelected,  options:questionOptions
      },
      { questiontext: 'Do you have strong math, science, and computer skills?',selected:defaultSelected,  options:questionOptions
      },
      { questiontext: 'Do you have strong organizational skills?',selected:defaultSelected,  options:questionOptions
      },
      { questiontext: 'Do you like building things or drawing?',selected:defaultSelected,  options:questionOptions
      }
    ],
    what_it_takes:[
      { 
        text: 'Strong Math, Science, and Time Management Skills'
      },
      { 
        text: 'Attention to Detail'
      },
      { 
        text: 'Ability to Follow Directions'
      },
      { 
        text: 'Satisfaction in Teamwork'
      },
      { 
        text: 'Hands-on Work with Tools'
      },
    ]
  },
  education:{

    questions:[                          
      { questiontext: 'Would you describe yourself as “patient” and “compassionate”?',selected:defaultSelected,  options:questionOptions
      },
      { questiontext: 'Do you enjoy helping people?',selected:defaultSelected,  options:questionOptions
      },
      { questiontext: 'Are you organized and reliable?',selected:defaultSelected,  options:questionOptions
      },
      { questiontext: 'Do you have strong public speaking skills?',selected:defaultSelected,  options:questionOptions
      },
      { questiontext: 'Are you a good communicator?',selected:defaultSelected,  options:questionOptions
      }
    ],
    what_it_takes:[
      { 
        text: 'Great communication skills'
      },
      { 
        text: 'A real grasp of the subject matter'
      },
      { 
        text: 'Organizational skills and the ability to think fast'
      },
      { 
        text: 'Ability to motivate your students to do their best'
      },
      { 
        text: 'Ability to empathize with students'
      },
      { 
        text: 'Ability to give feedback'
      },
      { 
        text: 'Confidence'
      },
      { 
        text: 'Ability to work effectively in groups'
      },
    ]
  },
  energy_and_utilities:{

    questions:[                          
      { questiontext: 'Do you enjoy working on projects as part of a team?',selected:defaultSelected,  options:questionOptions
      },
      { questiontext: 'Are you good at fixing, designing, or building things?',selected:defaultSelected,  options:questionOptions
      },
      { questiontext: 'Do you enjoy measuring and mapping data and details?',selected:defaultSelected,  options:questionOptions
      },
      { questiontext: 'Do you enjoy problem solving?',selected:defaultSelected,  options:questionOptions
      },
      { questiontext: 'Do you thrive under pressure?',selected:defaultSelected,  options:questionOptions
      }
    ],
    what_it_takes:[
      { 
        text: 'Foundational Skills in Math & Technical Concepts'
      },
      { 
        text: 'Satisfaction in Teamwork'
      },
      { 
        text: 'Self-motivation and Attention to Detail'
      },
      { 
        text: 'Leadership Skills and a Desire for Continuous Improvement'
      },
      { 
        text: 'Commitment to Life-long Learning'
      },
    ]
  },

  engineering_and_environment:{

    questions:[                          
      { questiontext: 'Do you enjoy working on projects as part of a team?',selected:defaultSelected,  options:questionOptions
      },
      { questiontext: 'Are you good at fixing, designing, or building things?',selected:defaultSelected,  options:questionOptions
      },
      { questiontext: 'Do you enjoy measuring and mapping data and details?',selected:defaultSelected,  options:questionOptions
      },
      { questiontext: 'Do you have strong math, science, and computer skills?',selected:defaultSelected,  options:questionOptions
      },
      { questiontext: 'Would you like to fly airplanes or explore the solar system?',selected:defaultSelected,  options:questionOptions
      }
    ],
    what_it_takes:[
      { 
        text: 'Strong math, science, computer and communication skills.'
      },
      { 
        text: 'Focus and attention to details'
      },
      { 
        text: 'Ability to stay well-organized'
      },
      { 
        text: 'You enjoy problem-solving'
      },
      { 
        text: 'Teamwork and coordination'
      },
    ]
  },
  healthcare:{

    questions:[                          
      { questiontext: 'Do you enjoy helping people?',selected:defaultSelected,  options:questionOptions
      },
      { questiontext: 'Do you enjoy measuring and mapping data and details?',selected:defaultSelected,  options:questionOptions
      },
      { questiontext: 'Are you interested in how the human body works?',selected:defaultSelected,  options:questionOptions
      },
      { questiontext: 'Do you manage your own stress well?',selected:defaultSelected,  options:questionOptions
      },
      { questiontext: 'Would you describe yourself as “patient” and “compassionate”?',selected:defaultSelected,  options:questionOptions
      }
    ],
    what_it_takes:[
      { 
        text: 'Strong math, science, and computer skills'
      },
      { 
        text: 'Attention to detail'
      },
      { 
        text: 'Desire to help others'
      },
      { 
        text: 'Satisfaction in teamwork'
      }
    ]
  },
  hospitality_and_tourism:{

    questions:[                          
      { questiontext: 'Are you interested in people from different backgrounds?',selected:defaultSelected,  options:questionOptions
      },
      { questiontext: 'Can you multi-task and handle deadlines?',selected:defaultSelected,  options:questionOptions
      },
      { questiontext: 'Can you study and master new subjects and skills?',selected:defaultSelected,  options:questionOptions
      },
      { questiontext: 'Can you work extra hours and weekends?',selected:defaultSelected,  options:questionOptions
      },
      { questiontext: 'Do you enjoy helping people?',selected:defaultSelected,  options:questionOptions
      }
    ],
    what_it_takes:[
      { 
        text: 'Adaptability and a positive attitude'
      },
      { 
        text: 'Willingness to work hard as a team player'
      },
      { 
        text: 'Cooperation, diplomacy, and competitive nature'
      },
      { 
        text: 'Ability to multi-task'
      },
      { 
        text: 'Organizational skills'
      },
      { 
        text: 'Strong work ethic and competitive nature'
      },
      { 
        text: 'Strong verbal and written communication skills'
      },
    ]
  },
  manufacturing_auto_logistics:{

    questions:[                          
      { questiontext: 'Do you have a strong attention to detail?',selected:defaultSelected,  options:questionOptions
      },
      { questiontext: 'Do you have strong math, science, and computer skills?',selected:defaultSelected,  options:questionOptions
      },
      { questiontext: 'Do you enjoy problem solving?',selected:defaultSelected,  options:questionOptions
      },
      { questiontext: 'Do you enjoy working with tools?',selected:defaultSelected,  options:questionOptions
      },
      { questiontext: 'Do you enjoy working on projects as part of a team?',selected:defaultSelected,  options:questionOptions
      }
    ],
    what_it_takes:[
      { 
        text: 'Strong Math and Science Skills'
      },
      { 
        text: 'Willingness to be Productive'
      },
      { 
        text: 'Satisfaction in Teamwork'
      },
      { 
        text: 'Mastery of Complicated Machinery and Processes'
      },
      { 
        text: 'Problem-solving Skills'
      },
    ]
  },
  public_safety:{

    questions:[                          
      { questiontext: 'Are you a good communicator?',selected:defaultSelected,  options:questionOptions
      },
      { questiontext: 'Do you enjoy helping people?',selected:defaultSelected,  options:questionOptions
      },
      { questiontext: 'Do you enjoy problem solving?',selected:defaultSelected,  options:questionOptions
      },
      { questiontext: 'Do you follow the rules?',selected:defaultSelected,  options:questionOptions
      },
      { questiontext: 'Do you manage your own stress well?',selected:defaultSelected,  options:questionOptions
      }
    ],
    what_it_takes:[
      { 
        text: 'Sound moral principles'
      },
      { 
        text: 'Loyalty to country and community'
      },
      { 
        text: 'Dependability and a positive attitude'
      },
      { 
        text: 'Willingness to work long and varied hours'
      },
      { 
        text: 'Physical fitness'
      },
    ]
  },
  transportation:{

    questions:[                          
      { questiontext: 'Are you good at operating tools and machinery?',selected:defaultSelected,  options:questionOptions
      },
      { questiontext: 'Are you physically strong and coordinated?',selected:defaultSelected,  options:questionOptions
      },
      { questiontext: 'Do you have strong math, science, and computer skills?',selected:defaultSelected,  options:questionOptions
      },
      { questiontext: 'Do you have strong organizational skills?',selected:defaultSelected,  options:questionOptions
      },
      { questiontext: 'Do you like to travel?',selected:defaultSelected,  options:questionOptions
      }
    ],
    what_it_takes:[
      { 
        text: 'Time management skills'
      },
      { 
        text: 'Attention to Detail'
      },
      { 
        text: 'Problem-Solving Skills'
      },
      { 
        text: 'Customer Service'
      },
      { 
        text: 'Strong Work Ethic'
      },
    ]
  }
}

/*########################################################################################################*/
/*########################################################################################################*/
/*########################################################################################################*/
var CAW_Quiz_Object={ 
  agriculture:{

        questions:[                          
    { questiontext: 'Are you good with animals, or interested in biological sciences?',selected:defaultSelected,  options:questionOptions},
    {  questiontext: 'Are you interested in environmental issues?', selected:defaultSelected, options:questionOptions},
    {  questiontext: 'Do you enjoy gardening, camping, hunting, or fishing?', selected:defaultSelected,  options:questionOptions},  
    {  questiontext: 'Do you have a love for the great outdoors?', selected:defaultSelected,  options:questionOptions},
    {  questiontext: 'Do you have strong organizational skills?', selected:defaultSelected,  options:questionOptions},
    ],
        what_it_takes:[
    { 
      text: 'Entrepreneurial Spirit',

    },
    {  
      text: 'Strong Math and Science Skills', 

    },
    {
     text: 'Management Ability', 

    },
    {  text: 'Problem Solving Skills'
    },
    {  text: 'Working with Natural Resources, Such as Livestock, Plants, and Timber', 
    },
    {  text: 'Interest in Seeing a Product Grow from Start to Finish', 
    },
  ]
  },
  healthcare:{

        questions:[                          
    { questiontext: 'Do you enjoy helping people?',selected:defaultSelected,  options:questionOptions},
    {  questiontext: 'Do you enjoy measuring and mapping data and details?', selected:defaultSelected, options:questionOptions},
    {  questiontext: 'Are you interested in how the human body works?', selected:defaultSelected,  options:questionOptions},  
    {  questiontext: 'Do you manage your own stress well?', selected:defaultSelected,  options:questionOptions},
    {  questiontext: 'Would you describe yourself as “patient” and “compassionate”?', selected:defaultSelected,  options:questionOptions},
    ],
        what_it_takes:[
    { 
      text: 'Strong math, science, and computer skills',

    },
    {  
      text: 'Attention to detail', 

    },
    {
     text: 'Desire to help others', 

    },
    {  text: 'Satisfaction in teamwork'
    }
  ]
  },
  manufacturing:{

        questions:[                          
    { questiontext: 'Do you have a strong attention to detail?',selected:defaultSelected,  options:questionOptions},
    {  questiontext: 'Do you have strong math, science, and computer skills?', selected:defaultSelected, options:questionOptions},
    {  questiontext: 'Do you enjoy problem solving?', selected:defaultSelected,  options:questionOptions},  
    {  questiontext: 'Do you enjoy working with tools?', selected:defaultSelected,  options:questionOptions},
    {  questiontext: 'Do you enjoy working on projects as part of a team?', selected:defaultSelected,  options:questionOptions},
    ],
        what_it_takes:[
    { 
      text: 'Strong Math and Science Skills',

    },
    {  
      text: 'Willingness to be Productive', 

    },
    {
     text: 'Satisfaction in Teamwork', 

    },
    {  text: 'Mastery of Complicated Machinery and Processes',
    },
    {  text: 'Problem-solving Skills', 
    }
  ]
  },
  public_service:{

        questions:[                          
    { questiontext: 'Are you a good communicator?',selected:defaultSelected,  options:questionOptions},
    {  questiontext: 'Do you enjoy helping people?', selected:defaultSelected, options:questionOptions},
    {  questiontext: 'Do you enjoy problem solving?', selected:defaultSelected,  options:questionOptions},  
    {  questiontext: 'Do you follow the rules?', selected:defaultSelected,  options:questionOptions},
    {  questiontext: 'Do you manage your own stress well?', selected:defaultSelected,  options:questionOptions},
    ],
        what_it_takes:[
    { 
      text: 'Sound moral principles',

    },
    {  
      text: 'Loyalty to country and community', 

    },
    {
     text: 'Dependability and a positive attitude', 

    },
    {  text: 'Willingness to work long and varied hours'
    },
    {  text: 'Physical fitness', 
    }
  ]
  },
  transportation_distribution_and_logistics:{

        questions:[                          
    { questiontext: 'Are you good at operating tools and machinery?',selected:defaultSelected,  options:questionOptions},
    {  questiontext: 'Are you physically strong and coordinated?', selected:defaultSelected, options:questionOptions},
    {  questiontext: 'Do you have strong math, science, and computer skills?', selected:defaultSelected,  options:questionOptions},  
    {  questiontext: 'Do you have strong organizational skills?', selected:defaultSelected,  options:questionOptions},
    {  questiontext: 'Do you like to travel?', selected:defaultSelected,  options:questionOptions},
    ],
        what_it_takes:[
    { 
      text: 'Time management skills',

    },
    {  
      text: 'Attention to Detail', 

    },
    {
     text: 'Problem-Solving Skills', 

    },
    {  text: 'Customer Service'
    },
    {  text: 'Strong Work Ethic', 
    }
  ]
  },
  communication_and_information_technology:{

        questions:[                          
    { questiontext: 'Do you like working with computers?',selected:defaultSelected,  options:questionOptions},
    {  questiontext: 'Do you enjoy problem solving?', selected:defaultSelected, options:questionOptions},
    {  questiontext: 'Can you explain computers to people who have limited experience with them?', selected:defaultSelected,  options:questionOptions},  
    {  questiontext: 'Are you interested in the way businesses work?', selected:defaultSelected,  options:questionOptions},
    {  questiontext: 'Are you good at math?', selected:defaultSelected,  options:questionOptions},
    ],
        what_it_takes:[
    { 
      text: 'Communication skills',

    },
    {  
      text: 'Writing', 

    },
    {
     text: 'Organizational Skills', 

    },
    {  text: 'Problem Solving Skills'
    },
    {  text: 'Planning', 
    },
    {  text: 'Computer Skills/Typing', 
    }
  ]
  },
  hospitality_and_tourism:{

        questions:[                          
    { questiontext: 'Are you interested in people from different backgrounds?',selected:defaultSelected,  options:questionOptions},
    {  questiontext: 'Can you multi-task and handle deadlines?', selected:defaultSelected, options:questionOptions},
    {  questiontext: 'Can you study and master new subjects and skills?', selected:defaultSelected,  options:questionOptions},  
    {  questiontext: 'Can you work extra hours and weekends?', selected:defaultSelected,  options:questionOptions},
    {  questiontext: 'Do you enjoy helping people?', selected:defaultSelected,  options:questionOptions},
    ],
        what_it_takes:[
    { 
      text: 'Adaptability and a positive attitude',

    },
    {  
      text: 'Willingness to work hard as a team player', 

    },
    {
     text: 'Cooperation, diplomacy, and competitive nature', 

    },
    {  text: 'Ability to multi-task'
    },
    {  text: 'Organizational skills', 
    },
    {  text: 'Strong work ethic and competitive nature', 
    },
    {  text: 'Strong verbal and written communication skills', 
    },
  ]
  },

  education_and_training:{

        questions:[                          
    { questiontext: 'Would you describe yourself as “patient” and “compassionate”?',selected:defaultSelected,  options:questionOptions},
    {  questiontext: 'Do you enjoy helping people?', selected:defaultSelected, options:questionOptions},
    {  questiontext: 'Are you organized and reliable?', selected:defaultSelected,  options:questionOptions},  
    {  questiontext: 'Do you have strong public speaking skills?', selected:defaultSelected,  options:questionOptions},
    {  questiontext: 'Are you a good communicator?', selected:defaultSelected,  options:questionOptions},
    ],
        what_it_takes:[
    { 
      text: 'Great communication skills',

    },
    {  
      text: 'A real grasp of the subject matter', 

    },
    {
     text: 'Organizational skills and the ability to think fast', 

    },
    {  text: 'Ability to motivate your students to do their best',
    },
    {  text: 'Ability to empathize with students', 
    },
    {  text: 'Ability to give feedback', 
    },
    {  text: 'Confidence', 
    },
    {  text: 'Ability to work effectively in groups', 
    },
  ]
  },
  architecture_construction_and_utilities:{

        questions:[                          
    { questiontext: 'Do you enjoy working with tools?',selected:defaultSelected,  options:questionOptions},
    {  questiontext: 'Do you have a strong attention to detail?', selected:defaultSelected, options:questionOptions},
    {  questiontext: 'Do you have strong math, science, and computer skills?', selected:defaultSelected,  options:questionOptions},  
    {  questiontext: 'Do you enjoy measuring and mapping data and details?', selected:defaultSelected,  options:questionOptions},
    {  questiontext: 'Do you like building things or drawing?', selected:defaultSelected,  options:questionOptions},
    ],
        what_it_takes:[
    { 
      text: 'Strong Math, Science, and Time Management Skills',

    },
    {  
      text: 'Attention to Detail', 

    },
    {
     text: 'Ability to Follow Directions', 

    },
    {  text: 'Satisfaction in Teamwork'
    },
    {  text: 'Hands-on Work with Tools', 
    }
  ]
  },


 
}


 /*********************** */
 var NCTEJW_Quiz_Object={ 
  agriculture:{

    questions:[ 
     { 
      questiontext: 'Are you good with animals, or interested in biological sciences?',
      selected:defaultSelected,
      options:questionOptions 
    },
    {  
      questiontext: 'Are you interested in environmental issues?', 
      selected:defaultSelected,
      options:questionOptions },
    {  
      questiontext: 'Do you enjoy gardening, camping, hunting, or fishing?', 
      selected:defaultSelected,  
      options:questionOptions
    },
    {  questiontext: 'Do you have a love for the great outdoors?', selected:defaultSelected,  options:questionOptions},
    {  questiontext: 'Do you have strong organizational skills?', selected:defaultSelected,options:questionOptions},
],
    what_it_takes:[   
      { 
        text: 'Entrepreneurial Spirit',

      },
      {  
        text: 'Strong Math and Science Skills', 

      },
      {
       text: 'Management Ability', 

      },
      {  text: 'Problem Solving Skills'
      },
      {  text: 'Working with Natural Resources, Such as Livestock, Plants, and Timber', 
      },
      {  text: 'Interest in Seeing a Product Grow from Start to Finish', 
      },

    ]
  },
  biotechnology:{

    questions:[                          
{ questiontext: '',selected:defaultSelected,  options:questionOptions},
{  questiontext: 'Are you good with animals, or interested in biological sciences?', selected:defaultSelected, options:questionOptions},
{  questiontext: 'Are you interested in environmental issues?', selected:defaultSelected,  options:questionOptions},  
{  questiontext: 'Do you enjoy measuring and mapping data and details?', selected:defaultSelected,  options:questionOptions},
{  questiontext: 'Do you have strong organizational skills?', selected:defaultSelected,  options:questionOptions},
{  questiontext: 'Do you get good grades in math and science?', selected:defaultSelected,  options:questionOptions},
],
    what_it_takes:[
{ 
  text: 'Strong Math and Science Skills',

},
{  
  text: 'Problem Solving Skills', 

},
{
 text: 'Working with Natural Resources, Such as Livestock, Plants, and Timber', 

},
{  text: 'Focus and Attention to Details', 
}
]
  },
  construction:{

        questions:[                          
    { questiontext: 'Do you enjoy working with tools?',selected:defaultSelected,  options:questionOptions},
    {  questiontext: 'Do you have a strong attention to detail?', selected:defaultSelected, options:questionOptions},
    {  questiontext: 'Do you have strong math, science, and computer skills?', selected:defaultSelected,  options:questionOptions},  
    {  questiontext: 'Do you have strong organizational skills?', selected:defaultSelected,  options:questionOptions},
    {  questiontext: 'Do you like building things or drawing?', selected:defaultSelected,  options:questionOptions},
    ],
        what_it_takes:[
    { 
      text: 'Strong Math, Science, and Time Management Skills',

    },
    {  
      text: 'Attention to Detail', 

    },
    {
     text: 'Ability to Follow Directions', 

    },
    {  text: 'Satisfaction in Teamwork'
    },
    {  text: 'Hands-on Work with Tools', 
    }
  ]
  },
  energy_and_technology:{

        questions:[                          
    { questiontext: 'Do you enjoy working on projects as part of a team?',selected:defaultSelected,  options:questionOptions},
    {  questiontext: 'Are you good at fixing, designing, or building things?', selected:defaultSelected, options:questionOptions},
    {  questiontext: 'Do you enjoy measuring and mapping data and details?', selected:defaultSelected,  options:questionOptions},  
    {  questiontext: 'Do you enjoy problem solving?', selected:defaultSelected,  options:questionOptions},
    {  questiontext: 'Do you thrive under pressure?', selected:defaultSelected,  options:questionOptions},
    ],
        what_it_takes:[
    { 
      text: 'Foundational Skills in Math & Technical Concepts',

    },
    {  
      text: 'Satisfaction in Teamwork', 

    },
    {
     text: 'Self-motivation and Attention to Detail', 

    },
    {  text: 'Leadership Skills and a Desire for Continuous Improvement'
    },
    {  text: 'Commitment to Life-long Learning', 
    }
  ]
  },
  healthcare:{

        questions:[                          
    { questiontext: 'Do you enjoy helping people?',selected:defaultSelected,  options:questionOptions},
    {  questiontext: 'Do you enjoy measuring and mapping data and details?', selected:defaultSelected, options:questionOptions},
    {  questiontext: 'Are you interested in how the human body works?', selected:defaultSelected,  options:questionOptions},  
    {  questiontext: 'Do you manage your own stress well?', selected:defaultSelected,  options:questionOptions},
    {  questiontext: 'Would you describe yourself as “patient” and “compassionate”?', selected:defaultSelected,  options:questionOptions},
    ],
        what_it_takes:[
    { 
      text: 'Strong math, science, and computer skills',

    },
    {  
      text: 'Attention to detail', 

    },
    {
     text: 'Desire to help others', 

    },
    {  text: 'Satisfaction in teamwork'
    }
  ]
  },
  manufacturing:{

        questions:[                          
    { questiontext: 'Do you have a strong attention to detail?',selected:defaultSelected,  options:questionOptions},
    {  questiontext: 'Do you have strong math, science, and computer skills?', selected:defaultSelected, options:questionOptions},
    {  questiontext: 'Do you enjoy problem solving?', selected:defaultSelected,  options:questionOptions},  
    {  questiontext: 'Do you enjoy working with tools?', selected:defaultSelected,  options:questionOptions},
    {  questiontext: 'Do you enjoy working on projects as part of a team?', selected:defaultSelected,  options:questionOptions},
    ],
        what_it_takes:[
    { 
      text: 'Strong Math and Science Skills',

    },
    {  
      text: 'Willingness to be Productive', 

    },
    {
     text: 'Satisfaction in Teamwork', 

    },
    {  text: 'Mastery of Complicated Machinery and Processes'
    },
    {  text: 'Problem-solving Skills', 
    }
  ]
  },
  public_works_and_safety:{

        questions:[                          
    { questiontext: 'Are you a good communicator?',selected:defaultSelected,  options:questionOptions},
    {  questiontext: 'Do you enjoy helping people?', selected:defaultSelected, options:questionOptions},
    {  questiontext: 'Do you enjoy problem solving?', selected:defaultSelected,  options:questionOptions},  
    {  questiontext: 'Do you follow the rules?', selected:defaultSelected,  options:questionOptions},
    {  questiontext: 'Do you manage your own stress well?', selected:defaultSelected,  options:questionOptions},
    ],
        what_it_takes:[
    { 
      text: 'Sound moral principles',

    },
    {  
      text: 'Loyalty to country and community', 

    },
    {
     text: 'Dependability and a positive attitude', 

    },
    {  text: 'Willingness to work long and varied hours'
    },
    {  text: 'Physical fitness', 
    }
  ]
  },
  transportation_and_logistics:{

        questions:[                          
    { questiontext: 'Are you good at operating tools and machinery?',selected:defaultSelected,  options:questionOptions},
    {  questiontext: 'Are you physically strong and coordinated?', selected:defaultSelected, options:questionOptions},
    {  questiontext: 'Do you have strong math, science, and computer skills?', selected:defaultSelected,  options:questionOptions},  
    {  questiontext: 'Do you have strong organizational skills?', selected:defaultSelected,  options:questionOptions},
    {  questiontext: 'Do you like to travel?', selected:defaultSelected,  options:questionOptions},
    ],
        what_it_takes:[
    { 
      text: 'Time management skills',

    },
    {  
      text: 'Attention to Detail', 

    },
    {
     text: 'Problem-Solving Skills', 

    },
    {  text: 'Customer Service'
    },
    {  text: 'Strong Work Ethic', 
    }
  ]
  },
  hospitality_tourism_and_retail:{

    questions:[                          
{ questiontext: 'Are you interested in people from different backgrounds?',selected:defaultSelected,  options:questionOptions},
{  questiontext: 'Can you multi-task and handle deadlines?', selected:defaultSelected, options:questionOptions},
{  questiontext: 'Can you study and master new subjects and skills?', selected:defaultSelected,  options:questionOptions},  
{  questiontext: 'Can you work extra hours and weekends?', selected:defaultSelected,  options:questionOptions},
{  questiontext: 'Do you enjoy helping people?', selected:defaultSelected,  options:questionOptions},
],
    what_it_takes:[
{ 
  text: 'Adaptability and a positive attitude',

},
{  
  text: 'Willingness to work hard as a team player', 

},
{
 text: 'Cooperation, diplomacy, and competitive nature', 

},
{  text: 'Ability to multi-task'
},
{  text: 'Organizational skills', 
},
{  text: 'Strong work ethic and competitive nature', 
},
{  text: 'Strong verbal and written communication skills', 
}
]
  },

}




