<template>
<div id="cardgame">
  <div class="info">
    <div><span class="label">Time:</span><span class="value">{{ time }} </span></div>
    <div><span class="label">Turns:</span><span class="value">{{ turns }} </span></div>
  </div>
  <div class="cards">
    <div class="card" v-for="(card,index) in cards" :key ="index" :class="{ flipped: card.flipped, found: card.found }" @click="flipCard(card)">
      <div class="back"></div>
      <div class="front" > <img  :src="require(`@/assets/games-logos/${card.image}-logo.jpg`)"  @click="displayEmployersPage(false,$event)" alt="front card logo"/></div>
    </div>
  </div>
  <div class="splash" v-if="showSplash">
    <div class="overlay"></div>
    <div class="content">
      <div class="title">You won!</div>
      <div class="score">Score: {{ score }}</div>
      <button class="newGame" @click="resetGame()">New game</button>
    </div>
  </div>
</div>
</template>
<script>
import _ from "lodash"

let CardTypes = [
	{ name: "content1", image: "alfa" },
	{ name: "content2", image: "eamc" },
	{ name: "content3", image: "forestry-works" },
	{ name: "content4", image: "frasle" },
	{ name: "content5", image: "rabren" },
	{ name: "content6", image: "oxford" },
	{ name: "content7", image: "rmc" },
    { name: "content8", image: "spire" },
	{ name: "content9",image: "tuskegee" },
	{ name: "content10", image: "webb" },
    { name: "content11", image: "usda" },
	{ name: "content12", image: "sponsor-amns" },
	{ name: "content13", image: "ag-pro" },
    { name: "content14", image: "alabama-green" },
	{ name: "content15", image: "kth" },
];

let shuffleCards = () => {
	let cards = [].concat(_.cloneDeep(CardTypes), _.cloneDeep(CardTypes));
	return _.shuffle(cards);
}
export default ({
name: "MatchingGame",



	
	data() {

        return{
		showSplash: false,
		cards: [],
		started: false,
		startTime: 0,
		turns: 0,
		flipBackTimer: null,
		timer: null,
		time: "--:--",
		score: 0
        }
},
	
	methods: {
		resetGame() {
			this.showSplash = false;
			let cards = shuffleCards();
			this.turns = 0;
			this.score = 0;
			this.started = false;
			this.startTime = 0;
			
			_.each(cards, (card) => {
				card.flipped = false;
				card.found = false;
			});
			
			this.cards = cards;
		},
		
		flippedCards() {
			return _.filter(this.cards, card => card.flipped);
		},
		
		sameFlippedCard() {
			let flippedCards = this.flippedCards();
			if (flippedCards.length == 2) {
				if (flippedCards[0].name == flippedCards[1].name)
					return true;
			}
		},
		
		setCardFounds() {
			_.each(this.cards, (card) => {
				if (card.flipped)
					card.found = true;
			});
		},
		
		checkAllFound() {
			let foundCards = _.filter(this.cards, card => card.found);
			if (foundCards.length == this.cards.length)
				return true;
		},
		
		startGame() {
			this.started = true;
			//this.startTime = moment();
			
		//	this.timer = setInterval(() => {
		//		this.time = moment(moment().diff(this.startTime)).format("mm:ss");
		//	}, 1000);
		},
		
		finishGame() {
			this.started = false;
			clearInterval(this.timer);
			let score = 1000// - (moment().diff(this.startTime, 'seconds') - CardTypes.length * 5) * 3 - (this.turns - CardTypes.length) * 5;
			this.score = Math.max(score, 0);
			this.showSplash = true;
		},
		
		flipCard(card) {
			if (card.found || card.flipped) return;
			
			if (!this.started) {
				this.startGame();
			}
			
			let flipCount = this.flippedCards().length;
			if (flipCount == 0) {
				card.flipped = !card.flipped;
			}
			else if (flipCount == 1) {
				card.flipped = !card.flipped;
				this.turns += 1;

				if (this.sameFlippedCard()) {
					// Match!
					this.flipBackTimer = setTimeout( ()=> {
						this.clearFlipBackTimer();
						this.setCardFounds();
						this.clearFlips();

						if (this.checkAllFound()) {
							this.finishGame();
						}	

					}, 200);
				}
				else {
					// Wrong match
					this.flipBackTimer = setTimeout( ()=> {
						this.clearFlipBackTimer();
						this.clearFlips();
					}, 1000);
				}
			}
		},
		
		clearFlips() {
			_.map(this.cards, card => card.flipped = false);
		},
		
		
		clearFlipBackTimer() {
			clearTimeout(this.flipBackTimer);
			this.flipBackTimer = null;
		}
	},
	
	created() {
		this.resetGame();
	}
});

</script>

<style scoped>
html {
  background-color: #292C33;
  color: White;
  font-size: 16px;
  font-family: 'Open Sans', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#cardgame {
  margin: 2em;
}

.info {
  text-align: center;
  padding-bottom: 1em;
  border-bottom: 1px solid #555;
}
.info > div {
  display: inline-block;
  width: 200px;
}
.info > div .label {
  margin-right: 5px;
}
.info > div .value {
  font-weight: bold;
}

.cards .card {
  position: relative;
  display: inline-block;
  width: 100px;
  height: 150px;
  margin: 1em 2em;
  -moz-transition: opacity 0.5s;
  -o-transition: opacity 0.5s;
  -webkit-transition: opacity 0.5s;
  transition: opacity 0.5s;
}
.cards .card .front, .cards .card .back {
  border-radius: 5px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: White;
  -moz-backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -moz-transition: -moz-transform 0.6s;
  -o-transition: -o-transform 0.6s;
  -webkit-transition: -webkit-transform 0.6s;
  transition: transform 0.6s;
  -moz-transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
.cards .card .back {
  background-image: url("https://eastalabamaworks.com/wp-content/uploads/east_logo.png");
  background-size: 90%;
  background-position: center;
  background-repeat: no-repeat;
  font-size: 12px;
}
.cards .card .front {
  -moz-transform: rotateY(-180deg);
  -ms-transform: rotateY(-180deg);
  -webkit-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
  background-size: 90%;
  background-repeat: no-repeat;
  background-position: center;
}
.cards .card.flipped .back, .cards .card.found .back {
  -moz-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
}
.cards .card.flipped .front, .cards .card.found .front {
  -moz-transform: rotateY(0deg);
  -ms-transform: rotateY(0deg);
  -webkit-transform: rotateY(0deg);
  transform: rotateY(0deg);
}
.cards .card.found {
  opacity: 0.3;
}

.splash {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.splash .overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
}
.splash .content {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 400px;
  height: 200px;
  margin: auto;
  text-align: center;
  background-color: rgba(51, 51, 51, 0.9);
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  -moz-box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.8);
  -webkit-box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.8);
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.8);
  padding: 1em;
}
.splash .content .title {
  font-size: 1.8em;
  padding: 0.5em;
}
.splash .content .score {
  padding: 0.5em;
}
.splash .content button {
  margin-top: 1.0em;
  background-color: #444;
  padding: 5px 20px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  border: 1px solid #555;
  color: White;
  font-size: 1.4em;
}

</style>
