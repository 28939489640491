//@file src/state/modules/dashboard/videos.js
import Vue from 'vue'
import Vuex from 'vuex'
import { vuexfireMutations } from 'vuexfire'

import createPersistedState from "vuex-persistedstate";
import user from "./user"
import auth from "./auth"
import assessment_form_store from './modules/assessmentforms/forms'
import assessment from './assessment'
import interaction_tracking_store from './modules/progresstracking/interactiontracking'
import awards_store from './modules/awards/awards'
Vue.use(Vuex)
//import firebase from "firebase";

const store = new Vuex.Store({
 
   // strict: process.env.NODE_ENV !== 'production',
    modules: {
        assessment_form_store:assessment_form_store,
        interaction_tracking_store:interaction_tracking_store,
        awards_store:awards_store,
         user,
         auth,
         assessment
          },  
          plugins: [createPersistedState()],

          mutations: {
            ...vuexfireMutations
          },
        
  })

  export default store 