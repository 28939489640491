<template>
<div class="robotTalk">
  <p> {{talkInfo.text.replace("##","")}}</p>
</div>
</template>


<script>
export default {
    name:"robotTalk",
    props:{
     talkInfo:{},
     nameforuser:String,
    
 
    },methods:{
      backToParentComponent(){
        //this.$parent.displayEmployersPage();
      },
    playVideo() {
      this.player.playVideo()
    },
    playing() {
      //console.log('we are watching!!!')
        this.$bvToast.toast(` You watched a video! You earned 10 points! `, {
          title: `Good Job!`,
          autoHideDelay: 5000,
          appendToast: true
        })
                 this.$store.commit('interaction_tracking_store/ADD_POINTS',{
        worldname:this.worldName,
        points:10
     
      
      
      });
      
     //            this.$store.commit('awards_store/ADD_BADGE',{
    //    worldname:this.worldName,
   //     points:10
     
      
      
   //   });
    },


 

    },  computed: {
    player() {
      return this.$refs.youtube.player
    }
  },


     data() {

      return {
        
			height: 500,
			options: {},
			playerReady: false,

      }
      
    }
}
</script>

<style>
/*
.robotTalk iframe {
    width: 100%;
    height: 280px;
}
.robotTalk {
    width: 86%;
    padding: 0px !important;
}*/
</style>