<template>
<section class="assessment" style="width:100vw">
<h1>Assessment</h1>
<button class="back-button sub-back" @click="backToParentComponent"><b-icon icon="chevron-left" aria-hidden="true" style="width: 42px; height: 42px; color: #de1a21 !important;"></b-icon></button>
  <b-row>
    <h2 style="margin-top:1ch" >Is this world a fit for you?</h2>
    <b-col cols="6" class="top-assess">   
        <b-form-valid-feedback :state="state">
        <span v-if="selectedYes>=3">Great news! The {{selectProduct(region,worldName)}} world is a good fit for you. Next, learn about some of the careers you can pursue.</span>
        <span class="not-perfect" v-else style="font-size: 1.3vw; line-height: 1.5vw;">The skills needed to succeed in the {{selectProduct(region,worldName)}} World don't seem to align with you. Review the skills below and continue learning about some of the careers you can pursue if you believe this is a good fit for you.</span>
      </b-form-valid-feedback>
        <div style="box-shadow: 6px 6px 12px #B8B9BE, -6px -6px 12px #fff; padding:15px; margin-top:10px;">
       <div style="color:black !important;" v-if="(state==true)">                  
          <p><b style="font-size: 1.2vw;">What It Takes :</b></p>
          <ul>
              <li v-for="(bulletpoint, index) in what_it_takes" :key="index" ><p style="margin-bottom:0px; font-size: 1vw;">{{bulletpoint.text}}</p></li><br/>
         </ul>
         </div>
        <b-row v-else>
          <b-col cols="10">
            <h4> What Skills are needed for {{selectProduct(region,worldName)}}?</h4> 
            <h5> Take the assessment and find out </h5>

          </b-col>
          <b-col  style="text-align:right;align-self:right">
            <b-icon icon="search" aria-hidden="true" style="width: 100px; height: 100px;"></b-icon>
          </b-col>
          </b-row>
        </div>
    </b-col>
    <b-col cols="6" id="formQs">
      <b-form-invalid-feedback :state="state"><p style="margin-bottom:5px; color:#de1a21;">Please select them all</p></b-form-invalid-feedback>
      <div class="formQ" v-for="(question, index) in questions" :key="index">
 
      <b-form-group :label="question.questiontext" v-slot="{ ariaDescribedby }">
      <b-form-radio-group
        :id="'question'+index"
        v-model="question.selected"
        :options="question.options"
        :aria-describedby="ariaDescribedby"
        :name="question.questiontext"
        buttons
      ></b-form-radio-group>
    </b-form-group>
      </div>
    </b-col>
  </b-row>
</section>
</template>


<script>
import goodFit_Quiz_Mixin from '../../shared_methods/goodFit_Quiz_mixin'
import worldNameMixin from '../../shared_methods/worldname_mixin'
import gamificationMixin from '../../shared_methods/gamificationMixin'
import { mapActions,mapGetters } from 'vuex'

export default {
    name:"Assessment",
    mixins: [worldNameMixin,goodFit_Quiz_Mixin,gamificationMixin],

    props:{

    worldName: String,
 
    },
    

  mounted() {
    const { currentUser } = this
    console.log(currentUser)
    console.log(this)
             this.questions.map(x => {

         x.selected=null;
          });

    if (currentUser) {
      // Hide the loading screen if currentUser
      // is available before the page renders
      //this.$q.loading.hide()
      //this.getDataFromApi(this.currentUser.id)
      //console.log(currentUser)
    }
  },

methods:{
    ...mapActions('assessment', ['test', 'loginUser']),
      backToParentComponent(){
        this.$parent.displayAssessment();
      },
    },
        computed: {
              ...mapGetters('user', ['currentUser']),

          selectedYes:{
            get() {  var selectedYesCounter=0;
          this.questions.map(x => {

              if (x.selected==true){
                selectedYesCounter+=1;
              
              }
          });
          return selectedYesCounter},
        set(newValue) { return newValue }
          },
      state() {
                 // //console.warn(this.questionOptions);

          var nullCounter=0;
         this.questions.map(x => {
          if (x.selected==null){
            nullCounter=0;
            nullCounter+=1;
          }

});
        return Boolean(nullCounter<1)
      }
        },  watch: {
    // whenever question changes, this function will run
    // eslint-disable-next-line no-unused-vars
    state: function (newState, oldState) {
      var quizTakenPreviously =this.$store.state.interaction_tracking_store[this.worldName].pagestate.suitabilityQuizTaken;
    //console.log(quizTakenPreviously)
      if(quizTakenPreviously==false){

       
      if (newState ==true){

        this.$store.commit('interaction_tracking_store/SET_TOPLEVELPROPERTY',{
        worldname:this.worldName,
      propertyid:'suitabilityQuizTaken',
       value:true
     
      
      
      }); 
      //console.log(oldState);
      var missionName= `${this.worldName}_TOOKQUIZ`
       //console.log(missionName)

  const missionAwarded= this.missionAwarded(this.$store.state,this.worldName,{metaprep:"",missionName:missionName})
          if(missionAwarded){
                  //console.log(missionName+" mission already awarded")
          }

          else{
                    this.$bvToast.toast(`Assessment Completed! You Earned +25 Points!`, {
          title: `Mission Success`,
          autoHideDelay: 5000,
          appendToast: true
        })
           //##INSERT FIREBASE INSERT
       this.$store.commit('awards_store/ADD_COMP_MISSIONS',{
           world:this.worldName,
        missionName:missionName,
      
     
      
     
       });
             this.$store.commit('assessments/ADD_ASSESS_ANSWER',{
           world:this.worldName,
        missionName:missionName,
      
     
      
     
       }); 
      this.$store.commit('assessments/ADD_ASSESS_POINTS',{
           world:this.worldName,
        missionName:missionName,
      
     
      
     
       });                    

                      this.$store.commit('awards_store/ADD_POINTS',{
       points:25
     
      
      
      });
          }

          

      }
   



      
      }
      else{
        
if (this.state==true){

      const badgeAwarded= this.badgeAwarded(this.$store.state,{badgeSrc:"aerospace",badgeName:"aerospace"})
                      
                if (badgeAwarded){
                  //console.log("already has it")
                }
                else{

                this.$store.commit('awards_store/ADD_BADGE',{
        badgeSrc:"manufacture",
        badgeName:'manufacture'
     
      
      
      });
// Use a shorter name for this.$createElement
        const h2 = this.$createElement
        // Increment the toast count
        this.count++
        // Create the message
        const vNodesMsg2 = h2(
     'p',
          { class: ['text-center', 'mb-0'] },
           [
            h2('b-spinner', { props: { type: 'grow', small: true } }),
            ` Took the quiz twice! way to be thorough! `,
            h2('b-spinner', { props: { type: 'grow', small: true } })
          ]
        )
        // Create the title
        const vNodesTitle2 = h2(
          'div',
          { class: ['d-flex', 'flex-grow-1', 'align-items-baseline', 'mr-2'] },
          [
            h2('strong', { class: 'mr-2' }, 'SECRET ACHIEVEMENT'),
            h2('small', { class: 'ml-auto text-italics' }, '5 minutes ago')
          ]
        )
        // Pass the VNodes as an array for message and title
        this.$bvToast.toast([vNodesMsg2], {
          title: [vNodesTitle2],
          solid: true,
          autoHideDelay: 3400,
          appendToast: true,

          variant: 'info'
        })
     // Use a shorter name for this.$createElement
        const h = this.$createElement
        // Increment the toast count
        this.count++
        // Create the message
        const vNodesMsg = h(
          'b-img',
          { props: { src: require('@/assets/badges/manufacture-badge.png') } 
          },
           
        )
        // Create the title
        const vNodesTitle = h(
          'div',
          { class: ['d-flex', 'flex-grow-1', 'align-items-baseline', 'mr-2'] },
          [
            h('strong', { class: 'mr-2' }, 'BADGE ACQUIRED'),
            h('small', { class: 'ml-auto text-italics' }, '5 minutes ago')
          ]
        )
        // Pass the VNodes as an array for message and title
        this.$bvToast.toast([vNodesMsg], {
          title: [vNodesTitle],
          solid: true,
          autoHideDelay: 3400,
          appendToast: true,

          variant: 'info'
        })
 
             
                }
   //##INSERT FIREBASE INSERT

      
}
      }

    }
  },
     data() {
     //console.log(process.env.VUE_APP_CURRENT_REGION)
   //     var defaultSelected=null;
        var quizInfo=this.selectQuizInfo(process.env.VUE_APP_CURRENT_REGION,this.worldName);
        //console.log(quizInfo.questions)
        //  var questionOptions=[
       //   { text: 'Yes', value: true },
         // { text: 'No', value: false },
   
 //       ]

      return {
                   region:process.env.VUE_APP_CURRENT_REGION.toLowerCase(),

                what_it_takes:quizInfo.what_it_takes,
                questions:quizInfo.questions
      }
    }
}
</script>

<style>
.assessment .d-block.valid-feedback span{
    font-size: 1.5vw;
    line-height: 1.9vw;
    font-family: 'Montserrat', Helvetica, Arial, sans-serif;
    text-align: center;
    font-weight: bolder;
}

.assessment .not-perfect{
  color: #de1a21 !important;
}


.assessment ul{
  margin-bottom: 0vh !important;
}

.assessment #formQs {
    padding-top: 2vh;
    overflow: scroll;
    overflow-x: hidden;
    box-shadow: inset 2px 2px 5px #B8B9BE, inset -3px -3px 7px #fff;
}

.assessment .btn-secondary:not(:disabled):not(.disabled).active {
  box-shadow: inset 2px 2px 5px #b8b9be, inset -3px -3px 7px #fff !important;
}

.b-icon{
  box-shadow: none !important;
}
.d-block {
    margin: 1vh 0;
}

.assessment .btn-secondary:not(:disabled):not(.disabled).active span{
 opacity: 0.8;
}

.assessment .formQ .btn{
  box-shadow: 6px 6px 12px #B8B9BE, -6px -6px 12px #fff;
  border: 0px solid white;
border-radius: 6px !important;
    background: #e5e5e5;
 margin-right: 10px;
 
}

.assessment .formQ .btn span{
  font-size: 14px;
  font-weight: bold;
  color:#de1a21;
}

.assessment section {
    width: 100vw;
}

.assessment p, .assessment h2, .assessment li, .assessment h4{
  font-size: 11px;
  color: black;
}

.assessment h2{
  font-size: 32px;
  width: 100%;
}

.assessment h4{
  font-size: 18px;
}

.assessment h5{
  font-size: 16px;
}

.assessment .btn-secondary:not(:disabled):not(.disabled):active, .assessment .btn-secondary:not(:disabled):not(.disabled).active, .assessment .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background: #e5e5e5;
}

 .assessment .back-btn {
    border: 0px solid white;
    background-color: #e5e5e5;
    box-shadow: 3px 3px 6px #b8b9be, -3px -3px 6px #fff;
    border-radius: 5px;   
}

</style>