
var worldInfoMixin = {
    methods: {
      selectRegionWorlds: function (regionName) {
        var worldsArrays={
            SAWDC:SAWDC_WORLDS_ARRAY,
            CAW:CAW_WORLDS_ARRAY,
            WAW:WAW_WORLDS_ARRAY,
            NAW:NAW_WORLDS_ARRAY,
            EAW:EAW_WORLDS_ARRAY,
            NCTEJW:NCTEJW_WORLDS_ARRAY

  
          }
          return worldsArrays[regionName]
      }
    }
  };


  export default worldInfoMixin;


/*########################################################################################################*/
/*########################################################################################################*/
/*########################################################################################################*/
var SAWDC_WORLDS_ARRAY=[
    {
        name: 'Aerospace',
        url:'aerospace_and_aviation',
      },
      {
        name:'Construction',
        url:'construction',
      },
      {
        name:'Energy',
        url:'energy',
    
      },
      {
        name:'Engineering',
        url:'engineering_and_design',
    
      },
      {
        name:'Healthcare',
        url:'healthcare',
    
      },
      {
        name:'it',
        url:'information_technology',
     },
      {
        name:  'Hospitality',
        url:'hospitality_and_tourism',
       },
  
      {
        name:'Manufacturing',
        url:'manufacturing',
     },
      {
        name:'Public Service',
        url:'public_service',
        },
      {
        name: 'Safety',
        url:'safety',
     },
      {
        name: 'Shipbuilding',
        url:'shipbuilding_and_repair',
     },
      {
        name: 'Su',
        url:'transportation_distribution_and_logistics',
      },
    ]


/*########################################################################################################*/
/*########################################################################################################*/
/*########################################################################################################*/
var WAW_WORLDS_ARRAY=[
    {
        name: 'agriculture',
        url:'agriculture',
      },
      {
        name:'cosmetology',
        url:'cosmetology',
      },
      {
        name:'construction',
        url:'construction',
    
      },
      {
        name:'education',
        url:'education',
    
      },
      {
        name:'energy_and_utilities',
        url:'energy_and_utilities',
    
      },
      {
        name:'engineering_and_environment',
        url:"engineering_and_environment",
     },   {
        name:  'healthcare',
        url:'healthcare',
       },
      {
        name:  'hospitality_and_tourism',
        url:'hospitality_and_tourism',
       },

      {
        name:'manufacturing_auto_logistics',
        url:'manufacturing_auto_logistics',
     },
      {
        name:'public_safety',
        url:'public_safety',
        },
      {
        name: 'transportation',
        url:'transportation',
     },
    
    ]

/*########################################################################################################*/
/*########################################################################################################*/
/*########################################################################################################*/
var EAW_WORLDS_ARRAY=[
    {
        name: 'agriculture_and_natural_resources',
        url:'agriculture_and_natural_resources',
      },
      {
        name:'automotive_and_manufacturing',
        url:'automotive_and_manufacturing',
      },
      {
        name:'communications_media_and_marketing',
        url:'communications_media_and_marketing',
    
      },
      {
        name:'construction',
        url:'construction',
    
      },
      {
        name:'energy_and_utilities',
        url:'energy_and_utilities',
    
      },
      {
        name:'engineering',
        url:"engineering",
     },   {
        name:  'healthcare',
        url:'healthcare',
       },
      {
        name:  'information_technology',
        url:'information_technology',
       },

      {
        name:'public_safety',
        url:'public_safety',
     },

      {
        name: 'transportation',
        url:'transportation_distribution_and_logistics',
     },
    
    ]
    /*########################################################################################################*/
/*########################################################################################################*/
/*########################################################################################################*/
var CAW_WORLDS_ARRAY=[

  {
    name:  'manufacturing',
    url:'manufacturing',
   },
     
   {
    name:'healthcare',
    url:'healthcare',
   },
   {
    name:'architecture_construction_and_utilities',
    url:'architecture_construction_and_utilities',
  },
  {
    name:'communication_and_information_technology',
    url:'communication_and_information_technology',

  },
  {
    name: 'transportation_distribution_and_logistics',
    url:'transportation_distribution_and_logistics',
 },
    {
        name: 'agriculture',
        url:'agriculture',
      },


      {
        name:'education_and_training',
        url:'education_and_training',
    
      },

      {
        name:'hospitality_and_tourism',
        url:'hospitality_and_tourism',
       },

      {
        name:'public_service',
        url:'public_service',
     },


    
    ]
    /*########################################################################################################*/
/*########################################################################################################*/
/*########################################################################################################*/
var NAW_WORLDS_ARRAY=[

      {
        name:'aerospace',
        url:'aerospace',
    
      },
      {
        name:'agriculture',
        url:'agriculture_food_and_natural_resources',
    
      },
      {
        name:'architecture',
        url:'architecture_and_construction',
    
      },

      {
        name:'Advanced Manufacturing',
        url:'modern_manufacturing',
      },
      {
        name:'automotive andmanufacturing',
        url:'modern_manufacturing_auto',
       },
      {
        name:  'business and finance',
        url:'business_and_finance',
       },

      {
        name:'public_service',
        url:'communications_media_and_marketing',
     },

      {
        name: 'Cybersecurity and Information Technology',
        url:'cybersecurity_and_information_technology',
     },
     {
        name: 'Education & Training',
        url:'education_and_training',
     },
     {
        name: 'Energy & Utilities',
        url:'energy_and_utilities',
     },
     {
        name: 'Healthcare',
        url:'healthcare',
     },
     {
        name: 'Hospitality & Tourism',
        url:'hospitality_and_tourism',
     },
     {
        name: 'Law, Public Safety, Correction and Security',
        url:'law_public_safety_and_corrections',
     },
     {
        name: 'Transportation,Distribution & Logistics',
        url:'transportation_distribution_and_logistics',
     },
    ]

 /*********************** */
 var NCTEJW_WORLDS_ARRAY=[
  {
    name:'agriculture',
    url:'agriculture',

  },
  {
    name:'biotechnology',
    url:'biotechnology',

  },
  {
    name:'Construction',
    url:'construction',
  },
  {
    name:'Energy',
    url:'energy_and_technology',

  },


  {
    name:'healthcare',
    url:'healthcare',
 },
  {
    name:  'Hospitality',
    url:'hospitality_tourism_and_retail',
   },

  {
    name:'Manufacturing',
    url:'manufacturing',
 },
  {
    name:'Public Works & Safety',
    url:'public_works_and_safety',
    },


  {
    name: 'Transportation & Logistics',
    url:'transportation_and_logistics',
  },
]

