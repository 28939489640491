<template>
<div>
<button class="back-button sub-back" @click="backToParentComponent"><b-icon icon="chevron-left" aria-hidden="true" style="width: 42px; height: 42px; color: #de1a21 !important;"></b-icon></button>
<div id="tabletIn">
  <div class="logoSec">
    <h3>{{entityInfo.entityName}} </h3>
    <img :id="index" :src="require(`@/assets/${region}-logos/${[entityInfo.entityLogo]}`)"  />
  </div>

    <b-tabs pills content-class="mt-3" class="form-tabs">
    <!--:title-link-class="linkClass(0)-->
    <b-tab  v-for="(program,index) in entityInfo.programs"  :key="index"   :class="{ active: index === 0}">
      <template #title>
            {{program.programName}} 
      </template>
      <h4 style="color:#0e2332"> {{program.programName}}</h4>
  
  <b-row fluid>
    
    <b-col>
      <div class="neu-body">
        <h2></h2>
            
            
            
  <p>{{program.description}} </p>
       <b-button class="neu-button" target="_blank" :href="program.link">Learn More</b-button>        
   
            
            
            
            
       <!--        <div v-if="currentExploreBlurb.mediaInfo.imageOrVideo=='video'">
               video
       <videoToggleWrapper :worldName='worldName' :key="1" :videoInfo='currentExploreBlurb.mediaInfo'/> 
              </div>
              <div v-else>
                image
                
  
                </div>

      
-->
      </div>
    </b-col>
    <b-col>
        <div class="videoSlide">
          <button class="left-vid-button" v-if="program.videos.length>1" @click='leftVideo'><b-icon icon="chevron-left" aria-hidden="true" style="width: 28px; height: 28px; color: #de1a21 !important;"></b-icon></button>
          <videoToggleWrapper :worldName='worldName' :key='videoIndex' :videoInfo='program.videos[videoIndex]'/> 
        <button class="right-vid-button" v-if="program.videos.length>1" @click='rightVideo'><b-icon icon="chevron-right" aria-hidden="true" style="width: 28px; height: 28px; color: #de1a21 !important;"></b-icon></button>
      </div>
    </b-col>
  </b-row>


  
    </b-tab>

   
  </b-tabs>
  


</div>
</div>
</template>


<script>
//import Assessment from './Assessment.vue';
import worldNameMixin from '../../../shared_methods/worldname_mixin'
//import exploreBlurbMixin from '../../shared_methods/exploreBlurbMixin'
import videoToggleWrapper from '../videoToggleWrapper';
//import careersInfoMixin from '../../shared_methods/careers_Info_Mixin';

export default {
  components: { videoToggleWrapper 
  }, data() {

               //console.log(exploreBlurb)
        return{ 
                     region:process.env.VUE_APP_CURRENT_REGION.toLowerCase(),
          ipadUp:true,
        assessToggle: true,
                   videoIndex:0,
                   tesxt:"awawawaw"


    }
    },   props:{
     entityInfo:{},
    worldName: String,
    },
   mixins: [worldNameMixin],
    name:"northPathways",


     methods: {
             backToParentComponent(){
        this.$parent.$parent.$parent.displayCentralPage(true,null)
       
      },
             rightVideo(){
          //console.log(this.videoIndex)
          if(this.videoIndex < (this.program.videos.length-1))
          {
          this.videoIndex ++

          }
          else
          {
              this.videoIndex=0;
          }
      },
      leftVideo(){
                    //console.log(this.videoIndex)

          if(this.videoIndex > 0)
          {
          this.videoIndex--;

          }
          else
          {
              this.videoIndex=this.program.videos.length-1;
          }
      },  ipadDown(){
     this.$parent.childComponentTriggerActive(this.$options.name)
     this.ipadUp=false;
     this.$parent.sidebar
    },
     },

}
</script>

<style scoped>

.naw #tabletIn #tabletIn .tabs {
    width: 53vw;
    display: inline-block;
    margin-top: 1vh;
    position: absolute;
    left: 24vw;
}

.nav-pills .nav-link {
    border-radius: 0.25rem;
    font-size: 0.8vw;
}

.logoSec {
    width: 23vw;
    display: inline-block;
    top: 8vh;
    position: absolute;
    left: 2vw;
}

.naw #tabletIn #tabletIn {
    position: absolute;
    top: 0vh;
    left: 0vw;
    width: 76vw;
}

.achievements, .mission {
    border: .0625rem solid #d1d9e6;
    margin-bottom: 1vh;
    padding: 10px 10px;
    box-shadow: inset 2px 2px 5px #b8b9be,inset -3px -3px 7px #fff!important;
    border-radius: .55rem;
}

.neu-body{
      border-radius: 15px;
    overflow: scroll;
    overflow-x: hidden;
    height: 31vh;
    box-shadow: inset 2px 2px 5px #b8b9be, inset -3px -3px 7px #fff;
    margin-bottom: 10px;
    padding: 1vw 1vh;
}

.neu-button {
    border: 0px solid white;
    width: 24vw;
    height: 6vh;
    border-radius: 150px;
    background: #e5e5e5;
    box-shadow: 6px 6px 12px #B8B9BE, -6px -6px 12px #fff;
    line-height: 14px;
    display: flex;
    font-family: 'Montserrat', Helvetica, Arial, sans-serif !important;
    font-weight: bold;
    align-items: center;
    justify-content: center;
    margin: 1vh 0vw;
    color: #de1a21 !important;
    font-size: 1em;
    text-transform: uppercase;
}

svg.bi-chevron-left.b-icon.bi {
    color: #de1a21 !important;
}

#tabletIn p{
    font-size: 1vw;
    margin:1vh 0;
}

.world-explore-image {
    width: 12vw;
    display: block;
    float: left;
    margin-right: 11px;
}


ul {
    list-style: none;
    margin-left:0;
    padding-left:0;
}


</style>