<template>
<div class="videoToggle">
  <div v-show="videoOver==false">
      <youtube v-if="videoInfo.videoType=='youtube'"  :video-id="videoInfo.videoId" ref="youtube" @playing="playing" @ended="ended"></youtube>
      <vimeo-player  v-if="(videoInfo.videoType=='vimeo') && (videoInfo.videoUrl.indexOf('review') == -1)"  ref="player" :video-url="videoInfo.videoUrl" @ready="onReady" @playing="playing" @ended="ended" :player-height="height"></vimeo-player>
      <vimeo-player  v-if="(videoInfo.videoType=='vimeo') && (videoInfo.videoUrl.indexOf('review') != -1)"  ref="player" :video-id="videoInfo.videoId" @ready="onReady" @playing="playing" @ended="ended" :player-height="height"></vimeo-player>

  </div>
<div v-show="videoOver==true">
                <img :src="require(`@/assets/video-end.jpg`)">

</div>
</div>
</template>


<script>
import { AmbientEventBus } from '@/eventbuses/ambientEventBus';

export default {
    name:"videoToggleWrapper",
    props:{
     videoInfo:{},
    worldName: String,
 
    },methods:{
      backToParentComponent(){
        this.$parent.displayEmployersPage();
      },
    playVideo() {
      this.player.playVideo()
            //AmbientEventBus.$emit('videoplay', this.videoInfo.videoType);

    },
    playing() {
      console.log('we are watching!!!')
                  AmbientEventBus.$emit('videoplay', this.videoInfo.videoType);

    },
    ended() {
      //console.log('this is the end!!!')
      this.videoOver=true;
                        AmbientEventBus.$emit('videoend', this.videoInfo.videoType);

      //console.log(this.videoInfo);
                     this.$store.commit('interaction_tracking_store/ADD_VIDEOWATCHED',{
        worldname:this.worldName,
      
      videoId:this.videoInfo.type +":"+this.videoInfo.videoId,
      
      });
      


        this.$bvToast.toast(`You watched a video! You just earned +10pts`, {
          title: `Good Job!`,
          autoHideDelay: 5000,
          appendToast: true
        })
   
                            this.$store.commit('awards_store/ADD_POINTS',{
       points:10
     
      
      
      });
   //##INSERT FIREBASE INSERT
   
  //payload.worldname
  //pagestate[payload.propertyid]
  // = payload.value;

    },
    onReady() {
			this.playerReady = true
		},
		play () {
			//console.log('we are watching VIMEO!!!')
		}

 

    },  computed: {
    player() {
      return this.$refs.youtube.player
    }
  },
mounted(){
  this.videoOver=false;
},

     data() {

      return {
        videoOver:false,
			height: 500,
			options: {},
			playerReady: false,
                 region:process.env.VUE_APP_CURRENT_REGION.toLowerCase(),


      }
      
    }
}
</script>

<style>
.videoToggle iframe {
    width: 100%;
    height: 38vh;
}
.videoToggle {
    width: 100%;
    padding: 0px !important;
}
</style>