/** Class representing a User. */
export default class User {
  /**
     * Create a user.
     * @param {String} id - Place holder for a unique Firebase id.
     * @param {String} backgroundPhoto - A generated URL from Google Storage.
     * @param {String} email - A valid email.
     * @param {String} fullName - The user's full name.
     * @param {String} mobile - the user's mobile number.
     * @param {String} profilePhoto - A generated URL from Google Storage.
     * @param {String} role - A generated URL from Google Storage.

     */
  id = ''
  backgroundPhoto = ''
  email = ''
  familyName = ''
  givenName = ''
  mobile = ''
  profilePhoto = ''
  role = ''
  employmentdata = {
    schoolname: '',
    county: '',
    state: ''
  }

  /**
   * @param  {Object} args - User args
   */
  constructor(args) {
    Object.keys(args).forEach((v) => {
      this[v] = args[v]
    })

    return {
      ...this
    }
  }
}
