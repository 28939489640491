/*
  Remember, we have access to our Vue instance not using
  an arrow function in our actions file. This allows us
  to create a scoped reference to our firebaseService - $fb
  That was assigned to the Vue instance earlier in our serviceConnection
  boot file.
*/

import { firestoreAction } from 'vuexfire'
//import User from '../../models/User'
import { docRef } from '../../_services/firebase/db.js'
import { collectionRef } from '../../_services/firebase/db.js'

//import { Notify } from 'quasar'
//import classroom from '../classroom/index.js'
export const test = async function($root,submissionData){
console.log(submissionData)
console.log(submissionData.currentUser.id)
var formidkey= submissionData.formid
console.log('wow')
  var answers = await collectionRef('studentAnswers')
  .where('studentid', '==', submissionData.currentUser.id) 
  //.where()
  .get()
  .then((querySnapshot) => {
    console.log(querySnapshot)
    var docarray = []
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
        console.log(doc.id, " => ", doc.data());
        var docplus = doc.data()
        docplus.refid= doc.id
      docarray.push(docplus)
    })
    return docarray
  })
  .catch((error) => {
    console.log('Error getting document:', error)
  })
  console.log("submissionData q1")
console.log(submissionData.q1)
  var newInfo =answers[0].worlds_info
  try{

  
  newInfo[formidkey].answers={q1:submissionData.q1,q2:submissionData.q2,q3:submissionData.q3,q4:submissionData.q4}
  }
  catch{
     newInfo[formidkey]= {}
     newInfo[formidkey].answers={q1:submissionData.q1,q2:submissionData.q2,q3:submissionData.q3,q4:submissionData.q4}

  }/*
  try{

  
    newInfo.push={q1:submissionData.q1,q2:submissionData.q2,q3:submissionData.q3,q4:submissionData.q4}
    }
    catch{
       newInfo[formidkey]= {}
       newInfo[formidkey].answers={q1:submissionData.q1,q2:submissionData.q2,q3:submissionData.q3,q4:submissionData.q4}
  
    }*/
  console.log(newInfo)
  //[`first.${nestedkey}`]: "12345"
  docRef("studentAnswers",answers[0].refid).update({
    'worlds_info': newInfo
  })
return answers[0]


}

export const worldVisit = async function($root,submissionData){
  console.log(submissionData)
  console.log(submissionData.currentUser.id)
  var formidkey= submissionData.formid
  console.log('wow')
    var answers = await collectionRef('studentAnswers')
    .where('studentid', '==', submissionData.currentUser.id) 
    //.where()
    .get()
    .then((querySnapshot) => {
      console.log(querySnapshot)
      var docarray = []
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
          console.log(doc.id, " => ", doc.data());
          var docplus = doc.data()
          docplus.refid= doc.id
        docarray.push(docplus)
      })
      return docarray
    })
    .catch((error) => {
      console.log('Error getting document:', error)
    })
  
    var newInfo =answers[0].worlds_info
    try{
  
    
    newInfo[formidkey].answers={q1:submissionData.q1,q2:submissionData.q2,q3:submissionData.q3,q4:submissionData.q4}
    }
    catch{
       newInfo[formidkey]= {}
       newInfo[formidkey].answers={q1:submissionData.q1,q2:submissionData.q2,q3:submissionData.q3,q4:submissionData.q4}
  
    }
    try{
  
    
      newInfo.push={q1:submissionData.q1,q2:submissionData.q2,q3:submissionData.q3,q4:submissionData.q4}
      }
      catch{
         newInfo[formidkey]= {}
         newInfo[formidkey].answers={q1:submissionData.q1,q2:submissionData.q2,q3:submissionData.q3,q4:submissionData.q4}
    
      }
    console.log(newInfo)
    //[`first.${nestedkey}`]: "12345"
    docRef("studentAnswers",answers[0].refid).update({
      'worlds_info': newInfo
    })
  return answers[0]
  
  
  }
export const test2 = async function($root,currentUserId){
  console.log(currentUserId)
  var nestedkey =  "aerospace_and_aviation2"
  console.log('wow')
    var answers = await collectionRef('studentAnswers')
    .where('studentid', '==', currentUserId)
    //.where()
    .get()
    .then((querySnapshot) => {
      console.log(querySnapshot)
      var docarray = []
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        var docplus = doc.data()
        docplus.refid= doc.id
      docarray.push(docplus)
      })
      return docarray
    })
    .catch((error) => {
      console.log('Error getting document:', error)
    })
    var newInfo =answers[0].worlds_info
    try{

    
    newInfo[nestedkey].answers={test1:"test",test2:"test"}
    }
    catch{
       newInfo[nestedkey]= {}
       newInfo[nestedkey].answers={test1:"test",test2:"test"}

    }
    console.log(newInfo)
    //[`first.${nestedkey}`]: "12345"
    docRef("studentAnswers",answers[0].refid).update({
      'worlds_info': newInfo
    })
  return answers[0]
  
  
  }
export const submitNewWorld = async function($root,currentUserId){
  console.log(currentUserId)
  console.log('wow')
    var answers = await collectionRef('studentAnswers')
    .where('studentid', '==', 'GRPq0hIuobMV6wqYdBGYFXTeaMl2') 
    .get()
    .then((querySnapshot) => {
      console.log(querySnapshot)
      var docarray = []
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
          console.log(doc.id, " => ", doc.data());
        docarray.push(doc.data())
      })
      return docarray
    })
    .catch((error) => {
      console.log('Error getting document:', error)
    })
    console.log(answers[0])
  return answers[0]
  
  
  }
  export const submitNewAssessment = async function($root,submissionData){
    console.log(submissionData.relevantField)
    
    console.log('wow')
      var answers = await collectionRef('studentAnswers')
      .where('studentid', '==', submissionData.currentUser.id) 
      .get()
      .then((querySnapshot) => {
        console.log(querySnapshot)
        var docarray = []
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
            console.log(doc.id, " => ", doc.data());
          docarray.push(doc.data())
        })
        return docarray
      })
      .catch((error) => {
        console.log('Error getting document:', error)
      })
    return answers[0]
    
    
    }
    
    export const submitNewMission= async function($root,currentUserId){
      console.log(currentUserId)
      console.log('wow')
        var answers = await collectionRef('studentAnswers')
        .where('studentid', '==', 'GRPq0hIuobMV6wqYdBGYFXTeaMl2') 
        .get()
        .then((querySnapshot) => {
          console.log(querySnapshot)
          var docarray = []
          querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
              console.log(doc.id, " => ", doc.data());
            docarray.push(doc.data())
          })
          return docarray
        })
        .catch((error) => {
          console.log('Error getting document:', error)
        })
      return answers[0]
      
      
      }
    export const submitNewAccomplishment= async function($root,currentUserId){
      console.log(currentUserId)
      console.log('wow')
        var answers = await collectionRef('studentAnswers')
        .where('studentid', '==', 'GRPq0hIuobMV6wqYdBGYFXTeaMl2') 
        .get()
        .then((querySnapshot) => {
          console.log(querySnapshot)
          var docarray = []
          querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
              console.log(doc.id, " => ", doc.data());
            docarray.push(doc.data())
          })
          return docarray
        })
        .catch((error) => {
          console.log('Error getting document:', error)
        })
      return answers[0]
      
      
      }
/**
 * @param {Object} $root - Place holder argument(Vuex store) to allow data to be passed
 *  as second argument.
 * @param data.email - User's email
 * @param data.password - User's password
 * @returns {function} - Firebase services function:
 * src/services/firebase/email.js > createUserWithEmail

export const createNewUser = async function ($root, data) {
  const $fb = this.$fb
  console.log('Data')
  console.log(data)
  const { email, password } = data
  console.log(email)
  console.log(password)
  const fbAuthResponse = await $fb.createUserWithEmail(email, password)
  console.log(fbAuthResponse.user.uid)
  const id = fbAuthResponse.user.uid
  const userRef = docRef('users', id)

  return addUserToUsersCollection(data, userRef)
}
export const createAnswer = async function ($root, data) {
  const $fb = this.$fb
  console.log('Data')
  console.log(data)
  const { email, password } = data
  console.log(email)
  console.log(password)
  const fbAuthResponse = await $fb.createUserWithEmail(email, password)
  console.log(fbAuthResponse.user.uid)
  const id = fbAuthResponse.user.uid
  const userRef = docRef('users', id)

  return addUserToUsersCollection(data, userRef)
}
 */
/**
 * @param {Object} $root - Place holder argument(Vuex store) to allow data to be passed
 *  as second argument.
 * @param data.email - User's email
 * @param data.password - User's password
 * @returns {function} - Firebase services function:
 * src/services/firebase/email.js > loginWithEmail
 */
export const loginUser = async function ($root, data) {
  console.log("login")

  const $fb = this.$fb
  const { email, password } = data
  //var successLoginEmail=
  // console.log(successLoginEmail)
  // console.log(successLoginEmail.i)
  // if (successLoginEmail){
  //   console.log('succeeded')
  // const userRef = docRef('users', id)

  //this.commit('user/setCurrentUserData', {role:"organize"}, { root: true })

  // }
  console.log(data)
  var userstuff= $fb.loginWithEmail(email, password)
  console.log(userstuff)
  return userstuff
}

/**
 * @returns {function} - Firebase services function:
 * src/services/firebase/email.js > logoutUser
 */
export const logoutUser = async function ({ commit }) {
  try {
    await firestoreAction(({ unbindFirestoreRef }) => {
      unbindFirestoreRef('currentUser')
    })
    commit('user/setCurrentUserData', null, { root: true })
    await this.$fb.logoutUser()
    //commit('common/setDrawerOpen', false, { root: true })
    // commit('user/setNullUserOnLogout', this)
  } catch (err) {

    console.log(err)
    //Notify.create({
    //  type: 'webapp_error',
    //  message: `An error as occured [logoutUser]: ${err}`
   // })
  }
}

export function routeUserToAuth() {
  this.$router.push({
    path: '/landing'
  })
}
