import {firestore} from "../firebase";
const sawdcdb = firestore.collection("/sawdc-emails");
const eastdb = firestore.collection("/eastern-emails");
const westdb= firestore.collection("/western-emails");
const northdb = firestore.collection("/northern-emails");
const centraldb = firestore.collection("/central-emails")

const collectionObject={
  sawdc:sawdcdb,
  eaw:eastdb,
  waw:westdb,
  naw:northdb,
  caw:centraldb
}
class EmailEntryDataService {
    getAll(region) {
      return collectionObject[region];
    }
  
    create(emailEntry,region) {

      return collectionObject[region].add(emailEntry);
    }
  
    update(id, value,region) {
      return collectionObject[region].doc(id).update(value);
    }
  
    delete(id,region) {
      return collectionObject[region].doc(id).delete();
    }
  }
  
  export default new EmailEntryDataService();