<template>
<div>
  <button v-if="ipadUp && !childComponentToggle"  :class="ipadUp ? 'back-button show-button' : 'back-button hid-button'"  @click="ipadDown"><b-icon icon="chevron-down" aria-hidden="true" style="width: 42px; height: 42px; color: #de1a21 !important;"></b-icon></button>
<div id="tabletIn" class="educationTab">
<h1>Education</h1>
<b-tabs content-class="mt-3" fill>
 
   <b-tab v-if="(region=='caw'||region=='waw'|| region =='nctejw') && centralExtra[this.worldName].length > 0 " title="Programs" class="Programs" active>
            <b-row  v-if="childComponentToggle==false" >
              <b-col @scroll="handleScroll" class="scrollLogos" cols="6">
                <div class="logoCont" v-for="(employer, index) in centralExtra[this.worldName]" :key="index"   :id="index" >
                      <img :id="index" :src="require(`@/assets/${region}-logos/${employer.employerLogo}`)"  @click="displayCentralPage(false,$event)" alt="logo of employer in program"/>
                </div>
                <b-icon v-show="!hasScrolledToBottom" class="scroll-ind" icon="chevron-down" aria-hidden="true"></b-icon>
              </b-col>
              <b-col cols="6" style="">
                <img :src="require(`@/assets/${region}-images/${centralExtra.rightImageSrc}.jpg`)" alt="career image">

              </b-col>
            </b-row>
            <b-row v-if="childComponentToggle==true">
                <CentralExtraPage :worldName='worldName' :employerInfo='centralExtra[this.worldName][this.centralStuffIndex]'/>
            </b-row>
     </b-tab>
        <b-tab v-if="region=='naw'" title="Pathways" class="Pathways" active>
            <b-row  v-if="childComponentToggle==false" >
              <b-col @scroll="handleScroll" class="scrollLogos" cols="12">
                <div class="logoCont" v-for="(pathway, index) in nawPathways[this.worldName].pathwayEntities" :key="index"   :id="index" >
                      <img :id="index" :src="require(`@/assets/${region}-logos/${[pathway.entityLogo]}`)"  @click="displayCentralPage(false,$event)"/>
                <p>{{pathway.entityName}}</p>
                </div>
                <b-icon v-show="!hasScrolledToBottom" class="scroll-ind" icon="chevron-down" aria-hidden="true"></b-icon>
              </b-col>
     
            </b-row>
            <b-row v-if="childComponentToggle==true">
                <northPathwaysPage :worldName='worldName' :entityInfo='nawPathways[this.worldName].pathwayEntities[this.centralStuffIndex]' />
            </b-row>
     </b-tab>
     <div v-if="region!='naw'">
      <b-tab v-for="(tab,index) in educationTabs" :key="index" :title="tab.tabName" :class="tab.tabName" :active="(region!= 'caw') && (tab.tabName=='Pathways')">
          <div class="educ-tabs" v-for="(cardRow,index) in tab.cardRows" :key='index'>
            <b-col>
              <b-card>
                <img :src="require(`@/assets/${region}-logos/${cardRow[0].cardImage}`)">
                  <b-card-title>
                    {{cardRow[0].cardTitle}}
                  </b-card-title>
                  <b-card-text>
                    {{cardRow[0].cardText}}
                  </b-card-text>
                  <b-button :href="cardRow[0].cardLink" class="card-link neu-button" target="_blank" rel="noopener noreferrer" > {{cardRow[0].customButtonText ||"Visit Website"}} </b-button>
              </b-card>
            </b-col>
            <b-col v-if="cardRow.length>1">     
              <b-card>
                <img :src="require(`@/assets/${region}-logos/${cardRow[1].cardImage}`)">
                <b-card-title>
                    {{cardRow[1].cardTitle}}
                  </b-card-title>
                <b-card-text>
                {{cardRow[1].cardText}}
                </b-card-text>
                <b-button :href="cardRow[1].cardLink" class="card-link neu-button" target="_blank" rel="noopener noreferrer" > Visit Website </b-button>   
              </b-card>
            </b-col>
          </div>
     </b-tab>
     </div>

  </b-tabs>
</div>
</div>
</template>


<script>
import worldNameMixin from '../../shared_methods/worldname_mixin'
import educationInfoMixin from '../../shared_methods/educationInfoMixin';
import CentralExtraPage from './subcomponents/centralextrapage.vue';
import employersInfoMixin from '../../shared_methods/employersInfoMixin';
import centralProgramsMixin from '../../shared_methods/centralProgramsMixin';
import northPathwaysPage from './subcomponents/northPathwaysPage.vue';
export default {
    name:"Education",
          mixins: [worldNameMixin,educationInfoMixin,employersInfoMixin,centralProgramsMixin],
   props:{
       worldName:String
     },
     components:{
       CentralExtraPage,
       northPathwaysPage
     },

    
    methods:{    handleScroll(el) {
        if((el.target.offsetHeight + el.target.scrollTop) >= el.target.scrollHeight) {
          this.hasScrolledToBottom = true;
        } 
        else{this.hasScrolledToBottom=false;}   },
          ipadDown(){
     this.$parent.childComponentTriggerActive(this.$options.name)
     this.ipadUp=false;
     this.$parent.sidebar
    }   , displayCentralPage(fromBackButtonBool,event) {
    this.childComponentToggle= !this.childComponentToggle;

 if (fromBackButtonBool==false){
 
     //console.log(this.centralExtra[this.centralStuffIndex]);
    this.centralStuffIndex=event.target.id;
    //this.$parent.handheldView='Assessment';//='Education'
     this.$store.commit('interaction_tracking_store/ADD_ELEMENTINTERACTION',{
        worldname:this.worldName,
        elementstate:true,
        elementid:event.target.id,
        elementname:event.target.class,
        interactiontype:"click",
        component:this.name,
        isSidebarComponent:false
      });
     }
    },
    },
      data() {
             var tabsInfo= this.selectEducationTabsInfo(process.env.VUE_APP_CURRENT_REGION,this.worldName)
             console.log(tabsInfo)
             if(process.env.VUE_APP_CURRENT_REGION == "CAW" ||process.env.VUE_APP_CURRENT_REGION == "WAW"||process.env.VUE_APP_CURRENT_REGION == "NAW" ||process.env.VUE_APP_CURRENT_REGION == "NCTEJW"  ){
             var centralStuff= this.selectProgramsInfo(process.env.VUE_APP_CURRENT_REGION,this.worldName)
             console.log(centralStuff[this.worldName]);

             }
      return {
                   region:process.env.VUE_APP_CURRENT_REGION.toLowerCase(),

        ipadUp:true,
        educationTabs:tabsInfo,
        nawPathways:centralStuff??null,
        nawIndex:0,
        centralStuffIndex:0,
        centralExtra:centralStuff??null,
        childComponentToggle:false,
        hasScrolledToBottom:false,
                swiper1Options: {
                          slidesPerView: 1,
          spaceBetween: 30,
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
          }
        },
                swiper2Options: {
                        slidesPerView: 4,
          spaceBetween: 30,  
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
          }
        },

      }
       }
}
</script>

<style>
.naw #tabletIn #tabletIn {
    position: absolute;
    top: 6vh;
    left: 1vw;
}

.naw #tabletIn .logoCont button,
.nctejw #tabletIn .logoCont button{
  border: 0px solid white;
    width: 100%;
    max-width: 20vw;
    height: 5vh;
    border-radius: 150px;
    background: #e5e5e5;
    box-shadow: 6px 6px 12px #B8B9BE, -6px -6px 12px #fff;
    line-height: 14px;
    display: flex;
    font-family: 'Montserrat', Helvetica, Arial, sans-serif !important;
    font-weight: bold;
    align-items: center;
    justify-content: center;
    margin: 2vh 0vw;
    color: #de1a21 !important;
    font-size: 1em;
    text-transform: uppercase;
}

.educationTab .neu-button {
    border: 0px solid white;
    width: 100%;
    max-width: 20vw;
    height: 5vh;
    border-radius: 150px;
    background: #e5e5e5;
    box-shadow: 6px 6px 12px #B8B9BE, -6px -6px 12px #fff;
    line-height: 14px;
    display: flex;
    font-family: 'Montserrat', Helvetica, Arial, sans-serif !important;
    font-weight: bold;
    align-items: center;
    justify-content: center;
    margin: 2vh 0vw;
    color: #de1a21 !important;
    font-size: 1em;
    text-transform: uppercase;
}
h4{
  color: #14424d !important;
}

.educationTab .tabs {
    margin-top: 6vh;
}

.educationTab h4 {
    font-size: 1.1rem;
}

.educationTab .neu-button:hover {
  box-shadow: inset 2px 2px 5px #B8B9BE, inset -3px -3px 7px #fff;
  background: #e5e5e5;
}

.educationTab .card{
  border: 0px solid white;
  box-shadow: inset 2px 2px 5px #B8B9BE, inset -3px -3px 7px #fff;
  background-color: rgba(256,256,256,.4) !important;
}
.sawdc .Pathway .card {
   width: 63vw;
    left: 7vw;
}
#healthcare .sawdc .educationTab {
    top: 15vh !important;
}

#healthcare .sawdc .educationTab .nav-fill .nav-item {

    margin-bottom: 1vh;
}

#healthcare .sawdc .educationTab .nav-tabs .nav-link {
    font-size: 0.6vw;
}

#healthcare .sawdc .educationTab .educ-tabs {
    top: 11vh;
    position: absolute;
}
.Pathway h4 {
    position: absolute;
      top: -1000px;
}

.Pathway .card-body{
  padding: 0px;
}

.educationTab p{
  font-size: 11px;
}

.educ-tabs{
  float: left;
  width: 50%;
  max-width: 33%;
  max-height: 100vh;
}

.educ-tabs .col{
  max-width: 100%;
}

.educationTab .nav-tabs .nav-link {
    color:#de1a21; 
    transition: all .2s ease;
    box-shadow: 6px 6px 12px #b8b9be, -6px -6px 12px #fff;
    border: 1px solid #d1d9e6;
        margin-right: 13px;
    font-size: 11px;
    border-radius: 10px !important;
    font-weight: bold;
}

.educationTab .nav-tabs .nav-link.active {
    box-shadow: inset 2px 2px 5px #b8b9be, inset -3px -3px 7px #fff;
}

 
.educationTab .Pathway .card-text,
.educationTab .Pathway button {
  display: none;
}

.Programs img, .Colleges img, .Training img {
    margin: 0 auto;
}

#transportation_distribution_and_logistics .Programs .scroll-ind{
  display: none;
}

.eaw .Programs .educ-tabs {
    float: left;
    width: 41%;
    max-width: 25%;
    max-height: 100vh;
}


.educationTab .nav-tabs {
    margin-top: -6vh;
    position: absolute;
    border:0px solid white;
}


.educationTab .tab-pane .container{
  padding: 0 0px;
}

.scrollLogos.col-6 {
    height: 37vh;
    padding: 2vh 1vw;
    display: inline-block;
    overflow: scroll;
    overflow-x: hidden
    
    } 

.caw .scrollLogos img, .waw .scrollLogos img,  .naw .scrollLogos img,  .nctejw .scrollLogos img{
    width: 10vw;
    float: left;
    margin-right:2vw;
    margin-bottom: 2vh;
}
.logoCont img {
    box-shadow: 6px 6px 12px #b8b9be, -6px -6px 12px #fff;
    border-radius: 15px;
}
.naw .scrollLogos .logoCont, .nctejw .scrollLogos .logoCont {
    width: 10vw;
    display: inline-block;
    margin: 0 1vw 0.5vh;
    height: 15vh;
    margin-top: 3vh;
}
.naw .logoCont p, .nctejw .logoCont p {
    font-size: 0.9vw;
    font-weight: 600;
}
</style>