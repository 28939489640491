<template>
<div>

    
<h1>{{selectProduct(region,worldName)}} central Extra </h1>
<button class="back-button sub-back" @click="backToParentComponent"><b-icon icon="chevron-left" aria-hidden="true" style="width: 42px; height: 42px; color: #de1a21 !important;"></b-icon></button>
  <b-row>
    <b-col cols="6" class="neu-body">
        <h2>{{employerInfo.employername}}</h2>
        <img width='50%' height='auto' style='margin-bottom:10px' :src="require(`@/assets/${region}-logos/${employerInfo.employerLogo}`)" alt="employers logo">
        <div v-if="employerInfo.multilink==false">
        <b-button class="neu-button" :href="employerInfo.employersite" target="_blank" rel="noopener noreferrer" >Learn More </b-button>
        </div>
        <div v-for="(button,index) in employerInfo.links" :key="index" v-else>
        <b-button class="neu-button"  :href="button.url" target="_blank" rel="noopener noreferrer" > {{button.title}} </b-button>

          </div>
        <p>{{employerInfo.employerp1}}</p>
        <p>{{employerInfo.employerp2}}</p>
        <p>{{employerInfo.employerp3}}</p>
    </b-col>
    <b-col v-if="employerInfo.images.length>0">
      </b-col>
    <b-col cols="6" v-if="employerInfo.videos.length>0">
        <div class="videoSlide">
          <button class="left-vid-button" v-if="employerInfo.videos.length>1" @click='leftVideo'><b-icon icon="chevron-left" aria-hidden="true" style="width: 28px; height: 28px; color: #de1a21 !important;"></b-icon></button>
          <videoToggleWrapper :worldName='worldName' :key='videoIndex' :videoInfo='employerInfo.videos[this.videoIndex]'/> 
        <button class="right-vid-button" v-if="employerInfo.videos.length>1" @click='rightVideo'><b-icon icon="chevron-right" aria-hidden="true" style="width: 28px; height: 28px; color: #de1a21 !important;"></b-icon></button>
      </div> </b-col>
  </b-row>
</div>
</template>


<script>
import worldNameMixin from '../../../shared_methods/worldname_mixin'

import videoToggleWrapper from '../videoToggleWrapper';
export default {
    name:"CentralExtraPage",
 mixins: [worldNameMixin],
    props:{
     employerInfo:{},
    worldName: String,
    },methods:{
      backToParentComponent(){
        this.$parent.$parent.$parent.displayCentralPage(true,null)
       
      },
      rightVideo(){
          //console.log(this.videoIndex)
          if(this.videoIndex < (this.employerInfo.videos.length-1))
          {
          this.videoIndex ++

          }
          else
          {
              this.videoIndex=0;
          }
      },
      leftVideo(){
                    //console.log(this.videoIndex)

          if(this.videoIndex > 0)
          {
          this.videoIndex--;

          }
          else
          {
              this.videoIndex=this.employerInfo.videos.length-1;
          }
      }
    },components:{
        videoToggleWrapper
       
    },

  
     data() {

      return {
                videoIndex:0,
                region:process.env.VUE_APP_CURRENT_REGION.toLowerCase(),


       

      }
      
    }
}
</script>

<style scoped>
.neu-body{
  padding:10px;
  border-radius: 15px;
   overflow: scroll;
   overflow-x: hidden;
    height: 40vh;
  box-shadow: inset 2px 2px 5px #B8B9BE, inset -3px -3px 7px #fff;
}
.neu-button {
    border: 0px solid white;
    width: 44%;
    margin-top: 10px;
    margin-bottom:10px;
    height: 7vh;
    border-radius: 150px;
    background: #e5e5e5;
    box-shadow: 6px 6px 12px #b8b9be, -6px -6px 12px #fff;
    line-height: 14px;
    display: flex;
    font-family: 'Montserrat', Helvetica, Arial, sans-serif !important;
    font-weight: bold;
    align-items: center;
    justify-content: center;
    color: #de1a21 !important;
    font-size: 1em;
    text-transform: uppercase;

}
.card {
    width: 26vw;
}
h2 {
    position: fixed;
    top: 11vh;
    width: 80vw;
    text-align: center;
}
p{
  font-size: 12px;
}

.left-vid-button {
    position: absolute;
    top: 17vh;
    left: 0.5vw;
    box-shadow: 6px 6px 12px #b8b9be, -6px -6px 12px #fff;
    border-radius: 150px;
    border: 0px;
    width: 3vw;
    height: 5vh;
}
.right-vid-button {
    position: absolute;
    top: 17vh;
    right: -0.5vw;
    box-shadow: 6px 6px 12px #b8b9be, -6px -6px 12px #fff;
    border-radius: 150px;
    border: 0px;
    width: 3vw;
    height: 5vh;
}

.videoSlide {
    width: 100%;
    display: inline-block;
}

.videoToggle{
  width: 29vw;
    padding: 0px !important;
    display: inline-block;
    position: absolute;
    left: 6vw;
}
.left-vid-button {
    left: 2vw;
}

.right-vid-button {
    right: 1vw;
}

.right-vid-button, .left-vid-button {
    top: 15vh;
    position: absolute;
    width: 3vw;
    height: 5.5vh;
    border-radius: 90px;
    padding: 2px;
    box-shadow: 6px 6px 12px #b8b9be, -6px -6px 12px #fff;
    border-color: #de1a21;
}

.employer-header{
  display: none;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1){
  .em-swiper-3 .swiper-button-next {
    margin-right: 22vw;
    position: fixed;
}
}


</style>