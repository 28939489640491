function findWithAttr(array, attr, value) {
    for(var i = 0; i < array.length; i += 1) {
        if(array[i][attr] === value) {
            return i;
        }
    }
    return -1;
}


var gamificationMixin = {
    methods: {
      badgeAwarded: function (store,badge) {
          //console.log(store)
          //console.log(store.awards_store.currentSession.badges)
       
            return (findWithAttr(store.awards_store.currentSession.badges,"badgeName",badge.badgeName)!=-1)
               
             

      },
      awardPoints: function (awards_store,badge) {
        if (awards_store.badges.indexOf(badge) == -1){
            awards_store.commit('awards_store/ADD_BADGE',{
               badgeSrc:"aerospace",
               badgeName:'aerospace'
            
             
             
             })
        }
    },      careerLearnedAndLogged: function (store,world,careerId) {

      //console.log(store.interaction_tracking_store[world].pagestate.careersLearned.indexOf(careerId))
        return (store.interaction_tracking_store[world].pagestate.careersLearned.indexOf(careerId)!=-1)
           
    },
       missionAwarded: function (store,world,mission) {
        //console.log(store.awards_store.currentSession.completedMissions)
     
          return (findWithAttr(store.awards_store.currentSession.completedMissions[world],"missionName",mission.missionName)!=-1)
             
      },
     achieveAwarded: function (store,world,achievement) {
    
        //console.log(store.awards_store.currentSession.completedAchievements)
     
        return (findWithAttr(store.awards_store.currentSession.completedAchievements[world],"achieveName",achievement.achievementName)!=-1)
           
  }
    }
  };
  export default gamificationMixin
