<template>
  <section>
    <h1 class="text-center mb-4 font-bold text-lg">Dashboard</h1>
    <article>
      <p class="bg-blue-100 p-5 border border-blue-200 rounded text-blue-500">
        This is the dashboard area which is secured via the router. A user must
        have an account and be logged in to view this page.
        {{currentUser}}
      </p>
      <button @click="testr"> test </button>
    </article>
  </section>
</template>

<script>

//import firebase from "firebase";
import { mapActions,mapGetters } from 'vuex'
// You would obiusly use this component to show secure dashboard information.
// As it stands it is just a simple Vue page with nothing intersting to show.
export default {
  
  data() {
    return {
      error: null,
      emailSending: false,
    };
  },
    mounted() {
    const { currentUser } = this
    console.log(currentUser)
    console.log(this)
    if (currentUser) {
      // Hide the loading screen if currentUser
      // is available before the page renders
      //this.$q.loading.hide()
      //this.getDataFromApi(this.currentUser.id)
      //console.log(currentUser)
    }
  },
  computed: {
    ...mapGetters('user', ['currentUser']),

    user() {
      return this.$store.getters.getUser;
    },
  },

 methods: {
    ...mapActions('auth', ['loginUser']),
    ...mapActions('assessment', ['test2', 'loginUser']),

   async testr()  {
    
   //   this.$refs.emailAuthenticationForm.validate().then(async (success) => {
     //   if (success) {
         // this.$q.loading.show({
        //    message: this.isRegistration
       //       ? 'Registering your account...'
        //      : 'Authenticating your account...',
       //     backgroundColor: 'grey',
       //     spinner: QSpinnerGears,
      //      customClass: 'loader'
     //     })
    // console.log(success)
          try {
            //console.log(this.currentUser)
               this.test2(this.currentUser.id)
          } catch (err) {
            this.error=err
          //  this.$q.notify({
         //     message: `An error as occured: ${err}`,
         //     color: 'negative'
         //   })
          } finally {
            this.$router.push({ path: '/dashboard' })
          }
      //  }
     // })
    },
 }

}
</script>
