<template>

  <div class="hello">
    <svg id="researchBackground" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920.62 905">
      <g style="isolation:isolate">
        <g id="Background_-_Agriculture-2" data-name="Background - Agriculture">
          <rect x="0.62" width="1920" height="900" style="fill:#727272"/>
          <path
              d="M1273.82,507.94a35.68,35.68,0,0,1-30.8-17.67l-14.35-24.61a35.63,35.63,0,0,0-17.23-15l-76.17-31.35a35.63,35.63,0,0,1-22.08-33v-142a35.77,35.77,0,0,1,4-16.53L1253.35,0h-99.29l-104.3,147.53a32.24,32.24,0,0,1-27.89,16.09H42.24a32.24,32.24,0,0,1-27.89-16.09L0,116.66V736.53H1920V507.94ZM1055.32,376a8.61,8.61,0,0,1-8.62,8.61H17.4A8.61,8.61,0,0,1,8.79,376V218.54a8.61,8.61,0,0,1,8.61-8.62H1046.7a8.61,8.61,0,0,1,8.62,8.62Z"
              transform="translate(0.62 0)" style="fill:#989898"/>
          <path
              d="M1154.06,0l-104.3,147.53a32.24,32.24,0,0,1-27.89,16.09H42.24a32.24,32.24,0,0,1-27.89-16.09L0,116.66v44.16C5.71,171,13.25,177.29,24.91,177.29H1039.2a32.25,32.25,0,0,0,28.12-16.47L1175.65,0Z"
              transform="translate(0.62 0)" style="fill:#7f7f7f"/>
          <path
              d="M1062.39,196.42H0c-2,0,1.47.72,0,1.93V396.18c1.47,1.2-2,1.93,0,1.93H1062.39c4.91,0,8.89-4.46,8.89-9.95V206.37C1071.28,200.88,1067.3,196.42,1062.39,196.42Z"
              transform="translate(0.62 0)" style="fill:#727272"/>
          <polygon
              points="916.23 520.95 324.6 520.95 306.23 502.58 143.34 502.58 117.34 457.5 121.78 454.94 146.3 497.45 308.35 497.45 326.73 515.82 913.15 515.82 945.53 455.02 950.06 457.43 916.23 520.95"
              style="fill:#7f7f7f"/>
          <path
              d="M35.55,398.11h994.26a0,0,0,0,1,0,0v0a77.06,77.06,0,0,1-77.06,77.06H112.61a77.06,77.06,0,0,1-77.06-77.06v0a0,0,0,0,1,0,0Z"
              style="fill:#7f7f7f"/>
          <rect x="69.23" y="508.86" width="39.43" height="21.45" rx="2.55" style="fill:#7f7f7f"/>
          <path d="M0,0C.49,1.79,0,3.65,0,5.49V900H1254.89V0Z" transform="translate(0.62 0)" style="fill:none"/>
          <rect x="0.62" y="736.53" width="1920" height="168.47" style="fill:#b2b2b2"/>
          <rect x="0.62" y="637.54" width="1920" height="99" style="fill:#b2b2b2"/>
          <path d="M1920,634.91H956.06c37.36-21.17,107-34.23,182.36-34.23H1920Z" transform="translate(0.62 0)"
                style="fill:#7c7c7c"/>
          <path
              d="M536.89,743.65C351.11,744.06,174.38,720.79,0,678.08V898.49H1920V367.39C1532.18,595.6,998.6,742.63,536.89,743.65Z"
              transform="translate(0.62 0)" style="fill:#838383;opacity:0.25;mix-blend-mode:multiply"/>
          <path d="M0,634.91H108.05C82.83,620.62,46.45,610,0,604.66Z" transform="translate(0.62 0)"
                style="fill:#7c7c7c"/>
          <path
              d="M71.78,534h34.32a2.55,2.55,0,0,1,2.55,2.55v16.34a2.56,2.56,0,0,1-2.56,2.56H71.78a2.55,2.55,0,0,1-2.55-2.55V536.53A2.56,2.56,0,0,1,71.78,534Z"
              style="fill:#7f7f7f"/>
          <rect x="0.62" y="634.91" width="1920" height="12.75" style="fill:#7c7c7c"/>
        </g>
      </g>
    </svg>
      <b-row>
        <b-col>
          <svg id="World_Hologram" data-name="World Hologram - Public Services" xmlns="http://www.w3.org/2000/svg"
               xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 1115.83 328.41">
            <defs>
              <linearGradient id="linear-gradient" x1="45.67" y1="210.79" x2="1004.55" y2="210.79"
                              gradientUnits="userSpaceOnUse">
                <stop offset="0" stop-color="#f1f1f1"/>
                <stop offset="1" stop-color="#e5e5e5"/>
              </linearGradient>
            </defs>
            <rect x="272.79" y="11.68" width="11.34" height="86.37" style="fill:#ebebeb"/>
            <rect x="349.33" y="11.68" width="11.34" height="86.37" style="fill:#ebebeb"/>
            <path
                d="M263.24,5.25H380.71a7.68,7.68,0,0,1,7.66,7.65h0a7.69,7.69,0,0,1-7.66,7.66H263.24a7.68,7.68,0,0,1-7.65-7.66h0A7.67,7.67,0,0,1,263.24,5.25Z"
                transform="translate(0 -3.67)" style="fill:#ebebeb;fill-rule:evenodd"/>
            <rect x="710.85" y="10.1" width="11.68" height="86.37" style="fill:#ebebeb"/>
            <rect x="789.67" y="10.1" width="11.68" height="86.37" style="fill:#ebebeb"/>
            <path
                d="M689.24,3.67H812.7a7.67,7.67,0,0,1,7.65,7.65h0A7.68,7.68,0,0,1,812.7,19H689.24a7.69,7.69,0,0,1-7.66-7.66h0A7.68,7.68,0,0,1,689.24,3.67Z"
                transform="translate(0 -3.67)" style="fill:#ebebeb;fill-rule:evenodd"/>
            <path
                d="M1004.55,332.08,900.42,135.62C892.1,111.33,876.81,89.5,848,89.5H202.26c-28.85,0-44.15,21.83-52.46,46.12L45.67,332.08Z"
                transform="translate(0 -3.67)" style="fill-rule:evenodd;fill:url(#linear-gradient)"/>
            <path
                d="M840.82,163.13H209.55v-1.45c0-10.6,0-8-.7-15.19-.65-6.58-1.9-10.16-4.16-12-2.07-1.65-5.2-2.14-14.56-2.23-9.12-.1-24,.11-38.31.32h-.38l0-2.89h.37c14.38-.21,29.24-.42,38.39-.33,9.54.1,13.44.55,16.34,2.87s4.48,6.42,5.23,14c.69,7,.72,3.81.72,14H837.92V136.91l.17-.32c2.1-4,4.27-8.12,7.89-10.27s8.31-2.14,15.43-2.14h25.67v2.9H861.41c-6.64,0-11,0-13.95,1.73s-4.7,5.13-6.64,8.81Z"
                transform="translate(0 -3.67)" style="fill:#989898"/>
            <rect x="59.34" y="172.84" width="939.76" height="25.39" rx="12.69" style="fill:#ebebeb"/>
            <polygon
                points="191.51 317.79 197.54 298.27 245.18 198.22 76.51 198.22 31.25 298.27 25.52 317.79 63.01 317.79 191.51 317.79"
                style="fill:#ebebeb;fill-rule:evenodd"/>
            <polygon
                points="407.93 259.1 388.2 298.27 382.39 317.79 525.11 317.79 525.11 298.27 525.11 259.1 407.93 259.1"
                style="fill:#ebebeb;fill-rule:evenodd"/>
            <polygon
                points="858.22 259.1 858.22 298.27 858.22 317.79 987.2 317.79 1024.7 317.79 1018.97 298.27 971.5 198.22 916.34 198.22 858.22 259.1"
                style="fill:#ebebeb;fill-rule:evenodd"/>
            <polygon points="382.39 317.79 865.54 317.79 865.54 304.57 386.33 304.57 382.39 317.79"
                     style="fill:#505050;fill-rule:evenodd"/>
            <polygon
                points="858.22 304.57 858.22 317.79 987.2 317.79 1024.7 317.79 1020.82 304.57 982.68 304.57 858.22 304.57"
                style="fill:#505050;fill-rule:evenodd"/>
            <polygon points="50.69 259.1 999.52 259.1 997.49 252.18 52.73 252.18 50.69 259.1"
                     style="fill:#e8f3fb;fill-rule:evenodd"/>
            <rect x="364.98" y="230.11" width="308.52" height="4.59" rx="2.3" style="fill:#1ec2ed"/>
            <polygon points="408.05 317.79 25.52 317.79 29.16 304.57 412.72 304.26 408.05 317.79"
                     style="fill:#505050;fill-rule:evenodd"/>
            <path d="M113.12,286.06H72.94l11.75-44.58,39.9.13Z" transform="translate(0 -3.67)" style="fill:#e4e4e4"/>
            <rect x="72.94" y="186.4" width="907.44" height="6.03" style="fill:#d81f2b"/>
            <rect x="172.22" y="218.02" width="77.58" height="21.31" style="fill:#36455c"/>
            <rect x="175.73" y="218.02" width="12.84" height="18.12" style="fill:#d81f2a"/>
            <rect x="190.17" y="218.02" width="12.84" height="18.12" style="fill:#d81f2a"/>
            <rect x="204.62" y="218.02" width="12.84" height="18.12" style="fill:#d81f2a"/>
            <rect x="219.06" y="218.02" width="12.84" height="18.12" style="fill:#d81f2a"/>
            <rect x="233.51" y="218.02" width="12.84" height="18.12" style="fill:#15a1c1"/>
            <path d="M432.48,273.36a2.22,2.22,0,1,0-2.22-2.22A2.23,2.23,0,0,0,432.48,273.36Z"
                  transform="translate(0 -3.67)" style="fill:#ace0f4;fill-rule:evenodd"/>
            <path d="M432.48,273.36a2.22,2.22,0,1,0-2.22-2.22A2.23,2.23,0,0,0,432.48,273.36Z"
                  transform="translate(0 -3.67)" style="fill:#ace0f4;fill-rule:evenodd"/>
            <path d="M461.41,273.36a2.22,2.22,0,1,0-2.22-2.22A2.22,2.22,0,0,0,461.41,273.36Z"
                  transform="translate(0 -3.67)" style="fill:#ace0f4;fill-rule:evenodd"/>
            <path d="M490.35,273.36a2.22,2.22,0,1,0-2.22-2.22A2.23,2.23,0,0,0,490.35,273.36Z"
                  transform="translate(0 -3.67)" style="fill:#ace0f4;fill-rule:evenodd"/>
            <path d="M897.05,273.36a2.22,2.22,0,1,0-2.22-2.22A2.23,2.23,0,0,0,897.05,273.36Z"
                  transform="translate(0 -3.67)" style="fill:#ace0f4;fill-rule:evenodd"/>
            <path d="M897.05,273.36a2.22,2.22,0,1,0-2.22-2.22A2.23,2.23,0,0,0,897.05,273.36Z"
                  transform="translate(0 -3.67)" style="fill:#ace0f4;fill-rule:evenodd"/>
            <path d="M926,273.36a2.22,2.22,0,1,0-2.22-2.22A2.23,2.23,0,0,0,926,273.36Z" transform="translate(0 -3.67)"
                  style="fill:#ace0f4;fill-rule:evenodd"/>
            <path d="M954.92,273.36a2.22,2.22,0,1,0-2.22-2.22A2.23,2.23,0,0,0,954.92,273.36Z"
                  transform="translate(0 -3.67)" style="fill:#ace0f4;fill-rule:evenodd"/>
            <path d="M162,272.51a2.22,2.22,0,1,0-2.22-2.22A2.23,2.23,0,0,0,162,272.51Z" transform="translate(0 -3.67)"
                  style="fill:#ace0f4;fill-rule:evenodd"/>
            <path d="M190.93,272.51a2.22,2.22,0,1,0-2.22-2.22A2.23,2.23,0,0,0,190.93,272.51Z"
                  transform="translate(0 -3.67)" style="fill:#ace0f4;fill-rule:evenodd"/>
            <rect x="743.34" y="153.98" width="5.21" height="10.26" rx="1.84" style="fill:#fff"/>
            <rect x="743.01" y="152.76" width="5.86" height="10.26" rx="2.07" style="fill:#989898"/>
            <rect x="760.7" y="153.98" width="5.21" height="10.26" rx="1.84" style="fill:#fff"/>
            <rect x="760.37" y="152.76" width="5.86" height="10.26" rx="2.07" style="fill:#989898"/>
            <rect x="274.6" y="153.98" width="5.21" height="10.26" rx="1.84" style="fill:#fff"/>
            <rect x="274.27" y="152.76" width="5.86" height="10.26" rx="2.07" style="fill:#989898"/>
            <rect x="291.96" y="153.98" width="5.21" height="10.26" rx="1.84" style="fill:#fff"/>
            <rect x="291.63" y="152.76" width="5.86" height="10.26" rx="2.07" style="fill:#989898"/>
            <polygon points="1115.83 328.41 1024.7 317.79 25.52 317.79 0 328.41 1115.83 328.41"
                     style="fill:#505050;fill-rule:evenodd"/>
          </svg>
          <div v-if="!hide" class="circle">
            <swiper class="swiper" ref="Swiper1" :options="swiperOption">
              <swiper-slide v-for="(world,index) in worldsARRAY" :key="index">
                <router-link :to="{ name: 'individualWorld', params: {worldname: world.url } }">
                  <div :id="'anim'+index" :ref="'anim'+index"/>
                </router-link>
              </swiper-slide>

            </swiper>
          </div>
        </b-col>
        <b-col>
          <svg id="tabBG" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
               viewBox="0 0 1273.74 901.04">
            <defs>
              <linearGradient id="linear-gradient" x1="-1234.26" y1="4838.55" x2="-92.51" y2="4838.55"
                              gradientTransform="translate(-82.59 5288.55) rotate(180)" gradientUnits="userSpaceOnUse">
                <stop offset="0" stop-color="#f1f1f1"/>
                <stop offset="1" stop-color="#e5e5e5"/>
              </linearGradient>
              <clipPath id="clip-path" transform="translate(-9.92 4.37)">
                <rect x="9.81" y="-15" width="910" height="920" transform="translate(909.81 -19.81) rotate(90)"
                      style="fill:none"/>
              </clipPath>
              <clipPath id="clip-path-2" transform="translate(-9.92 4.37)">
                <path
                    d="M53.08,635.85V822.6a15.24,15.24,0,0,0,4.47,10.78l54.72,54.73a15.27,15.27,0,0,0,10.78,4.46H662.24A15.29,15.29,0,0,0,673,888.11l2.55-2.56a15.23,15.23,0,0,1,10.78-4.46h435.89a15.25,15.25,0,0,0,10.78-4.47l19-19a15.26,15.26,0,0,1,10.77-4.46h51.35a15.29,15.29,0,0,0,10.78-4.46l23-23.06a15.25,15.25,0,0,0,4.47-10.78V762.19h7.82L1276.43,746a15.25,15.25,0,0,0,4.47-10.78v-101a15.23,15.23,0,0,0-4.47-10.77l-16.33-16.34h-7.64v-32.8A15.25,15.25,0,0,0,1248,563.6l-.23-.23a15.24,15.24,0,0,1-4.46-10.78V419.11a15.24,15.24,0,0,1,4.46-10.78h0a15.23,15.23,0,0,0,4.47-10.77v-253a15.2,15.2,0,0,1,4.46-10.77L1279,111.55a15.24,15.24,0,0,0,4.47-10.78V65.1A15.24,15.24,0,0,0,1279,54.32L1233.51,8.88a15.23,15.23,0,0,0-10.77-4.47H944.82A15.24,15.24,0,0,0,934,8.88l-4.59,4.59a15.24,15.24,0,0,1-10.78,4.46H265a15.22,15.22,0,0,1-10.77-4.46L244.4,3.69A15.27,15.27,0,0,0,233.62-.77H102.23A15.29,15.29,0,0,0,91.45,3.69L35.83,59.32A15.25,15.25,0,0,0,31.36,70.1V601.5a15.25,15.25,0,0,0,4.47,10.78l12.79,12.79A15.24,15.24,0,0,1,53.08,635.85Z"
                    style="fill:none"/>
              </clipPath>
              <linearGradient id="linear-gradient-2" x1="-1366" y1="4842.65" x2="-113.95" y2="4842.65"
                              xlink:href="#linear-gradient"/>
            </defs>
            <g style="isolation:isolate">
              <g id="Panel_-_Public_Services" data-name="Panel - Public Services">
                <g style="mix-blend-mode:multiply">
                  <path
                      d="M914,8.51h9.37A15.24,15.24,0,0,0,912.6,13L908,17.57A15.24,15.24,0,0,1,897.23,22H243.51a15.22,15.22,0,0,1-10.77-4.46L223,7.79a15.27,15.27,0,0,0-10.78-4.46H80.79A15.29,15.29,0,0,0,70,7.79L14.39,63.42A15.25,15.25,0,0,0,9.92,74.2V605.6a15.25,15.25,0,0,0,4.47,10.78l12.79,12.79A15.24,15.24,0,0,1,31.64,640V826.7a15.24,15.24,0,0,0,4.47,10.78l54.72,54.73a15.27,15.27,0,0,0,10.78,4.46H640.8a15.29,15.29,0,0,0,10.78-4.46l2.55-2.56a15.23,15.23,0,0,1,10.78-4.46H914Z"
                      transform="translate(-9.92 4.37)" style="fill:url(#linear-gradient)"/>
                </g>
                <g style="clip-path:url(#clip-path)">
                  <path
                      d="M53.08,632.25V819a15.24,15.24,0,0,0,4.47,10.78l54.72,54.73A15.27,15.27,0,0,0,123.05,889H662.24A15.29,15.29,0,0,0,673,884.51l2.55-2.56a15.23,15.23,0,0,1,10.78-4.46h435.89A15.25,15.25,0,0,0,1133,873l19-19a15.26,15.26,0,0,1,10.77-4.46h51.35a15.29,15.29,0,0,0,10.78-4.46L1248,822a15.25,15.25,0,0,0,4.47-10.78V758.59h7.82l16.15-16.15a15.25,15.25,0,0,0,4.47-10.78v-101a15.23,15.23,0,0,0-4.47-10.77l-16.33-16.34h-7.64v-32.8A15.25,15.25,0,0,0,1248,560l-.23-.23A15.24,15.24,0,0,1,1243.3,549V415.51a15.24,15.24,0,0,1,4.46-10.78h0a15.23,15.23,0,0,0,4.47-10.77V141a15.2,15.2,0,0,1,4.46-10.77L1279,108a15.24,15.24,0,0,0,4.47-10.78V61.5A15.24,15.24,0,0,0,1279,50.72L1233.51,5.28A15.23,15.23,0,0,0,1222.74.81H944.82A15.24,15.24,0,0,0,934,5.28l-4.59,4.59a15.24,15.24,0,0,1-10.78,4.46H265a15.22,15.22,0,0,1-10.77-4.46L244.4.09a15.27,15.27,0,0,0-10.78-4.46H102.23A15.29,15.29,0,0,0,91.45.09L35.83,55.72A15.25,15.25,0,0,0,31.36,66.5V597.9a15.25,15.25,0,0,0,4.47,10.78l12.79,12.79A15.24,15.24,0,0,1,53.08,632.25ZM209.62,109.87h0A64.36,64.36,0,0,1,255.13,91H1068a64.36,64.36,0,0,1,45.51,18.85h0a64.41,64.41,0,0,1,18.86,45.52V687.94a64.37,64.37,0,0,1-18.86,45.51h0A64.36,64.36,0,0,1,1068,752.3H255.13a64.36,64.36,0,0,1-45.51-18.85h0a64.36,64.36,0,0,1-18.85-45.51V155.39A64.4,64.4,0,0,1,209.62,109.87Z"
                      transform="translate(-9.92 4.37)" style="fill:#989898"/>
                  <path
                      d="M53.08,637.65V824.4a15.24,15.24,0,0,0,4.47,10.78l54.72,54.73a15.27,15.27,0,0,0,10.78,4.46H662.24A15.29,15.29,0,0,0,673,889.91l2.55-2.56a15.23,15.23,0,0,1,10.78-4.46h435.89a15.25,15.25,0,0,0,10.78-4.47l19-19a15.26,15.26,0,0,1,10.77-4.46h51.35a15.29,15.29,0,0,0,10.78-4.46l23-23.06a15.25,15.25,0,0,0,4.47-10.78V764h7.82l16.15-16.15a15.25,15.25,0,0,0,4.47-10.78v-101a15.23,15.23,0,0,0-4.47-10.77L1260.1,609h-7.64v-32.8A15.25,15.25,0,0,0,1248,565.4l-.23-.23a15.24,15.24,0,0,1-4.46-10.78V420.91a15.24,15.24,0,0,1,4.46-10.78h0a15.23,15.23,0,0,0,4.47-10.77v-253a15.2,15.2,0,0,1,4.46-10.77L1279,113.35a15.24,15.24,0,0,0,4.47-10.78V66.9A15.24,15.24,0,0,0,1279,56.12l-45.44-45.44a15.23,15.23,0,0,0-10.77-4.47H944.82A15.24,15.24,0,0,0,934,10.68l-4.59,4.59a15.24,15.24,0,0,1-10.78,4.46H265a15.22,15.22,0,0,1-10.77-4.46L244.4,5.49A15.27,15.27,0,0,0,233.62,1H102.23A15.29,15.29,0,0,0,91.45,5.49L35.83,61.12A15.25,15.25,0,0,0,31.36,71.9V603.3a15.25,15.25,0,0,0,4.47,10.78l12.79,12.79A15.24,15.24,0,0,1,53.08,637.65ZM209.62,115.27h0a64.36,64.36,0,0,1,45.51-18.85H1068a64.36,64.36,0,0,1,45.51,18.85h0a64.41,64.41,0,0,1,18.86,45.52V693.34a64.37,64.37,0,0,1-18.86,45.51h0A64.36,64.36,0,0,1,1068,757.7H255.13a64.36,64.36,0,0,1-45.51-18.85h0a64.36,64.36,0,0,1-18.85-45.51V160.79A64.4,64.4,0,0,1,209.62,115.27Z"
                      transform="translate(-9.92 4.37)" style="fill:#b2b2b2"/>
                  <g style="clip-path:url(#clip-path-2)">
                    <path
                        d="M53.08,635.85V822.6a15.24,15.24,0,0,0,4.47,10.78l54.72,54.73a15.27,15.27,0,0,0,10.78,4.46H662.24A15.29,15.29,0,0,0,673,888.11l2.55-2.56a15.23,15.23,0,0,1,10.78-4.46h435.89a15.25,15.25,0,0,0,10.78-4.47l19-19a15.26,15.26,0,0,1,10.77-4.46h51.35a15.29,15.29,0,0,0,10.78-4.46l23-23.06a15.25,15.25,0,0,0,4.47-10.78V762.19h7.82L1276.43,746a15.25,15.25,0,0,0,4.47-10.78v-101a15.23,15.23,0,0,0-4.47-10.77l-16.33-16.34h-7.64v-32.8A15.25,15.25,0,0,0,1248,563.6l-.23-.23a15.24,15.24,0,0,1-4.46-10.78V419.11a15.24,15.24,0,0,1,4.46-10.78h0a15.23,15.23,0,0,0,4.47-10.77v-253a15.2,15.2,0,0,1,4.46-10.77L1279,111.55a15.24,15.24,0,0,0,4.47-10.78V65.1A15.24,15.24,0,0,0,1279,54.32L1233.51,8.88a15.23,15.23,0,0,0-10.77-4.47H944.82A15.24,15.24,0,0,0,934,8.88l-4.59,4.59a15.24,15.24,0,0,1-10.78,4.46H265a15.22,15.22,0,0,1-10.77-4.46L244.4,3.69A15.27,15.27,0,0,0,233.62-.77H102.23A15.29,15.29,0,0,0,91.45,3.69L35.83,59.32A15.25,15.25,0,0,0,31.36,70.1V601.5a15.25,15.25,0,0,0,4.47,10.78l12.79,12.79A15.24,15.24,0,0,1,53.08,635.85Z"
                        transform="translate(-9.92 4.37)" style="fill:url(#linear-gradient-2)"/>
                    <path
                        d="M1185.62,416.34l34-34a4.13,4.13,0,0,0,1.2-2.91V182a4.11,4.11,0,0,0-7-2.91L1182,210.87a4.11,4.11,0,0,0-1.2,2.87l-2.15,199.64A4.11,4.11,0,0,0,1185.62,416.34Z"
                        transform="translate(-9.92 4.37)"
                        style="fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.71274042129517px"/>
                    <path
                        d="M1213.78,434.94,1195,453.7a4.09,4.09,0,0,0-1.2,2.9V525a4.13,4.13,0,0,0,1.2,2.91l19,19a4.11,4.11,0,0,0,7-2.91l-.23-106.12A4.11,4.11,0,0,0,1213.78,434.94Z"
                        transform="translate(-9.92 4.37)"
                        style="fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.71274042129517px"/>
                    <path
                        d="M1270.46,605.38h-95.33a4,4,0,0,0-4,4v111.1a4,4,0,0,0,1.17,2.83l36.82,36.82a4,4,0,0,0,2.83,1.17h54.74"
                        transform="translate(-9.92 4.37)"
                        style="fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.71274042129517px"/>
                    <path
                        d="M652.47,893.39l42.63-42.64a4,4,0,0,1,2.83-1.17h216.3a4,4,0,0,0,2.83-1.17l23.85-23.85a4,4,0,0,1,2.83-1.17h115.33a4,4,0,0,1,2.83,1.17l25.6,25.61a4,4,0,0,0,2.83,1.17h66.17l4.94,4.94"
                        transform="translate(-9.92 4.37)"
                        style="fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.71274042129517px"/>
                    <path
                        d="M1200.91,754.1H824l-29.68,29.68a4,4,0,0,1-2.83,1.17H618.91l-11.36,11.36H515.71a4,4,0,0,0-2.83,1.17l-52.46,52.46a4,4,0,0,1-2.83,1.17H399.52a4,4,0,0,1-2.83-6.83l91.75-91.75H89.26"
                        transform="translate(-9.92 4.37)"
                        style="fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.71274042129517px"/>
                    <path d="M411.81,775.93l-68.13,68.13a4,4,0,0,1-2.83,1.17H311.53a4,4,0,0,1-2.83-6.83L372.15,775Z"
                          transform="translate(-9.92 4.37)"
                          style="fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.71274042129517px"/>
                    <path d="M346.66,775.93l-68.14,68.13a4,4,0,0,1-2.83,1.17H246.38a4,4,0,0,1-2.83-6.83L307,775Z"
                          transform="translate(-9.92 4.37)"
                          style="fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.71274042129517px"/>
                    <path d="M281.5,775.93l-68.13,68.13a4,4,0,0,1-2.83,1.17H181.22a4,4,0,0,1-2.83-6.83L241.84,775Z"
                          transform="translate(-9.92 4.37)"
                          style="fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.71274042129517px"/>
                    <path
                        d="M53.08,801.82l35-35A4,4,0,0,0,89.26,764V624a4,4,0,0,0-1.17-2.83l-30.6-30.6a4,4,0,0,1-1.17-2.83V453a4,4,0,0,1,1.17-2.83l78.73-96.73a4,4,0,0,0,1.17-2.83V189.36a4,4,0,0,1,1.17-2.84l35-35H1252.23"
                        transform="translate(-9.92 4.37)"
                        style="fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.71274042129517px"/>
                    <path d="M31.36,417.77l76.28-76.28a4,4,0,0,0,1.17-2.83V168a4,4,0,0,0-1.17-2.83L30.46,88"
                          transform="translate(-9.92 4.37)"
                          style="fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.71274042129517px"/>
                    <path d="M284,16.13l37.79,37.79a4,4,0,0,0,2.83,1.17H860a4,4,0,0,0,2.83-1.17l37.4-37.41"
                          transform="translate(-9.92 4.37)"
                          style="fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.71274042129517px"/>
                    <path
                        d="M1129.39,2.61l29.14,29.14a4,4,0,0,1,0,5.66L1125,71a4,4,0,0,1-2.83,1.17H268.34A4,4,0,0,1,265.51,71L227.39,32.86a4,4,0,0,0-2.83-1.17H57.74"
                        transform="translate(-9.92 4.37)"
                        style="fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.71274042129517px"/>
                    <path
                        d="M30.46,386.6l54.28-54.28A4.09,4.09,0,0,0,86,329.41V304.08a4.11,4.11,0,0,0-7-2.9L29.29,350.82Z"
                        transform="translate(-9.92 4.37)"
                        style="fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.71274042129517px"/>
                    <path
                        d="M30.46,330.88,84.74,276.6A4.09,4.09,0,0,0,86,273.69V248.36a4.11,4.11,0,0,0-7-2.9L29.29,295.1Z"
                        transform="translate(-9.92 4.37)"
                        style="fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.71274042129517px"/>
                    <path
                        d="M30.46,275.16l54.28-54.28A4.09,4.09,0,0,0,86,218V192.64a4.11,4.11,0,0,0-7-2.9L29.29,239.38Z"
                        transform="translate(-9.92 4.37)"
                        style="fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.71274042129517px"/>
                    <path
                        d="M53.08,779.27l17.54-17.54a4.13,4.13,0,0,0,1.21-2.91V668.19a4.1,4.1,0,0,0-1.21-2.9L53.08,647.75Z"
                        transform="translate(-9.92 4.37)"
                        style="fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.71274042129517px"/>
                    <path
                        d="M1185.62,418.14l34-34a4.13,4.13,0,0,0,1.2-2.91V183.77a4.11,4.11,0,0,0-7-2.91L1182,212.67a4.11,4.11,0,0,0-1.2,2.87l-2.15,199.64A4.11,4.11,0,0,0,1185.62,418.14Z"
                        transform="translate(-9.92 4.37)"
                        style="fill:none;stroke:#989898;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.71274042129517px"/>
                    <path
                        d="M1213.78,436.74,1195,455.5a4.09,4.09,0,0,0-1.2,2.9v68.39a4.13,4.13,0,0,0,1.2,2.91l19,19a4.11,4.11,0,0,0,7-2.91l-.23-106.12A4.11,4.11,0,0,0,1213.78,436.74Z"
                        transform="translate(-9.92 4.37)"
                        style="fill:none;stroke:#989898;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.71274042129517px"/>
                    <path
                        d="M1270.46,607.18h-95.33a4,4,0,0,0-4,4v111.1a4,4,0,0,0,1.17,2.83l36.82,36.82a4,4,0,0,0,2.83,1.17h54.74"
                        transform="translate(-9.92 4.37)"
                        style="fill:none;stroke:#989898;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.71274042129517px"/>
                    <path
                        d="M654.27,893.39l40.83-40.84a4,4,0,0,1,2.83-1.17h216.3a4,4,0,0,0,2.83-1.17l23.85-23.85a4,4,0,0,1,2.83-1.17h115.33a4,4,0,0,1,2.83,1.17L1087.5,852a4,4,0,0,0,2.83,1.17h66.17l3.35,3.35"
                        transform="translate(-9.92 4.37)"
                        style="fill:none;stroke:#989898;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.71274042129517px"/>
                    <path
                        d="M1201.61,755.9H824l-29.68,29.68a4,4,0,0,1-2.83,1.17H618.91l-11.36,11.36H515.71a4,4,0,0,0-2.83,1.17l-52.46,52.46a4,4,0,0,1-2.83,1.17H399.52a4,4,0,0,1-2.83-6.83l91.75-91.75H89.26"
                        transform="translate(-9.92 4.37)"
                        style="fill:none;stroke:#989898;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.71274042129517px"/>
                    <path d="M411.81,777.73l-68.13,68.13a4,4,0,0,1-2.83,1.17H311.53a4,4,0,0,1-2.83-6.83l63.45-63.45Z"
                          transform="translate(-9.92 4.37)"
                          style="fill:none;stroke:#989898;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.71274042129517px"/>
                    <path d="M346.66,777.73l-68.14,68.13a4,4,0,0,1-2.83,1.17H246.38a4,4,0,0,1-2.83-6.83L307,776.75Z"
                          transform="translate(-9.92 4.37)"
                          style="fill:none;stroke:#989898;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.71274042129517px"/>
                    <path d="M281.5,777.73l-68.13,68.13a4,4,0,0,1-2.83,1.17H181.22a4,4,0,0,1-2.83-6.83l63.45-63.45Z"
                          transform="translate(-9.92 4.37)"
                          style="fill:none;stroke:#989898;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.71274042129517px"/>
                    <path
                        d="M53.08,803.62l35-35a4,4,0,0,0,1.17-2.83V625.84A4,4,0,0,0,88.09,623l-30.6-30.6a4,4,0,0,1-1.17-2.83V454.82A4,4,0,0,1,57.49,452l78.73-96.73a4,4,0,0,0,1.17-2.83V191.16a4,4,0,0,1,1.17-2.84l35-35H1252.23"
                        transform="translate(-9.92 4.37)"
                        style="fill:none;stroke:#989898;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.71274042129517px"/>
                    <path d="M31.36,419.57l76.28-76.28a4,4,0,0,0,1.17-2.83V169.78a4,4,0,0,0-1.17-2.83L30.46,89.77"
                          transform="translate(-9.92 4.37)"
                          style="fill:none;stroke:#989898;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.71274042129517px"/>
                    <path d="M281.86,15.81l39.91,39.91a4,4,0,0,0,2.83,1.17H860a4,4,0,0,0,2.83-1.17L902,16.51"
                          transform="translate(-9.92 4.37)"
                          style="fill:none;stroke:#989898;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.71274042129517px"/>
                    <path
                        d="M1127.78,2.8l30.75,30.75a4,4,0,0,1,0,5.66L1125,72.78a4,4,0,0,1-2.83,1.17H268.34a4,4,0,0,1-2.83-1.17L227.39,34.66a4,4,0,0,0-2.83-1.17H57.74"
                        transform="translate(-9.92 4.37)"
                        style="fill:none;stroke:#989898;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.71274042129517px"/>
                    <path
                        d="M30.46,388.4l54.28-54.28A4.09,4.09,0,0,0,86,331.21V305.88a4.11,4.11,0,0,0-7-2.9L29.29,352.62Z"
                        transform="translate(-9.92 4.37)"
                        style="fill:none;stroke:#989898;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.71274042129517px"/>
                    <path
                        d="M30.46,332.68,84.74,278.4A4.09,4.09,0,0,0,86,275.49V250.16a4.11,4.11,0,0,0-7-2.9L29.29,296.9Z"
                        transform="translate(-9.92 4.37)"
                        style="fill:none;stroke:#989898;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.71274042129517px"/>
                    <path
                        d="M30.46,277l54.28-54.28A4.09,4.09,0,0,0,86,219.77V194.44a4.11,4.11,0,0,0-7-2.9L29.29,241.18Z"
                        transform="translate(-9.92 4.37)"
                        style="fill:none;stroke:#989898;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.71274042129517px"/>
                    <path
                        d="M53.08,781.07l17.54-17.54a4.13,4.13,0,0,0,1.21-2.91V670a4.1,4.1,0,0,0-1.21-2.9L53.08,649.55Z"
                        transform="translate(-9.92 4.37)"
                        style="fill:none;stroke:#989898;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.71274042129517px"/>
                  </g>
                  <path
                      d="M53.08,635.85V822.6a15.24,15.24,0,0,0,4.47,10.78l54.72,54.73a15.27,15.27,0,0,0,10.78,4.46H662.24A15.29,15.29,0,0,0,673,888.11l2.55-2.56a15.23,15.23,0,0,1,10.78-4.46h435.89a15.25,15.25,0,0,0,10.78-4.47l19-19a15.26,15.26,0,0,1,10.77-4.46h51.35a15.29,15.29,0,0,0,10.78-4.46l23-23.06a15.25,15.25,0,0,0,4.47-10.78V762.19h7.82L1276.43,746a15.25,15.25,0,0,0,4.47-10.78v-101a15.23,15.23,0,0,0-4.47-10.77l-16.33-16.34h-7.64v-32.8A15.25,15.25,0,0,0,1248,563.6l-.23-.23a15.24,15.24,0,0,1-4.46-10.78V419.11a15.24,15.24,0,0,1,4.46-10.78h0a15.23,15.23,0,0,0,4.47-10.77v-253a15.2,15.2,0,0,1,4.46-10.77L1279,111.55a15.24,15.24,0,0,0,4.47-10.78V65.1A15.24,15.24,0,0,0,1279,54.32L1233.51,8.88a15.23,15.23,0,0,0-10.77-4.47H944.82A15.24,15.24,0,0,0,934,8.88l-4.59,4.59a15.24,15.24,0,0,1-10.78,4.46H265a15.22,15.22,0,0,1-10.77-4.46L244.4,3.69A15.27,15.27,0,0,0,233.62-.77H102.23A15.29,15.29,0,0,0,91.45,3.69L35.83,59.32A15.25,15.25,0,0,0,31.36,70.1V601.5a15.25,15.25,0,0,0,4.47,10.78l12.79,12.79A15.24,15.24,0,0,1,53.08,635.85Z"
                      transform="translate(-9.92 4.37)"
                      style="fill:none;stroke:#989898;stroke-linecap:round;stroke-linejoin:round;stroke-width:0.5px"/>
                </g>
              </g>
            </g>
          </svg>
          <div class="tabContent">
            <div class="tabContentWhite">
              <!--{{currentWorld.url}} -->
              <h2>{{ selectProduct(region, currentWorld.url) }} </h2>
              <span> Progress: <strong>{{
                  allMissionsAchievementsCount(currentWorld.url)
                }} /  8  Missions and Achievements</strong></span>

              <b-progress :max="8">

                <b-progress-bar
                    :value="allMissionsAchievementsCount(currentWorld.url)"
                    animated
                >
                </b-progress-bar>
              </b-progress>


              <p>Click below to explore this world.</p>
            </div>
            <div class="research-lab-nav">
              <div @click="decreaseIndex" class="swiper-button-prev" slot="button-prev"></div>
              <div @click="increaseIndex" class="swiper-button-next" slot="button-next"></div>
           
              <b-button>
                <router-link :to="{ name: 'individualWorld', params: {worldname: currentWorld.url } }">Explore
                </router-link>
              </b-button>
            </div>
          </div>
        </b-col>
      </b-row>
  </div>
</template>

<script>

import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';
import Lottie from 'lottie-web';
import worldNameMixin from '../shared_methods/worldname_mixin';
import regionWorldMixin from '../shared_methods/regionworlds_mixin';

export default {
  name: 'researchLab',
  mixins: [worldNameMixin, regionWorldMixin],
  title: 'Navigation',
  components: {
    Swiper,
    SwiperSlide,
  },
  beforeDestroy() {
    this.hide = true;
    // Destroys instance on exit
    Lottie.destroy(this.animName);
  },
  mounted() {
    this.init();
  },
  data() {
    const aState = this.$store.state.interaction_tracking_store;

    const data = {
      hide: false,
      bigState: aState,
      region: process.env.VUE_APP_CURRENT_REGION.toLowerCase(),
      worldsARRAY: this.selectRegionWorlds(process.env.VUE_APP_CURRENT_REGION),
      animName: 'worldAnim',
      swiperOption: {
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
      currentWorldIndex: 0,
    };

    // Import all the animation data for each world
    data.animDataARRAY = data.worldsARRAY.map((item) => {
      // Object clone is needed to prevent memory leak
      return JSON.parse(JSON.stringify(require(`@/assets/worldselect_worlds/${data.region}/${item.url}.json`)));
    });

    return data;
  },
  computed: {
    currentWorld: function () {
      return this.worldsARRAY[this.currentWorldIndex];
    },
  },
  watch: {
    currentWorldIndex: function(){
      // Destroy previous instance before initiating new instance with updated world index
      Lottie.destroy(this.animName);
      this.init();
    }
  },
  methods: {
    init() {
      const index = this.currentWorldIndex;
      // Grab the current world's animation data
      const animationData = this.animDataARRAY[index];

      this.anim = Lottie.loadAnimation({
        container: document.getElementById('anim'+index), // TODO: Using Vue ref needs to be debugged
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData,
        name: this.animName
      });
    },handleplay() {
       //    //console.warn("hndleplay");
         //   console.log(this)
    //  this.$parent.pauseAmbient()
 var a = this.$refs.audioDoor;     
 //console.log(this.$parent);
           a.play()
 //    this.$parent.restartAmbient()

    },
    increaseIndex() {
      this.currentWorldIndex += 1;
    },
    decreaseIndex() {
      this.currentWorldIndex -= 1;
      // this.doorSound.play()
    },
        allMissionsAchievementsCount(worldName)
    { //TODO MAKE IT BETTER
        console.log(this.$store.state.awards_store.currentSession.completedAchievements)
            console.log(worldName)
      return ((this.$store.state.awards_store.currentSession.completedAchievements[worldName].length) +
      (this.$store.state.awards_store.currentSession.completedMissions[worldName].length))
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

#researchBackground {
  height: 100vw;
  position: absolute;
  top: 0vh;
}

.swiper-container {
  width: 50vw;
  height: 80vh;
}

.hello h1 {
  position: absolute;
  top: -100vw;
}

.swiper-wrapper {
  position: absolute;
}


#tabBG {
  position: absolute;
  left: -12vw;
  top: -19%;
  width: 100vw;
  height: 100vh;
  z-index: 2;
}

.tabContent {
    position: absolute;
    z-index: 2;
    width: 36vw;
    height: 43vh;
    border-radius: 10px;
    top: 28vh;
    right: 3vw;
}

.tabContentWhite {
  text-align: center;
}

.research-lab-nav {
  width: 36vw;
  position: absolute;
}

.tabContent button {
    background-color: rgba(0, 0, 0, 0) !important;
    border: 0px solid white;
    left: 9.5vw;
    top: 0vh;
    position: absolute;
}

.swiper-button-prev, .swiper-button-next {
  border-radius: 150px;
  background: #e5e5e5;
  box-shadow: 6px 6px 12px #B8B9BE, -6px -6px 12px #fff;
  width: 6vw;
  height: 11vh;
  top: 1vh;
  margin-top:0px;
}

.swiper-button-prev {
  left: 1vw;
}

.swiper-button-next {
  right: 1vw;
}




.swiper-button-prev:after, .swiper-button-next:after {
  color: #de1a21;
}


.tabContentWhite h2 {
  font-size: 3vw;
    text-align: center;
    font-weight: bolder;
    line-height: 3vw;
    word-break: break-word;
    margin-bottom: 4vh;
}

.tabContent a {
  border-radius: 150px;
  background: linear-gradient(145deg, #F0F0F0, #CACACA);
  width: 14vw;
  height: 11vh;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 1vw;
  color: #de1a21 !important;
  font-size: 21px;
  font-family: 'Montserrat', Helvetica, Arial, sans-serif;
  text-align: center;
  font-weight: bolder;
  box-shadow: 6px 6px 12px #b8b9be, -6px -6px 12px #fff;
}

.tabContent a:active {
  border-radius: 150px;
  background: linear-gradient(145deg, #CACACA, #F0F0F0);
  box-shadow: 26px 26px 49px #C3C3C3,
  -26px -26px 49px #FDFDFD;
}

.tabContent a:hover {
  text-decoration: none;
  color: #ef2b99;
}

.tabContent a.router-link-exact-active {
  border-radius: 150px;
  background: linear-gradient(145deg, #CACACA, #F0F0F0);
  box-shadow: 26px 26px 49px #C3C3C3,
  -26px -26px 49px #FDFDFD;
}

#World_Hologram {
  position: absolute;
  left: -1vw;
  width: 60vw;
  bottom: -100vh;
  z-index: 2;
}

#researchBackground {
  position: absolute;
  left: 0vw;
  top: 0vh;
  height: 121vh;
  z-index: 1;
}


.circle {
  position: absolute;
  z-index: 99999999;
  top: 0vh;
  left: 3vw;
  width: 50vw;
  height: 90vh;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
  .tabContentWhite h2 {
    font-size: 2vw;
    line-height: 2vw;
  }

  .tabContent {
    width: 48vw !important;
    height: 77vh !important;
    top: 8vw !important;
    right: -2vw !important;
    /* background-color: white; */
    padding: 15vh 6vw 19vh 10vw;
  }

  #tabBG {
    left: 4vw;
    top: -19%;
    width: 79vw;
    height: 90vh;
  }

  #World_Hologram {
    left: -13vw !important;
    width: 84vw !important;
    bottom: -103vh !important;
  }

}

@media only screen and (max-width:960px){
  #tabBG {
    position: absolute;
    left: -4vw !important;
    top: 4vh !important;
    width: 153vw !important;
    height: 57vh !important;
    z-index: 2;
}
.swiper-container {
    width: 50vw !important;
    height: 66vh !important;
}
.circle {
    position: absolute;
    z-index: 99999999;
    top: 0vh !important;
    left: 3vw !important;
    width: 41vw !important;
    height: 90vh !important;
}
#World_Hologram {
    position: absolute;
    left: -1vw !important;
    width: 60vw !important;
    bottom: -52vh !important;
    z-index: 2;
}
.tabContent {
    position: absolute;
    z-index: 2;
    width: 36vw !important;
    height: 43vh !important;
    border-radius: 10px;
    top: 13vh !important;
    right: 3vw;
}
.tabContent a{
  width: 44vw !important;
}
.swiper-button-prev {
    left: -51vw !important;
    width: 13vw !important;
}
.swiper-button-next{
    width: 13vw !important;
}
.tabContent button {
    background-color: rgba(0, 0, 0, 0) !important;
    border: 0px solid white;
    left: -36.5vw !important;
    top: 0vh;
    position: absolute;
    width: 100vw;
}
}

</style>
