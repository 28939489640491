<template>
<div>
<button v-if="ipadUp && !childComponentToggle"   :class="ipadUp ? 'back-button show-button' : 'back-button hid-button'"  @click="ipadDown"><b-icon icon="chevron-down" aria-hidden="true" style="width: 42px; height: 42px; color: #de1a21 !important;"></b-icon></button>
<div id="tabletIn">
<h1>Employers</h1>
  <b-row>
      <div v-show="worldName === 'communication_and_information_technology'">
  
    <h2><span v-show="region=='sawdc'"> Participating </span>Employers in {{selectProduct(region,worldName)}}</h2>       
    <p>Click the logos below to learn more about each employer</p>
  </div>
  <div class="employer-header">
  
    <h2><span v-show="region=='sawdc'"> Participating </span>Employers in {{selectProduct(region,worldName)}}</h2>       
    <p>Click the logos below to learn more about each employer</p>
  </div>
  </b-row>
  <b-row v-if="employers.empForBox.length <1">
    <!--<button @click="logAndInit">Click for Surprise</button>-->
  
    <div id="lottieRobotAnim" ref="animRobot"/>
    
    <h2 style="margin:auto; text-align:center;">Nothing to see here except,<br> <span style="color:#de1a21;">FREE POINTS!</span></h2>
  
  </b-row>
  <b-row  v-if="childComponentToggle==false && employers.empForBox.length > 0" >
    <b-col @scroll="handleScroll" class="scrollLogos" :cols="columnWidth">
      <div v-if="region=='eaw'">
      <div  class="logoCont" v-for="(employer, index) in employers.empForBox" :key="index"   :id="index" >
        <a :href="employer.employersite" target="_blank"><img :id="index" :src="require(`@/assets/${region}-logos/${employer.employerLogo}`)"  /></a>
      </div>
      </div>
      <div v-if="region!= 'eaw'">

      <div  class="logoCont" v-for="(employer, index) in employers.empForBox" :key="index"   :id="index" >
            <img :id="index" :src="require(`@/assets/${region}-logos/${employer.employerLogo}`)"  @click="displayEmployersPage(false,$event)" alt="employer's image" />
      </div>
      </div>
      <!-- <b-icon v-show="!hasScrolledToBottom" class="scroll-ind" icon="chevron-down" aria-hidden="true"></b-icon> -->
    </b-col>
    <b-col v-if="region=='eaw'">
      <div class="videoSlide">
        <button class="left-vid-button" @click='leftVideo'><b-icon icon="chevron-left" aria-hidden="true" style="width: 28px; height: 28px; color: #de1a21 !important;"></b-icon></button>
        <videoToggleWrapper :worldName='worldName' :key='carouselIndex' :videoInfo='employers.carouselVideos[this.carouselIndex]'/> 
        <button class="right-vid-button" @click='rightVideo'><b-icon icon="chevron-right" aria-hidden="true" style="width: 28px; height: 28px; color: #de1a21 !important;"></b-icon></button>
      </div>
    </b-col>
  </b-row>
  <b-row v-if="childComponentToggle==true">
      <EmployerPage :worldName='worldName' :employerInfo='employers.empForBox[this.employerIndex]'/>
  </b-row>
</div>
</div>
</template>


<script>
import EmployerPage from './subcomponents/employerpage.vue';
import worldNameMixin from '../../shared_methods/worldname_mixin'
import employersInfoMixin from '../../shared_methods/employersInfoMixin';
import videoToggleWrapper from './videoToggleWrapper';
import Lottie from 'lottie-web';

export default {
    name:"Employers",
            mixins: [worldNameMixin,employersInfoMixin],
       props:{
       worldName:String
     },components:{
        EmployerPage,
        videoToggleWrapper
     },

    methods: {
       init() {
      // Grab the current world's animation data
      const animationData =  JSON.parse(JSON.stringify(require(`@/assets/robot/celebration_robot.json`)));

      this.anim = Lottie.loadAnimation({
        container: document.getElementById('lottieRobotAnim'), // TODO: Using Vue ref needs to be debugged
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData,
        name: this.animName
      });
      //console.log(this.anim)
    },
    logAndInit(){
     //console.log("logging")
     this.init();
     this.surpriseClick=true;
    },
       rightVideo(){
          //console.log(this.carouselIndex)
          if(this.carouselIndex < (this.employers.carouselVideos.length-1))
          {
          this.carouselIndex ++

          }
          else
          {
              this.carouselIndex=0;
          }
      },
      leftVideo(){

          if(this.carouselIndex > 0)
          {
          this.carouselIndex--;

          }
          else
          {
              this.carouselIndex=this.employers.carouselVideos.length-1;
          }
      },
    ipadDown(){
     this.$parent.childComponentTriggerActive(this.$options.name)
     this.ipadUp=false;
     this.$parent.sidebar
    },

    displayEmployersPage(fromBackButtonBool,event) {
    this.childComponentToggle= !this.childComponentToggle;

 if (fromBackButtonBool==false){
 
     //console.log(event.target.id);
    this.employerIndex=event.target.id;
    //this.$parent.handheldView='Assessment';//='Education'
     this.$store.commit('interaction_tracking_store/ADD_ELEMENTINTERACTION',{
        worldname:this.worldName,
        elementstate:true,
        elementid:event.target.id,
        elementname:event.target.class,
        interactiontype:"click",
        component:this.name,
        isSidebarComponent:false
      });
     }
    },
    handleScroll(el) {
        if((el.target.offsetHeight + el.target.scrollTop) >= el.target.scrollHeight) {
          this.hasScrolledToBottom = true;
        } 
        else{this.hasScrolledToBottom=false;}   }
  ,

      },  created() {
   
    window.addEventListener('scroll', this.handleScroll);
  },
  mounted(){
  if(this.region=="eaw"){
    this.columnWidth=6
  }
  this.init()  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
    beforeDestroy() {
          Lottie.destroy(this.animName);

  },
       data() {
             var employersInfo= this.selectEmployersInfo(process.env.VUE_APP_CURRENT_REGION,this.worldName)
      return {
                   region:process.env.VUE_APP_CURRENT_REGION.toLowerCase(),
                animName: 'robotAnim',
         surpriseClick:false,
        employerIndex:0,
        carouselIndex:0,
        columnWidth:12,
        ipadUp:true,
        childComponentToggle:false,
        hasScrolledToBottom:false,
        employers:employersInfo,
                swiper1Options: {
                          slidesPerView: 1,
          spaceBetween: 30,
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
          }
        },
                swiper2Options: {
                        slidesPerView: 4,
          spaceBetween: 30,  
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
          }
        },
      }
       }
}
</script>

<style scoped>
p{
  font-size:11px;
}
.em-swiper-2 .swiper-slide {
    width: 9vw;
    height: 16vh;
    border-radius: 15px;
    background-color: #e5e5e5;

}

.em-swiper-3 .swiper-slide{
    
    margin-bottom: 1vh;
    padding: 10px 10px;
    background-color: rgba(0,0,0,0) !important;
}
.swiper-container.em-swiper-3.swiper-container-initialized.swiper-container-horizontal {
    position: absolute;
    width: 29vw;
    margin-top: 6vh;
}
.swiper-button-prev, .swiper-button-next {
    border-radius: 150px;
    background: #e5e5e5;
    box-shadow: 6px 6px 12px #B8B9BE, -6px -6px 12px #fff;
   width: 4vw;
    height: 8vh;
}

#lottieRobotAnim{
  height: 20vw;
    position: absolute;
    top: 14vh;
}



.swiper-button-prev:after, .swiper-button-next:after {
    color: #de1a21;
    font-size: 23px;
}

.em-swiper-2{
  height:18vh;
}

.scrollLogos.col-6 {
    height: 37vh;
    display: inline-block;
    overflow: scroll;
    overflow-x: hidden;
    box-shadow: inset 2px 2px 5px #B8B9BE, inset -3px -3px 7px #fff;
}

.eaw .scrollLogos .logoCont {
    width: 10vw;
    display: inline-block;
    margin: 0 1vw 0.5vh;
}
.eaw .scrollLogos.col-6 {
    height: 43vh !important;
}

.sawdc .scrollLogos .logoCont {
    width: 10vw;
    display: inline-block;
    margin: 0 1vw 0.5vh;
}
.sawdc .scrollLogos.col-6 {
    height: 43vh !important;
}

.videoSlide {
    width: 100%;
    display: inline-block;
}

.videoToggle{
  width: 29vw;
    padding: 0px !important;
    display: inline-block;
    position: absolute;
    left: 6vw;
}
.left-vid-button {
    left: 2vw;
}

.right-vid-button {
    right: 1vw;
}

.right-vid-button, .left-vid-button {
    top: 15vh;
    position: absolute;
    width: 3vw;
    height: 5.5vh;
    border-radius: 90px;
    padding: 2px;
    box-shadow: 6px 6px 12px #b8b9be, -6px -6px 12px #fff;
    border-color: #de1a21;
}

.em-swiper-2 .swiper-button-prev:after, .swiper-button-next:after {
    color: #de1a21;
    font-size: 16px;
}

.em-swiper-2 .swiper-button-prev, .em-swiper-2 .swiper-button-next {
    border-radius: 150px;
    background: #e5e5e5;
    box-shadow: 6px 6px 12px #B8B9BE, -6px -6px 12px #fff;
   width: 2vw;
    height: 4vh;
}

.bi-chevron-down.scroll-ind.b-icon.bi {
    width: 3.5vw;
    height: 7vh;
    color: white;
    padding: 5px 10px;
    background: #de1a21;
    box-shadow: 6px 6px 12px #b8b9be, -6px -6px 12px #fff;
    position: absolute;
    bottom: 6px;
    left: 50%;
    border-radius: 150px;
    animation:flash 1.5s infinite linear;

}
@keyframes flash {
  0%, 100% {
    opacity:1;
  }
  50% {
    opacity:.2;
  }
}
.swiper-slide img {
    margin-top: 4vh;
    cursor: pointer;
}

.swiper-slide:hover {
    /*box-shadow: inset 2px 2px 5px #B8B9BE, inset -3px -3px 7px #fff;*/
    background-color: #e5e5e5;
    border-radius: 15px;
}

.swiper-slide {
    border-radius: 15px;
    background-color: #e5e5e5;
    /*box-shadow: 6px 6px 12px #B8B9BE, -6px -6px 12px #fff;*/
}

.em-swiper-3 .swiper-slide img {
    cursor: pointer;
    text-align: center;
    display: block;
    margin: auto;
    padding: 8px 8px;
    border-radius: 24px;
    width:66%;
}

.em-swiper-3 p, .em-swiper-3 h6 {
    padding:0px 10px;
}

.em-swiper-3 button {
    padding: 4px 10px;
    position: static;
    /*margin: -5px 10px 15px;*/
    width: 100%;
    height: 7vh;
    border: 0px solid white;
    border-radius: 150px;
    background: #e5e5e5;
    box-shadow: 6px 6px 12px #B8B9BE, -6px -6px 12px #fff;
    line-height: 14px;
    display: flex;
    font-family: 'Montserrat', Helvetica, Arial, sans-serif !important;
    font-weight: bold;
    align-items: center;
    justify-content: center;
    color: #de1a21 !important;
    font-size: 15px;
    text-transform: uppercase;
}

.em-swiper-3 .swiper-button-next {
        margin-right: 13vw;
    position: fixed;
}

.em-swiper-3 .swiper-button-prev {
        left: 54vw;
    position: fixed;
}

</style>